import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { useDispatch } from "react-redux";
import swal from "sweetalert";
import {
  postLanguage,
  getLanguagies,
  delLanguage,
} from "../../Services/doctor/profile";
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import * as icons from "@material-ui/icons";
const Languages = ({ classes }) => {
  const [level, setLevel] = useState("");
  const [languagies, setLanguagies] = useState([]);
  const [name, setName] = useState("");
  const dispatch = useDispatch();
  const handleLevel = (e) => {
    setLevel(e.target.value);
  };
  const handleName = (e) => {
    setName(e.target.value);
  };
  const handleAdd = async () => {
    dispatch(showLoading());
    try {
      let { data } = await postLanguage({ name, level });
      data = data.data;
      let tmp = [...languagies];
      tmp.push(data);
      setLanguagies(tmp);
      swal("success!", "successfully added!", "success");
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401) {
        msg = data.msg;
      }
      swal("wrong!", msg, "error");
    } finally {
      dispatch(hideLoading());
    }
  };
  const handleDelete = async (id, name) => {
    let isSure = await swal({
      title: "Are you sure?",
      text: `Are you sure to delete ${name}!`,
      icon: "warning",
      buttons: ["No", "Yes"],
      dangerMode: true,
    });
    if (isSure) {
      try {
        dispatch(showLoading());
        await delLanguage(id);
        let tmp = languagies.filter((item) => item._id != id);
        setLanguagies(tmp);
        swal("success!", "successfully deleted!", "success");
      } catch (error) {
        let msg = "some error occured";
        let { status, data } = error.response;
        if (status == 400 || status == 401) {
          msg = data.msg;
        }
        swal("wrong!", msg, "error");
      } finally {
        dispatch(hideLoading());
      }
    }
  };
  useEffect(async () => {
    dispatch(showLoading());
    try {
      let { data } = await getLanguagies();
      data = data.data;
      setLanguagies(data);
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401) {
        msg = data.msg;
      }
      swal("wrong!", msg, "error");
    } finally {
      dispatch(hideLoading());
    }
  }, []);
  return (
    <Container>
      <Row className="justify-content-center">
        <Col xs={12} lg={8}>
          <div className="d-flex justify-content-between">
            <span>Language</span>
            <div>
              <Button
                variant="contained"
                onClick={handleAdd}
                className={classes.addBtn}
              >
                Add +
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={12} lg={8}>
          <Row className="mb-4">
            <Col xs={12} lg={9} className="mt-2">
              <TextField
                size="small"
                value={name}
                onChange={handleName}
                id="langName"
                fullWidth
                label="Language name"
                variant="outlined"
              />
            </Col>
            <Col xs={12} lg={3} className="mt-2">
              <FormControl
                size="small"
                variant="outlined"
                fullWidth
                className={classes.formControl}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Level
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={level}
                  onChange={handleLevel}
                  label="From"
                >
                  <MenuItem value={1}>{1}</MenuItem>
                  <MenuItem value={2}>{2}</MenuItem>
                  <MenuItem value={3}>{3}</MenuItem>
                </Select>
              </FormControl>
            </Col>
          </Row>
          {languagies.map((item) => (
            <Row>
              <Col xs={12} lg={9} className="mt-3">
                <TextField
                  size="small"
                  value={item.name}
                  fullWidth
                  disabled
                  label="Language name"
                  variant="outlined"
                />
              </Col>
              <Col xs={12} lg={2} className="mt-3">
                <TextField
                  size="small"
                  value={item.level}
                  fullWidth
                  disabled
                  label="Level"
                  variant="outlined"
                />
              </Col>
              <Col lg={1} className="mt-3">
                <Button
                  fullWidth
                  onClick={() => {
                    handleDelete(item._id, item.name);
                  }}
                >
                  <icons.DeleteOutline style={{ color: "red" }} />
                </Button>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default Languages;
