import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { useDispatch } from "react-redux";
import swal from "sweetalert";
import { Pagination } from "@material-ui/lab";
import {
    getListOFAllAppointment,
    deleteAppointment,
    getListOfDoctor,
} from "../../Services/admin/appointments";
import {
    TextField,
    Button,
    InputAdornment,
    IconButton,
} from "@material-ui/core";
import { } from "@material-ui/lab";
import * as icons from "@material-ui/icons";
import ATabel from "../AdminAppointments/ATabel";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    textField: {
        [`& fieldset`]: {
            borderRadius: 0,
        },
    },
    searchBtn: {
        borderRadius: 0,
        color: "#fff",
        backgroundColor: "#6DBCDB",
        "&:hover": {
            backgroundColor: "#0097e6",
        },
    },
}));

const Appointment = ({ history }) => {
    const classes = useStyles();
    const [rows, setRows] = useState([]);
    const [editInfo, setEditInfo] = useState([]);
    const [doctorList, setDoctorList] = useState(null);
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(1);
    const dispatch = useDispatch();

    const handleEdit = async (idx) => {
        const { push } = history;
        push({ pathname: "/appointmentEdit", state: { info: rows[idx], editInfo: editInfo[idx] ,doctorList: doctorList} })
    };
    const initRows = async (page) => {
        let { data } = await getListOFAllAppointment(page);
        const doctorData = await getListOfDoctor()
        let Alldoctor = doctorData.data.data;
        let doctor_tmp = [];
        for (let i = 0; i < Alldoctor.length; i++) {
            let x = Alldoctor[i];
            doctor_tmp.push(x)
        }
        setDoctorList(doctor_tmp)
        setCount(data.count);
        data = data.data;
        let tmp = [];
        let edit_tmp = [];
        for (let i = 0; i < data.length; i++) {
            let x = data[i];
            let y = data[i];
            edit_tmp.push({
                name: y.name,
                pid: y.patientId,
                age: y.age,
                phone: y.phone,
                did: y.doctor,
                visit_date: y.visit_date,
                visited: y.visited,
            })

            let date = new Date(x.visit_date);
            date = date.toLocaleString();
            tmp.push({
                id: x.patientId,
                _id: x._id,
                date,
                name: x.name,
                phone: x.phone,
            });
        }
        setRows(tmp);
        setEditInfo(edit_tmp)
    };
    const handlePage = async (e, value) => {
        setPage(value);
    };
    const handleRemove = async (e, name) => {
        let isSure = await swal({
            title: "Are you sure?",
            text: `Are you sure to delete ${name}!`,
            icon: "warning",
            buttons: ["No", "Yes"],
            dangerMode: true,
        });
        if (isSure) {
            try {
                dispatch(showLoading());
                await deleteAppointment(e);
                swal("success!", "successfully category deleted!", "success");
                if (page == 1) await initRows(1);
                else setPage(1);
            } catch (error) {
                let msg = "some error occured";
                let { status, data } = error.response;
                if (status == 400 || status == 401) {
                    msg = data.msg;
                }
                swal("wrong!", msg, "error");
            } finally {
                dispatch(hideLoading());
            }
        }
    }

    useEffect(async () => {
        dispatch(showLoading());
        try {
            await initRows(page);
        } catch (error) {
            let msg = "some error occured";
            let { status, data } = error.response;
            if (status == 400 || status == 401) {
                msg = data.msg;
            }
            swal("wrong!", msg, "error");
        } finally {
            dispatch(hideLoading());
        }
    }, [page]);

    return (
        <>
            <Container>
                <Row>
                    <Col xs={12} className="mt-2">
                        <ATabel rows={rows}
                            handleRemove={handleRemove}
                            handleEdit={handleEdit} />
                    </Col>
                </Row>
                {count > 6 ? (
                    <div className="mt-3">
                        <Pagination
                            count={parseInt((count + 5) / 6)}
                            onChange={handlePage}
                            page={page}
                            style={{ display: "flex", justifyContent: "center" }}
                            variant="outlined"
                            color="secondary"
                        />
                    </div>
                ) : null}
            </Container>
        </>
    );
};

export default withRouter(Appointment);
