import { post, get, put, del } from "../../http";
import { root } from "../../config.json";

export const getListOfAdmin = (page) => {
  return get(`${root}/api/v1/admin/admin?limit=6&page=${page}`);
};

export const deleteAdmin = (id) => {
  return del(`${root}/api/v1/admin/admin?id=${id}`);
};

export const createAdmin = (body) => {
  return post(`${root}/api/v1/admin/admin`, body);
};
