import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";

import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Button,
  Tooltip,
} from "@material-ui/core";

import * as icons from "@material-ui/icons";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const LTabel = ({ rows, handleRemove, handleEdit, handleSwitch }) => {
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left">ID</StyledTableCell>
            <StyledTableCell align="left">Name</StyledTableCell>
            <StyledTableCell align="left">Address</StyledTableCell>
            <StyledTableCell align="left">Phone</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, idx) => (
            <StyledTableRow key={idx}>
              <StyledTableCell align="left">{row.ID ? row.ID : "N/A"}</StyledTableCell>
              <StyledTableCell align="left">
                <div>{row.name}</div>
              </StyledTableCell>
              <StyledTableCell align="left">
                <div>{row.address}</div>
              </StyledTableCell>

              <StyledTableCell align="left">
                <div className="d-flex justify-content-between">
                  <div>
                    {row.phone.map((item, idx) => (
                      <span key={idx}>
                        {idx != 0 ? <span> - </span> : null}
                        {item}
                      </span>
                    ))}
                  </div>
                  <div>
                    <Tooltip title="Delete">
                      <Button
                        className="p-0"
                        onClick={() => {
                          handleRemove(row._id, row.name);
                        }}
                      >
                        <icons.DeleteOutline className="text-danger" />
                      </Button>
                    </Tooltip>
                    {/* <Tooltip title="checkUp">
                      <Button
                        className="p-0"
                        onClick={() => {
                          handleCheckUp(row);
                        }}
                      >
                        <icons.TableChart style={{ color: "#0abde3" }} />
                      </Button>
                    </Tooltip> */}
                    <Tooltip title="Edit">
                      <Button
                        className="p-0"
                        onClick={() => {
                          handleEdit(idx);
                        }}
                      >
                        <icons.EditOutlined style={{ color: "#0abde3" }} />
                      </Button>
                    </Tooltip>
                    <Tooltip>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          handleSwitch(row._id, idx);
                        }}
                      >
                        Switch
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LTabel;
