import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import PRTabel from "./PRTabel";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { useDispatch } from "react-redux";
import swal from "sweetalert";
import { Pagination } from "@material-ui/lab";
import {
  getPatientReports,
  delReport,
  searchReport,
} from "../../Services/doctor/Patient";
import * as icons from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  searchBtn: {
    borderRadius: 0,
    color: "#fff",
    backgroundColor: "#6DBCDB",
    "&:hover": {
      backgroundColor: "#0097e6",
    },
  },
}));

const AssistantManagement = ({ history }) => {
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const deleteReport = async (id, name) => {
    try {
      let isSure = await swal({
        title: "Are you sure?",
        text: `Are you sure to delete ${name}!`,
        icon: "warning",
        buttons: ["No", "Yes"],
        dangerMode: true,
      });
      if (isSure) {
        dispatch(showLoading());
        await delReport(id);
        swal("success!", "successfully deleted!", "success");
        if (page != 1) setPage(1);
        else await initRows(1);
      }
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401) {
        msg = data.msg;
      }
      swal("wrong!", msg, "error");
    } finally {
      dispatch(hideLoading());
    }
  };
  const handleEdit = (i) => {
    const { push } = history;
    push({
      pathname: "/DoctorsprescriptionEdit",
      state: { info: rows[i] },
    });
  };
  const initRows = async (page) => {
    let { data } =
      search.length > 0
        ? await searchReport(page, search)
        : await getPatientReports(page);

    setCount(data.count);
    data = data.data;
    let tmp = [];
    for (let i = 0; i < data.length; i++) {
      let x = data[i];
      let date = new Date(x.createAt);
      date = date.toLocaleString().split(",")[0];
      tmp.push({
        id: x.patientId,
        _id: x._id,
        name: x.name,
        phone: x.phone,
        description: `${x.description.substr(0, 20)}${
          x.description.length > 20 ? "..." : ""
        }`,
        title: `${x.title.substr(0, 20)}${x.title.length > 20 ? "..." : ""}`,
        fullTitle: x.title,
        fullDescription: x.description,
        date,
        imgs: x.documents,
      });
    }
    setRows(tmp);
  };
  useEffect(async () => {
    try {
      dispatch(showLoading());
      await initRows(page);
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401) {
        msg = data.msg;
      }
      swal("wrong!", msg, "error");
    } finally {
      dispatch(hideLoading());
    }
  }, [page]);
  const handlePage = async (e, value) => {
    setPage(value);
  };
  const handlePatientReport = () => {
    const { push } = history;
    push("/DoctorsprescriptionAdd");
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  const handleSearchEnter = async () => {
    dispatch(showLoading());
    try {
      await initRows(page);
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401) {
        msg = data.msg;
      }
      swal("wrong!", msg, "error");
    } finally {
      dispatch(hideLoading());
    }
  };
  return (
    <>
      <Container>
        <Row className="justify-content-between">
          <Col
            xs={{ order: "last" }}
            md={{ order: "first", span: 3 }}
            className="mt-2"
          >
            <Button
              onClick={handlePatientReport}
              fullWidth
              variant="contained"
              className={classes.searchBtn}
            >
              + Add report
            </Button>
          </Col>
          <Col
            xs={{ order: "first", span: 12 }}
            md={{ order: "last", span: 3 }}
            className="mt-2"
          >
            <TextField
              fullWidth
              size="small"
              id="search"
              type="number"
              label="Search by ID"
              variant="outlined"
              value={search}
              onChange={handleSearch}
              onKeyDown={(e) => {
                if (e.key === "Enter") handleSearchEnter();
              }}
              className={classes.textField}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton onClick={handleSearchEnter}>
                      <icons.Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="mt-2">
            <PRTabel
              rows={rows}
              deleteReport={deleteReport}
              handleEdit={handleEdit}
            />
          </Col>
        </Row>
        {count > 6 ? (
          <div className="mt-3">
            <Pagination
              count={parseInt((count + 5) / 6)}
              onChange={handlePage}
              page={page}
              style={{ display: "flex", justifyContent: "center" }}
              variant="outlined"
              color="secondary"
            />
          </div>
        ) : null}
      </Container>
    </>
  );
};

export default withRouter(AssistantManagement);
