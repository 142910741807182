import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { days, months } from "../../utils/values.json";
import * as icons from "@material-ui/icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { updateAssistant } from "../../Services/doctor/Assistant";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { useDispatch } from "react-redux";
import {
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";

const useStyles = makeStyles((theme) => ({
  menuPaper: {
    maxHeight: 200,
  },
}));
const AMEdit = ({ location }) => {
  const dispatch = useDispatch();
  const { info } = location.state;
  const [gender, setGender] = useState(info.genderId);
  const classes = useStyles();
  const [fname, setFname] = useState(info.fname);
  const [lname, setLname] = useState(info.lname);
  const [email, setEmail] = useState(info.email);
  const [phone, setPhone] = useState(info.phone);

  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleGender = (e) => {
    setGender(e.target.value);
  };
  const handleFname = (e) => {
    setFname(e.target.value);
  };
  const handleLname = (e) => {
    setLname(e.target.value);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handlePhone = (e) => {
    setPhone(e.target.value);
  };

  const handleSave = async () => {
    if (
      fname.length > 0 &&
      lname.length > 0 &&
      phone.length > 0 &&
      email.length > 0
    ) {
      try {
        dispatch(showLoading());
        await updateAssistant(info.id, {
          fname,
          lname,
          phone,
          email,
          password: values.password,
          gender,
        });
        swal("success!", "successfully assistant created!", "success");
      } catch (error) {
        let msg = "some error occured";
        let { status, data } = error.response;
        if (status == 400 || status == 401) {
          msg = data.msg;
        }

        swal("wrong!", msg, "error");
      } finally {
        dispatch(hideLoading());
      }
    } else {
      swal("wrong!", "please fill all field!", "error");
    }
  };

  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col md="6">Full name</Col>
        </Row>

        <Row className="justify-content-center">
          <Col md="3" className="mt-2">
            <TextField
              size="small"
              fullWidth
              id="fname"
              label="First name"
              variant="outlined"
              value={fname}
              onChange={handleFname}
            />
          </Col>
          <Col md="3" className="mt-2">
            <TextField
              size="small"
              fullWidth
              id="lname"
              label="Last name"
              variant="outlined"
              value={lname}
              onChange={handleLname}
            />
          </Col>
        </Row>
        <Row className="justify-content-center mt-4">
          <Col md="6">Contact info</Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="3" className="mt-2">
            <TextField
              size="small"
              fullWidth
              id="email"
              label="Email Address"
              value={email}
              onChange={handleEmail}
              variant="outlined"
            />
          </Col>
          <Col md="3" className="mt-2">
            <TextField
              size="small"
              fullWidth
              id="phone"
              inputProps={{ maxLength: 10 }}
              label="Phone number"
              value={phone}
              onChange={handlePhone}
              variant="outlined"
            />
          </Col>
        </Row>
        <Row className="justify-content-center mt-4">
          <Col md="3" className="mt-2">
            Gender
          </Col>
          <Col md="3" className="mt-2">
            Passwrod
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="3" className="mt-2">
            <FormControl
              size="small"
              variant="outlined"
              fullWidth
              className={classes.formControl}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Gender
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="Gender"
                value={gender}
                onChange={handleGender}
                label="Gender"
              >
                <MenuItem value="0">Male</MenuItem>
                <MenuItem value="1">Female</MenuItem>
              </Select>
            </FormControl>
          </Col>
          <Col md="3" className="mt-2">
            <FormControl fullWidth size="small" variant="outlined">
              <OutlinedInput
                placeholder="Password"
                id="outlined-adornment-password"
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChange("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? (
                        <icons.Visibility />
                      ) : (
                        <icons.VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Col>
        </Row>
        <Row className="justify-content-center mt-4">
          <Col md="6">
            <Row className="justify-content-end">
              <Col md="2">
                <Button
                  fullWidth
                  onClick={handleSave}
                  variant="contained"
                  color="secondary"
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AMEdit;
