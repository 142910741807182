import { get} from "../../http";
import { root } from "../../config.json";

export const getListOfUsers = (page) => {
  return get(`${root}/api/v1/usersList/users?limit=6&page=${page}`);
};

export const ViewSinglePatient = (id) => {
    return get(`${root}/api/v1/usersList/users/${id}`);
  };
  
