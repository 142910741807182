import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Grid,
  Avatar,
  Menu,
  MenuItem,
  Button,
} from "@material-ui/core";
import { root } from "../../Services/config.json";
import { getDoctor } from "../../Services/doctor/profile";
import * as icons from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { withRouter } from "react-router";
import Hospital from "../Hospital/Hospital";

const drawerWidth = 240;

const options = [{ text: "Logout", link: "/logout" }];

const ITEM_HEIGHT = 48;

const useStyles = makeStyles((theme) => ({
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#fff",
    color: "#7f8c8d",
    marginTop: "2px",
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  textField: {
    [`& fieldset`]: {
      borderRadius: 0,
    },
  },
  searchBtn: {
    borderRadius: 0,
    color: "#fff",
    backgroundColor: "#6DBCDB",
  },
  avatar: {
    color: "#fff",
    backgroundColor: "#2980b9",
    marginRight: "20px",
  },
  badge: {},
}));

const MUIAppBar = ({ handleDrawerOpen, open, history, role }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const [url, setUrl] = useState(null);
  let jwt = localStorage.getItem("AdminJwtoken");
  useEffect(async () => {
    let { data } = await getDoctor();
    setUrl(`${root}${data.data.photo}`);
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoreOption = (link) => {
    history.replace({ pathname: link, state: "logout" });
    // window.location.reload();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSwitch = () => {
    localStorage.removeItem("AdminJwtoken");
    localStorage.setItem("jwtoken", jwt);
    window.location.replace("/");
  };
  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          className={clsx(classes.menuButton, open && classes.hide)}
        >
          <icons.Menu />
        </IconButton>
        {jwt ? (
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={handleSwitch}
          >
            Switch back as admin
          </Button>
        ) : null}

        <Grid container={true}></Grid>

        <div> 
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <icons.MoreVert />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={menuOpen}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: "20ch",
              },
            }}
          >
            {options.map(({ text, link }) => (
              <MenuItem
                key={text}
                selected={text === "Pyxis"}
                onClick={() => {
                  handleMoreOption(link);
                }}
              >
                {text}
              </MenuItem>
            ))}
          </Menu>
        </div>
        {role == "doctor" ? (
          <Avatar className={classes.avatar} src={url}></Avatar>
        ) : null}
      </Toolbar>
    </AppBar>
  );
};

export default withRouter(MUIAppBar);
