import { put, del, post, get } from "../../http";
import { root } from "../../config.json";

export const getCities = () => {
    return get(`${root}/api/v1/city?limit=100`);
  };
  
  export const getCategories = () => {
    return get(`${root}/api/v1/category?limit=100`);
  };
  export const getHospitalInfo = (id) => {
    return get(`${root}/api/v1/hospital/info/${id}`);
  };
  
  export const ProfileUpload = (id, data) => {
    return post(`${root}/api/v1/hospital/img/${id}`, data, {
      headers: { "content-type": "multipart/form-data" },
    });
  };
  export const editHospital = (id, body) => {
    return put(`${root}/api/v1/hospital/${id}`, body);
  };
  
  