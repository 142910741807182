import { useEffect, useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { Button, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { getCities } from "../../Services/doctor/profile";
import ImgComp from "../utils/ImageComp";
import { createAds, uploadImg } from "../../Services/ads";
import swal from "sweetalert";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { useDispatch } from "react-redux";

const isValidUrl = (str) => {
  return str.match(
    /http(s)?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
};

const AddAds = ({ history }) => {
  const dispatch = useDispatch();
  const [cities, setCities] = useState([]);
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [cityList, setCityList] = useState([]);
  const [blob, setBlob] = useState(null);
  useEffect(() => {
    const call = async () => {
      let { data } = await getCities();
      data = [...data.data];
      data.unshift({ e_name: "All", _id: "all" });
      setCityList(data);
    };
    call();
  }, []);
  const handleName = (e) => {
    setName(e.target.value);
  };
  const handleLink = (e) => {
    setLink(e.target.value);
  };
  const handleSave = async () => {
    if (link.length == 0 || isValidUrl(link) != null) {
      if (blob && name.length > 0) {
        try {
          dispatch(showLoading());
          let accessCity = [];
          for (let x of cities) {
            accessCity.push(x._id);
          }
          let body = { name, link };
          if (accessCity.length == 0 || accessCity[0] == "all") {
            body["is_public"] = true;
          } else {
            body["city"] = accessCity;
          }

          let { data } = await createAds(body);
          let id = data.data._id;

          const fd = new FormData();
          fd.append("img", blob, "jack");
          await uploadImg(id, fd);
          swal("success!", "successfully ads created!", "success");
          history.goBack();
        } catch (error) {
          swal("error", error.message, "error");
        } finally {
          dispatch(hideLoading());
        }
      } else {
        swal("error", "Name and Photo is required.", "error");
      }
    } else {
      swal("error", "link is invalid.", "error");
    }
  };
  return (
    <Container>
      <Row className="justify-content-center">
        <Col md="3" className="mt-2">
          <TextField
            value={name}
            onChange={handleName}
            size="small"
            fullWidth
            variant="outlined"
            label="Name"
          />
        </Col>
        <Col md="3" className="mt-2">
          <TextField
            value={link}
            onChange={handleLink}
            size="small"
            fullWidth
            variant="outlined"
            label="Link"
          />
        </Col>
      </Row>
      <Row className="justify-content-center mt-2">
        <Col md="6">
          <Autocomplete
            multiple
            options={cityList}
            onChange={(e, value) => {
              setCities(value);
            }}
            getOptionLabel={(option) => option.e_name}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                variant="outlined"
                label="Cities"
              />
            )}
          />
        </Col>
      </Row>
      <ImgComp loading={false} blob={blob} setBlob={setBlob} ads={true} />
      <Row className="justify-content-center">
        <Col md="5"></Col>
        <Col md="1" className="mt-4">
          <Button
            onClick={handleSave}
            fullWidth
            variant="contained"
            color="secondary"
          >
            Save
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default AddAds;
