import { useState, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import ImageCropper from "./ImageCropper";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";
const ImageComp = ({ loading, blob, setBlob, link, ads }) => {
  const [url, setUrl] = useState(link);
  const [load, setLoad] = useState(false);
  const [inputImg, setInputImg] = useState("");
  const [showCropper, setShowCropper] = useState(false);
  const imgs = useRef();
  const onInputChange = (e) => {
    // convert image file to base64 string
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      () => {
        setInputImg(reader.result);
        setShowCropper(true);
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const getBlob = (blob) => {
    // pass blob up from the ImageCropper component
    setBlob(blob);
  };
  const handleCrop = async () => {
    let reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => {
      setUrl(reader.result);
      setLoad(true);
    };
    setShowCropper(false);
  };
  const handleImgLoad = () => {
    if (!load) setLoad(true);
  };
  return (
    <>
      <Row className="justify-content-center ">
        <Col>
          <Row className="justify-content-center mb-2 mt-4">
            <Col lg={6}>
              {load ? null : (
                <div
                  style={{
                    width: 250,
                    backgroundColor: "white",
                    height: 250,
                  }}
                  className="text-center"
                >
                  {loading ? (
                    <CircularProgress style={{ marginTop: "100px" }} />
                  ) : null}
                </div>
              )}
              <img
                src={url}
                id="profile"
                onLoad={handleImgLoad}
                style={{
                  width: "250px",
                  height: "250px",
                  objectFit: "contain",
                  display: load ? "block" : "none",
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="justify-content-center ">
        <Col>
          <Row className="justify-content-center mb-2">
            <Col lg={6}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  imgs.current.click();
                }}
              >
                Select new profile images
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      {showCropper ? (
        <Dialog fullWidth open={true}>
          <DialogContent style={{ height: "300px" }}>
            <ImageCropper
              getBlob={getBlob}
              inputImg={inputImg}
              aspect={ads ? 343 / 181 : 1}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="secondary" onClick={handleCrop}>
              Crop
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
      <input
        multiple
        type="file"
        accept="image/png, image/jpeg"
        onChange={onInputChange}
        style={{ display: "none" }}
        ref={imgs}
      />
    </>
  );
};

export default ImageComp;
