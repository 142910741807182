import { post, get, put, del } from "../../http";
import { root } from "../../config.json";

export const getListOfDoctor = (page) => {
  return get(`${root}/api/v1/doctor/all?limit=6&page=${page}`);
};

export const deActiveDoctor = (id) => {
  return put(`${root}/api/v1/doctor/active?id=${id}`);
};

export const createDoctor = (body) => {
  return post(`${root}/api/v1/doctor/profile`, body);
};

export const updateDoctor = (id, body) => {
  return put(`${root}/api/v1/doctor/profile?id=${id}`, body);
};

export const switchToDoctor = (id) => {
  return get(`${root}/api/v1/admin/${id}`);
};

// export const searchDoctor = (name) => {
//   return get(`${root}/api/v1/doctor/search/${name}`);
// };

export const searchDoctor = (ID) => {
  return get(`${root}/api/v1/doctor/searchByID/${ID}`);
};