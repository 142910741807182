import React, { useState, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { addCategory, uploadImage } from "../../Services/admin/Category";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { useDispatch } from "react-redux";
import { TextField, Button } from "@material-ui/core";

import swal from "sweetalert";

const CAdd = ({ history }) => {
  const dispatch = useDispatch();

  const [e_title, setE_Title] = useState("");
  const [f_title, setF_Title] = useState("");
  const [p_title, setP_Title] = useState("");
  const [b_color, setB_Color] = useState("");
  const [image, setImage] = useState(null);
  const [url, setUrl] = useState("");
  const imgs = useRef();

  const handleE_title = (e) => {
    setE_Title(e.target.value);
  };
  const handleD_title = (e) => {
    setF_Title(e.target.value);
  };
  const handleP_title = (e) => {
    setP_Title(e.target.value);
  };
  const handleB_Color = (e) => {
    setB_Color(e.target.value);
  };

  const handleSave = async () => {
    if (e_title.length > 0 && f_title.length > 0 && p_title.length > 0) {
      try {
        dispatch(showLoading());
        let { data } = await addCategory({
          e_title,
          p_title,
          f_title,
          background: b_color,
        });
        let id = data.data._id;
        if (image) {
          const fd = new FormData();
          fd.append("img", image, "jack");
          await uploadImage(id, fd);
        }
        swal("success!", "successfully category created!", "success");
        history.goBack();
      } catch (error) {
        let msg = "some error occured";
        let { status, data } = error.response;
        if (status == 400 || status == 401) {
          msg = data.msg;
        }

        swal("wrong!", msg, "error");
      } finally {
        dispatch(hideLoading());
      }
    } else {
      swal("wrong!", "please fill all field!", "error");
    }
  };

  const onInputChange = (e) => {
    // convert image file to base64 string
    const file = e.target.files[0];
    setImage(e.target.files[0]);
    const reader = new FileReader();
    reader.onload = (e) => {
      setUrl(e.target.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col md="3" className="mt-2">
            <TextField
              size="small"
              fullWidth
              label="English name"
              value={e_title}
              onChange={handleE_title}
              variant="outlined"
            />
          </Col>
          <Col md="3" className="mt-2">
            <TextField
              size="small"
              fullWidth
              label="Dari name"
              value={f_title}
              onChange={handleD_title}
              variant="outlined"
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="3" className="mt-3">
            <TextField
              size="small"
              fullWidth
              label="Pashto name"
              value={p_title}
              onChange={handleP_title}
              variant="outlined"
            />
          </Col>
          <Col md="3" className="mt-3">
            <TextField
              size="small"
              fullWidth
              label="Background Color"
              value={b_color}
              onChange={handleB_Color}
              variant="outlined"
            />
          </Col>
        </Row>
        <Row className="justify-content-center mt-4">
          <Col md="6">
            <Row className="justify-content-start">
              <Col md="2">
                {url.length > 0 ? null : (
                  <div
                    style={{
                      width: 200,
                      backgroundColor: "white",
                      height: 200,
                    }}
                    className="text-center"
                  >
                    {/* <CircularProgress style={{ marginTop: "80px" }} /> */}
                  </div>
                )}
                <img
                  src={url}
                  style={{
                    width: "200px",
                    height: "200px",
                    objectFit: "contain",
                    display: url.length > 0 ? "block" : "none",
                  }}
                />
              </Col>
            </Row>
            <Row className="justify-content-start mt-3">
              <Col sm="5">
                <Button
                  fullWidth
                  onClick={() => {
                    imgs.current.click();
                  }}
                  variant="contained"
                  color="secondary"
                >
                  Select new Image
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-center mt-4">
          <Col md="6">
            <Row className="justify-content-end">
              <Col md="2">
                <Button
                  fullWidth
                  onClick={handleSave}
                  variant="contained"
                  color="secondary"
                >
                  Add
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <input
          multiple
          type="file"
          accept="image/png, image/jpeg"
          onChange={onInputChange}
          style={{ display: "none" }}
          ref={imgs}
        />
      </Container>
    </>
  );
};

export default CAdd;
