import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ThemeProvider } from "@material-ui/styles";
import * as icons from "@material-ui/icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { ViewDoctor ,updateAppointment} from "../../Services/admin/appointments";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import lightBlue from "@material-ui/core/colors/lightBlue";
import { useDispatch } from "react-redux";
import { TextField, Button, FormControl, InputLabel, Select, MenuItem ,createMuiTheme} from "@material-ui/core";
import {
    DateTimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import swal from "sweetalert";
import { root } from "../../Services/config.json";
import DateFnsUtils from '@date-io/date-fns';


const AppointmentEdit = ({ history, location }) => {
    const dispatch = useDispatch();
    let { info } = location.state;
    let { editInfo } = location.state;
    let { doctorList } = location.state;
    let id = info._id;

    const defaultMaterialTheme = createMuiTheme({
        palette: {
            primary: lightBlue,
        },
    });

   
    const [docotorName, setDoctorName] = useState("");
    const [ChangeDoctor, setChangeDoctor] = useState("");
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [newDocId, setNewDocId] = useState(editInfo.did);
    const [status,setStatus]  = useState(false)
    const [datestatus,setDateStatus]  = useState(false)


    const fullInfo = async (id) => {
        if (id) {
            let singleDocData = await ViewDoctor(editInfo.did);
            let doctor_name = singleDocData.data.data.fullname;
            setDoctorName(doctor_name)
        }
    }
    const handleDateChange =async (e)=>{
        if(e){
            setSelectedDate(e)
            setDateStatus(true)
        }
    }
    const handleChangedDoctor = async (e) => {
        setChangeDoctor(e.target.value)
        let doc_id = doctorList[e.target.value]._id;
        setNewDocId(doc_id)
        setStatus(true)
    }


    useEffect(async () => {
        dispatch(showLoading());
        try {
            await fullInfo(id);
        } catch (error) {
            let msg = "some error occured";
            let { status, data } = error.response;
            if (status == 400 || status == 401) {
                msg = data.msg;
            }
            swal("wrong!", msg, "error");
        } finally {
            dispatch(hideLoading());
        }
    }, [id]);
    const handleUpdate = async () => {
        if (status && datestatus) {
          try {
            dispatch(showLoading());
            await updateAppointment(id, {
                changeVisitedDate: selectedDate,
              newDoctorId: newDocId,
            });
            swal("success!", "successfully Appointment updated!", "success");
            history.goBack();
          } catch (error) {
            let msg = "some error occured";
            let { status, data } = error.response;
            if (status == 400 || status == 401) {
              msg = data.msg;
            }

            swal("wrong!", msg, "error");
          } finally {
            dispatch(hideLoading());
          }
        } else {
          swal("wrong!", "please fill all field!", "error");
        }
        setStatus(false)
        setDateStatus(false)
    };

    return (
        <>
            <Container>
                <Row className="justify-content-center">
                    <Col md="3" className="mt-2">
                        <TextField
                            size="small"
                            fullWidth
                            label="Paitent Name"
                            value={editInfo.name}
                            inputProps={
                                { readOnly: true, }}
                            variant="outlined"
                        />
                    </Col>
                    <Col md="3" className="mt-2">
                        <TextField
                            size="small"
                            fullWidth
                            label="Paitent ID"
                            value={editInfo.pid}
                            inputProps={
                                { readOnly: true, }}
                            variant="outlined"
                        />
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col md="3" className="mt-3">
                        <TextField
                            size="small"
                            fullWidth
                            label="Phone"
                            value={editInfo.phone}
                            inputProps={
                                { readOnly: true, }}
                            variant="outlined"
                        />
                    </Col>
                    <Col md="3" className="mt-3">
                        <TextField
                            size="small"
                            fullWidth
                            label="age"
                            value={editInfo.age}
                            inputProps={
                                { readOnly: true, }}
                            variant="outlined"
                        />
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col md="6" className="mt-2">
                        <TextField
                            size="small"
                            fullWidth
                            label="Doctor Name"
                            value={docotorName}
                            inputProps={
                                { readOnly: true, }}
                            variant="outlined"
                        />
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col md="6" className="mt-2">
                        <TextField
                            size="small"
                            fullWidth
                            label="Visit Date"
                            value={editInfo.visit_date}
                            inputProps={
                                { readOnly: true, }}
                            variant="outlined"
                        />
                    </Col>
                </Row>
                <Row className="justify-content-center mt-2">
                    <Col md="6" className="mt-3">
                        <FormControl fullWidth>
                            <InputLabel>Change Doctor</InputLabel>
                            <Select
                                onChange={handleChangedDoctor}
                                value={ChangeDoctor}
                                label="Change Doctor"
                            >
                                {doctorList.map((obj, idx) => (
                                    <MenuItem key={idx} value={idx}>
                                        {obj.fullname}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Col>
                </Row>
                <Row className="justify-content-center ">
                    <Col md="6" className="mt-4">
                        <InputLabel>Change Schedule</InputLabel>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <ThemeProvider theme={defaultMaterialTheme}>
                                <DateTimePicker
                                    fullWidth
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    animateYearScrolling={false}
                                />
                            </ThemeProvider>

                        </MuiPickersUtilsProvider>
                    </Col>
                </Row>
                <Row className="justify-content-center mt-2">
                    <Col md="3" className="mt-3" >
                        <Button
                            fullWidth
                            onClick={handleUpdate}
                            variant="contained"
                            color="secondary"
                        >
                            Update
                        </Button>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default AppointmentEdit;
