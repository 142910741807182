import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  Button,
  CardContent,
  Typography,
  Chip,
  CardActions,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
} from "@material-ui/core";

import { addTime, delTime } from "../../../Services/doctor/schedule";

import "bootstrap/dist/css/bootstrap.min.css";

import { showLoading, hideLoading } from "react-redux-loading-bar";
import { useDispatch } from "react-redux";
import swal from "sweetalert";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    maxHeight: 275,
    minHeight: 275,
  },

  title: {
    fontSize: 20,
    textAlign: "center",
    fontWeight: "bold",
  },

  searchBtn: {
    borderRadius: 0,
    color: "#fff",
    backgroundColor: "#6DBCDB",
    "&:hover": {
      backgroundColor: "#0097e6",
    },
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

const MUICard = ({ title, times, day, setTime }) => {
  const [open, SetOpen] = useState(false);
  const [hour, setHour] = useState("");
  const [min, setMin] = useState("");
  const [count, setCount] = useState("");

  const classes = useStyles();

  const handleClose = () => {
    SetOpen(false);
  };

  const handleOpen = () => {
    SetOpen(true);
  };

  const handleHour = (e) => {
    setHour(e.target.value);
  };
  const handleMin = (e) => {
    setMin(e.target.value);
  };
  const handleCount = (e) => {
    setCount(e.target.value);
  };

  const dispatch = useDispatch();

  const handleDelete = async (time) => {
    let isSure = await swal({
      title: "Are you sure?",
      text: `Are you sure to delete ${time}!`,
      icon: "warning",
      buttons: ["No", "Yes"],
      dangerMode: true,
    });
    if (isSure) {
      try {
        dispatch(showLoading());
        await delTime(time, day);
        let tmp = times.filter((item) => item != time);
        setTime(tmp);
        swal("success!", "successfully deleted!", "success");
      } catch (error) {
        let msg = "some error occured";
        let { status, data } = error.response;
        if (status == 400 || status == 401) {
          msg = data.msg;
        }
        swal("wrong!", msg, "error");
      } finally {
        dispatch(hideLoading());
      }
    }
  };
  const handleAddTime = async () => {
    if (
      hour.length > 0 &&
      min.length > 0 &&
      count.length > 0 &&
      parseInt(count) > 0
    ) {
      dispatch(showLoading());
      try {
        let h = hour;
        let m = min;
        if (h.length == 1) h = `0${h}`;
        if (m.length == 1) m = `0${m}`;
        await addTime({ dayOfWeek: day, time: `${h}:${m}`, count });
        let tmp = [...times];
        tmp.push(`${h}:${m}`);
        setTime(tmp);
        swal("success!", "successfully added!", "success");
      } catch (error) {
        let msg = "some error occured";
        let { status, data } = error.response;
        if (status == 400 || status == 401) {
          msg = data.msg;
        }
        swal("wrong!", msg, "error");
      } finally {
        dispatch(hideLoading());
        SetOpen(false);
      }
    } else {
      swal("wrong!", "Please fill all fiald.", "error");
    }
  };

  return (
    <>
      <Card className={classes.root}>
        <CardContent>
          <Typography className={classes.title} gutterBottom>
            {title}
          </Typography>
          <div
            className="text-center"
            style={{ overflowY: "auto", height: "150px", overflowX: "hidden" }}
          >
            {times.map((item) => (
              <Chip
                key={item}
                variant="outlined"
                label={item}
                onDelete={() => {
                  handleDelete(item);
                }}
                className={classes.chip}
              />
            ))}
          </div>
        </CardContent>
        <CardActions className="justify-content-center">
          <Button
            onClick={handleOpen}
            variant="contained"
            className={classes.searchBtn}
          >
            Add
          </Button>
        </CardActions>
      </Card>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="alert-title">
          <span
            style={{ fontSize: "16px", fontWeight: "bold" }}
          >{`add time for ${title}`}</span>
        </DialogTitle>
        <DialogContent className="text-center">
          <div>
            <TextField
              placeholder="Hour"
              variant="outlined"
              size="small"
              type="number"
              style={{ width: 80 }}
              value={hour}
              onChange={handleHour}
            />
            <span
              className="mx-1 mt-2"
              style={{ display: "inline-block", fontWeight: "bold" }}
            >
              :
            </span>
            <TextField
              placeholder="Min"
              variant="outlined"
              size="small"
              type="number"
              value={min}
              onChange={handleMin}
              style={{ width: 80 }}
            />{" "}
            <TextField
              placeholder="Count"
              variant="outlined"
              size="small"
              type="number"
              value={count}
              onChange={handleCount}
              style={{ width: 80 }}
            />
          </div>
          <span
            className="text-secondary mt-2"
            style={{ fontSize: "10px", display: "inline-block" }}
          >
            Enter your time in 24 hour format.
          </span>
        </DialogContent>
        <DialogActions className="justify-content-center">
          <Button onClick={handleAddTime} className={classes.searchBtn}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MUICard;
