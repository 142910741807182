import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { getSingleInfo ,UpdateFeedback} from "../../Services/admin/feedback";
import StarRatings from 'react-star-ratings';
import {
  TextField,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { useDispatch } from "react-redux";
import swal from "sweetalert";
import { Pagination } from "@material-ui/lab";
import { root } from "../../Services/config.json";



const ViewPatient = ({ history, location }) => {
  const { id } = location.state;
  const [user, setUser] = useState([])
  const [index, setIndex] = useState(0)
  const [docId, setDocId] = useState()
  const [flag, setFlag] = useState(false);
  const [TreatStarts, setTreatStarts] = useState(0)
  const [KnowStarts, setKnowStarts] = useState(0)
  const [CleanStarts, setCleanStarts] = useState(0);
  const [DocStarts, setDocStarts] = useState(0);
  const [comment, setComment] = useState("");
  const dispatch = useDispatch();


  const initRows = async (id) => {
    if (id > 0 && id != "undefined") {
      let userData = await getSingleInfo(id)
      if (userData.data.data.length > 0) {
        setDocId(userData.data.data[0].doctor[0]._id)
        setUser(userData.data.data)
        setFlag(true)
      }
      else {
        swal("wrong!", "Feedback Not Found", "error");
        history.goBack()
      }

    }

  }
  const handlePage = async (e, value) => {
    setIndex(value - 1)
    setDocId(user[value - 1].doctor[0]._id);
    setTreatStarts(0);
    setKnowStarts(0);
    setCleanStarts(0);
    setDocStarts(0);
    setComment("")
  };
  const handleComment = async (e) => {
    setComment(e.target.value)
  }

  const handleUpdate = async () =>{
      try {
        dispatch(showLoading());
        await UpdateFeedback(user[index].patientId, docId, {
          starsForDoc: DocStarts,
          cleaningForDoc : CleanStarts,
          knowledgeForDoc : KnowStarts,
          treatmentForDoc : TreatStarts,
          comment: comment,
        });
        swal("success!", "successfully FeedBack updated!", "success");
        history.goBack();
      } catch (error) {
        let msg = "some error occured";
        let { status, data } = error.response;
        if (status == 400 || status == 401) {
          msg = data.msg;
        }

        swal("wrong!", msg, "error");
      } finally {
        dispatch(hideLoading());
      }
    
  }

  // const handleImgLoad = () => {
  //   if (!load) setLoad(true);
  // };
  // const handleRepLoad = () => {
  //   if (!loadReport) setLoadReport(true)
  // }
  useEffect(async () => {
    dispatch(showLoading());
    try {
      await initRows(id)
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401) {
        msg = data.msg;
      }
      swal("wrong!", msg, "error");
    } finally {
      dispatch(hideLoading());
    }
  }, [id]);
  return (
    <>
      {flag ? <Container>
        <Row className="justify-content-center">
          <Col lg={12} xs={12} className="justify-content-center">
            <Typography variant="h6" component="h2" className="text-center">
              User Profile Detail
            </Typography>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg={8} xs={12} md={6} className="mt-3">
            <Row className="justify-content-center mt-4">
              <Col lg={6} xs={6} md="3" className="mt-2">
                <TextField
                  size="small"
                  fullWidth
                  label="Paitent Name"
                  inputProps={
                    { readOnly: true, }}
                  value={user[0].name}
                  variant="outlined"
                />
              </Col>
              <Col lg={6} xs={6} md="3" className="mt-2">
                <TextField
                  size="small"
                  fullWidth
                  label="Paitent ID"
                  inputProps={
                    { readOnly: true, }}
                  value={user[0].patientId}
                  variant="outlined"
                />
              </Col>
            </Row>
            <Row className="justify-content-center mt-4">
              <Col lg={6} xs={6} md="3" className="mt-2">
                <TextField
                  size="small"
                  fullWidth
                  label="Age"
                  inputProps={
                    { readOnly: true, }}
                  value={user[0].age}
                  variant="outlined"
                />
              </Col>
              <Col lg={6} xs={6} md="3" className="mt-2">
                <TextField
                  size="small"
                  fullWidth
                  label="visited Date"
                  inputProps={
                    { readOnly: true, }}
                  inputProps={
                    { readOnly: true, }}
                  value={user[0].visit_date}
                  variant="outlined"
                />
              </Col>
            </Row>
            <Row className="justify-content-center mt-4">
              <Col lg={6} xs={6} md="3" className="mt-2">
                <TextField
                  size="small"
                  fullWidth
                  label="Phone"
                  inputProps={
                    { readOnly: true, }}
                  value={user[0].phone}
                  variant="outlined"
                />
              </Col>
              <Col lg={6} xs={6} md="3" className="mt-2">
                <TextField
                  size="small"
                  fullWidth
                  label="Visited"
                  inputProps={
                    { readOnly: true, }}
                  value={user[0].visited}
                  variant="outlined"
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Container>
          <Row className="justify-content-center mt-3"  >
            <Col lg={12} xs={6}>
              <Typography variant="h6" component="h2" className="text-center">
                FeedBack No {index + 1}
              </Typography>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={8} xs={12} md={6} className="mt-2">
              <Row className="justify-content-center mt-2">
                <Col lg={6} xs={6} md="3" className="mt-2">
                  <TextField
                    size="small"
                    fullWidth
                    label="Doctor Name"
                    inputProps={
                      { readOnly: true, }}
                    value={user[index].doctor[0].fullname}
                    variant="outlined"
                  />
                </Col>
                <Col lg={6} xs={6} md="3" className="mt-2">
                  <TextField
                    size="small"
                    fullWidth
                    label="Doctor Email"
                    inputProps={
                      { readOnly: true, }}
                    value={user[index].doctor[0].email}
                    variant="outlined"
                  />
                </Col>
              </Row>
              <Row className="justify-content-center mt-2">
                <Col lg={6} xs={6} md="3" className="mt-2">
                  <TextField
                    size="small"
                    fullWidth
                    label="Phone"
                    inputProps={
                      { readOnly: true, }}
                    value={user[index].doctor[0].phone}
                    variant="outlined"
                  />
                </Col>
                <Col lg={6} xs={6} md="3" className="mt-2">
                  <TextField
                    size="small"
                    fullWidth
                    label="speciality"
                    inputProps={
                      { readOnly: true, }}
                    inputProps={
                      { readOnly: true, }}
                    value={user[index].doctor[0].speciality}
                    variant="outlined"
                  />
                </Col>
              </Row>
              <Row className="justify-content-center mt-2">
                <Col lg={12} xs={6} md="3" className="mt-2">
                  <TextField
                    size="small"
                    fullWidth
                    label="Address"
                    inputProps={
                      { readOnly: true, }}
                    value={user[index].doctor[0].address}
                    variant="outlined"
                  />
                </Col>
              </Row>
              <Row className="justify-content-center mt-2">
                <Col lg={12} xs={6}>
                  <Row className="justify-content-center mt-2">
                    <StarRatings
                      rating={user[index].treatmentForDoc}
                      starRatedColor="blue"
                      changeRating={null}
                      numberOfStars={5}
                      name='rating'
                    />
                  </Row>
                </Col>
                <Col lg={12} xs={6}>
                  <Row className="justify-content-center mt-2">
                    <Typography variant="h6" component="h2" className="text-center">
                      Change Treatment Rating
                    </Typography>
                  </Row>
                </Col>
                <Col lg={12} xs={6}>
                  <Row className="justify-content-center mt-2">
                    <StarRatings
                      rating={TreatStarts}
                      starRatedColor="yellow"
                      changeRating={setTreatStarts}
                      numberOfStars={5}
                      name='rating'
                    />
                  </Row>
                </Col>
                <Col lg={12} xs={6}>
                  <Row className="justify-content-center mt-2">
                    <Typography variant="h6" component="h2" className="text-center">
                      - - - - - - - - - - - - - -  - - - - - - - - - - - - - - -
                    </Typography>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-center mt-2">
                <Col lg={12} xs={6}>
                  <Row className="justify-content-center mt-2">
                    <StarRatings
                      rating={user[index].knowledgeForDoc}
                      starRatedColor="blue"
                      changeRating={null}
                      numberOfStars={5}
                      name='rating'
                    />
                  </Row>
                </Col>
                <Col lg={12} xs={6}>
                  <Row className="justify-content-center mt-2">
                    <Typography variant="h6" component="h2" className="text-center">
                      Change Knowledge Rating
                    </Typography>
                  </Row>
                </Col>
                <Col lg={12} xs={6}>
                  <Row className="justify-content-center mt-2">
                    <StarRatings
                      rating={KnowStarts}
                      starRatedColor="yellow"
                      changeRating={setKnowStarts}
                      numberOfStars={5}
                      name='rating'
                    />
                  </Row>
                </Col>
                <Col lg={12} xs={6}>
                  <Row className="justify-content-center mt-2">
                    <Typography variant="h6" component="h2" className="text-center">
                      - - - - - - - - - - - - - -  - - - - - - - - - - - - - - -
                    </Typography>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-center mt-2">
                <Col lg={12} xs={6}>
                  <Row className="justify-content-center mt-2">
                    <StarRatings
                      rating={user[index].cleaningForDoc}
                      starRatedColor="blue"
                      changeRating={null}
                      numberOfStars={5}
                      name='rating'
                    />
                  </Row>
                </Col>
                <Col lg={12} xs={6}>
                  <Row className="justify-content-center mt-2">
                    <Typography variant="h6" component="h2" className="text-center">
                      Change Cleaning Rating
                    </Typography>
                  </Row>
                </Col>
                <Col lg={12} xs={6}>
                  <Row className="justify-content-center mt-2">
                    <StarRatings
                      rating={CleanStarts}
                      starRatedColor="yellow"
                      changeRating={setCleanStarts}
                      numberOfStars={5}
                      name='rating'
                    />
                  </Row>
                </Col>
                <Col lg={12} xs={6}>
                  <Row className="justify-content-center mt-2">
                    <Typography variant="h6" component="h2" className="text-center">
                      - - - - - - - - - - - - - -  - - - - - - - - - - - - - - -
                    </Typography>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-center mt-2">
                <Col lg={12} xs={6}>
                  <Row className="justify-content-center mt-2">
                    <StarRatings
                      rating={user[index].starsForDoc}
                      starRatedColor="blue"
                      changeRating={null}
                      numberOfStars={5}
                      name='rating'
                    />
                  </Row>
                </Col>
                <Col lg={12} xs={6}>
                  <Row className="justify-content-center mt-2">
                    <Typography variant="h6" component="h2" className="text-center">
                      Change Doctor Rating
                    </Typography>
                  </Row>
                </Col>
                <Col lg={12} xs={6}>
                  <Row className="justify-content-center mt-2">
                    <StarRatings
                      rating={DocStarts}
                      starRatedColor="yellow"
                      changeRating={setDocStarts}
                      numberOfStars={5}
                      name='rating'
                    />
                  </Row>
                </Col>
                <Col lg={12} xs={6}>
                  <Row className="justify-content-center mt-2">
                    <Typography variant="h6" component="h2" className="text-center">
                      - - - - - - - - - - - - - -  - - - - - - - - - - - - - - -
                    </Typography>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col md="6" className="mt-2">
                  <TextField
                    size="small"
                    fullWidth
                    label="Comments"
                    onChange={handleComment}
                    value={comment}
                    variant="outlined"
                  />
                </Col>
              </Row>
              <Row className="justify-content-center mt-2">
                <Col md="4" lg={4} sm={4} className="mt-3" >
                  <Button
                    fullWidth
                    onClick={handleUpdate}
                    variant="contained"
                    color="secondary"
                  >
                    Update Feedback
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="mt-3">
            <Pagination
              count={user.length}
              onChange={handlePage}
              page={index + 1}
              style={{ display: "flex", justifyContent: "center" }}
              variant="outlined"
              color="secondary"
            />
          </div>
        </Container>
      </Container> : <Container></Container>}

    </>
  );
};

export default withRouter(ViewPatient);
