import React, { useEffect, useState } from "react";
import CardAppointment from "../../containers/CardAppointment";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { useDispatch } from "react-redux";
import { getChartInfo } from "../../Services/doctor/Dashboard";
import { Line } from "react-chartjs-2";
import swal from "sweetalert";


const Dashboard = () => {
  const dispatch = useDispatch();
  const [daily, setDaily] = useState(0);
  const [monthly, setMonthly] = useState(0);
  const [yearly, setYearly] = useState(0);
  const [chartData, setChartData] = useState({});
  const [flag,setFlag] = useState(false);

  const initRows = async () => {
    dispatch(showLoading());
    const { data } = await getChartInfo();
    console.log("dashboard");
    let tmp = [];
    for (let i = 0; i < 12; i++) {
      tmp.push(data.months[i]);
    }
    setChartData({
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [
        {
          label: "Report of patient",
          data: tmp,
          fill: false,
          backgroundColor: "rgb(255, 99, 132)",
          borderColor: "rgba(255, 99, 132, 0.2)",
        },
      ],
    });
    setDaily(data.daily);
    setMonthly(data.monthly);
    setYearly(data.yearly);
  };
  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  useEffect(async () => {
    try {
      await initRows();
      setFlag(true)
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401) {
        msg = data.msg;
      }
      swal("wrong!", msg, "error");
    } finally {
      dispatch(hideLoading());
      console.log("finally");
    }
  }, []);
  return (
    <>{flag ? <Container>
        <Row className="justify-content-between">
          <Col lg="3" className="mt-2">
            <div style={{ color: "red" }}></div>
            <CardAppointment
              color="#706fd3"
              text="Today Appointments"
              count={daily}
            />
          </Col>
          <Col lg="3" className="mt-2">
            <CardAppointment
              color="#ccae62"
              text="Month Appointments"
              count={monthly}
            />
          </Col>
          <Col lg="3" className="mt-2">
            <CardAppointment
              color="#e66767"
              text="Year Appointments"
              count={yearly}
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg={10}>
            <Line data={chartData} options={options} />
          </Col>
        </Row>
      </Container> : <Container></Container>
      }
    </>
  );
};

export default Dashboard;
