import React from "react";
import MainLayout from "./containers/MainLayout";
import Profile from "./components/profile/Profile";
import { Route, Switch } from "react-router";
import Dashboard from "./components/Dashboard/Dashboard";
import Appointment from "./components/Appointment/Appointment";
import AddAppointment from "./components/Appointment/AddAppointment";
import AssistantManagement from "./components/AssistantManagement/AssistantManagement";
import AssistantManagementEdit from "./components/AssistantManagement/AssistantManagmentEdit";
import AssistantManagementAdd from "./components/AssistantManagement/AssistantManagmentAdd";
import LaboratoryReport from "./components/LaboratoryReport/LaboratoryReport";
import LaboratoryReportAdd from "./components/LaboratoryReport/LaboratoryReportAdd";
import LaboratoryReportEdit from "./components/LaboratoryReport/LaboratoryReportEdit";
import PatientReports from "./components/PatientReports/PatientReports";
import PatientReportsAdd from "./components/PatientReports/PatientReportsAdd";
import PatientReportEdit from "./components/PatientReports/PatientReportEdit";
import Patients from "./components/Patients/Patients";
import Admin from "./components/Admin/Admin";
import Categories from "./components/Categories/Categories";
import CategoryAdd from "./components/Categories/CategoriesAdd";
import CategoryEdit from "./components/Categories/CategoriesEdit";
import Doctor from "./components/Doctor/Doctor";
import Hospital from "./components/Hospital/Hospital";
import HospitalAdd from "./components/Hospital/HospitalAdd";
import HospitalEdit from "./components/Hospital/HospitalEdit";
import DoctorAdd from "./components/Doctor/DoctorAdd";
import DoctorEdit from "./components/Doctor/DoctorEdit";
import AdminAdd from "./components/Admin/adminAdd";
import Reports from "./components/Patients/Reports";
import Report from "./components/Patients/Report";
import History from "./components/Patients/History";
import User from "./components/User/User";
import Lab from "./components/Lab/Lab";
import Pharmacy from "./components/Pharmacy/Pharmacy";
import PharmacyAdd from "./components/Pharmacy/PharmacyAdd";
import PharmacyEdit from "./components/Pharmacy/PharmacyEdit";
import Ads from "./components/Ads/Ads";
import AdsAdd from "./components/Ads/AddAds";
import AdsEdit from "./components/Ads/EditAds";
import LabAdd from "./components/Lab/LabAdd";
import LabEdit from "./components/Lab/LabEdit";
import LabCheckUp from "./components/LabDashboard/Services/LabCheckUp";
import LabAddCheckUp from "./components/LabDashboard/Services/LabAddCheckUp";
import LabEditCheckUp from "./components/LabDashboard/Services/LabEditCheckUp";
import Schedule from "./components/Settings/Schedule/Schedule";
import ChangePasswrod from "./components/Settings/ChangePassword/ChangePassword";
import Login from "./components/Login/Login";
import Appointments from './components/AdminAppointments/appointment'
import AppointmentEdit from './components/AdminAppointments/appointmentEdit'
import AdminPaitents from './components/AdminPaitents/patients';
import ViewPatient from "./components/AdminPaitents/ViewPatient";
import Feedback from './components/Feedback/feedback';
import { withRouter } from "react-router";
import jwt_decode from "jwt-decode";
import EditFeedback from "./components/Feedback/editFeedback";
import HCheckUp from "./components/Hospital/HCheckUp"
import HCheckUpAdd from './components/Hospital/HCheckUpAdd';
import HCheckUpEdit from './components/Hospital/HCheckUpEdit';
import PAddCheckUp from './components/PharmacyDashboard/services/PAddCheckUp';
import PCheckUpEdit from './components/PharmacyDashboard/services/PEditCheckUp';
import EditAppointment from './components/Appointment/EditAppointment';
import HProfile from './components/hProfile/profile';
import HDCheckUp from "./components/hProfile/services/HCheckUp";
import HDAddCheckUp from './components/hProfile/services/HCheckUpAdd';
import HDEditCheckUp from './components/hProfile/services/HCheckUpEdit';
import HDoctors from './components/hProfile/doctors/Doctor';
import HDoctorEdit from './components/hProfile/doctors/DoctorEdit';
import HDoctorAdd from './components/hProfile/doctors/DoctorAdd';
import HPatients from './components/hProfile/patients/Patients';
import HPatientsView from './components/hProfile/patients/ViewPatient';
import HAppointments from './components/hProfile/Appointments/appointment';
import HAppointmentsEdit from './components/hProfile/Appointments/appointmentEdit';
import HFeebacks from './components/hProfile/Feedback/feedback';
import PDashboard from './components/PharmacyDashboard/Profile';
import LDashboard from './components/LabDashboard/profile';
import PharmacyCheckUp from './components/PharmacyDashboard/services/PCheckUp';
import Patient_new from './components/AdminPaitents/Patient_new';
import PatientView from './components/Patients/PatientView';
import ViewReports from './components/AdminPaitents/Reports';
import Cities from './components/Cities/Cities';
import AddCity from './components/Cities/AddCities'


const App = ({ history, location }) => {
  let { pathname } = location;
  const { role } = localStorage.jwtoken
    ? jwt_decode(localStorage.jwtoken)
    : "doctor";

  pathname = pathname.toLowerCase();
  const jwtoken = localStorage.getItem("jwtoken");
  if (!jwtoken) {
    if (pathname != "/login") history.replace("/login");
  } else if (pathname === "/") {
    history.replace(role == "doctor" ? "/dashboard" : role == "hospital" ? "/hProfile" :  role == "pharmacy" ? "/PharmacyDashboard" : role == "lab" ? "/LabDashboard" :  "/Admin");
  } else if (pathname === "/login") {
    const jwtoken = localStorage.getItem("jwtoken");
    if (jwtoken) {
      history.replace(role == "doctor" ? "/dashboard" : role == "hospital" ? "/hProfile" : role == "pharmacy" ? "/PharmacyDashboard" : role == "lab" ? "/LabDashboard" : "/Admin");
    }
  } else if (pathname === "/logout") {
    localStorage.removeItem("jwtoken");
    localStorage.clear();
    window.location.replace("/login");
  }
  return (
    <>
      <Switch>
        <Route
          exact
          path="/Login"
          component={(props) => {
            return <Login {...props} />;
          }}
        />

        <MainLayout role={role}>
          {role == "doctor" ? (
            <>
              <Route exact path="/Dashboard" component={Dashboard} />
              <Route exact path="/Profile" component={Profile} />
              <Route exact path="/Appointments" component={Appointment} />
              <Route
                exact
                path="/AssistantManagement"
                component={AssistantManagement}
              />
              <Route
                exact
                path="/AssistantManagementAdd"
                component={AssistantManagementAdd}
              />
              <Route
                exact
                path="/AssistantManagementEdit"
                component={AssistantManagementEdit}
              />
              <Route
                exact
                path="/LaboratoryReport"
                component={LaboratoryReport}
              />
              <Route
                exact
                path="/LaboratoryReportAdd"
                component={LaboratoryReportAdd}
              />
              <Route
                exact
                path="/LaboratoryReportEdit"
                component={LaboratoryReportEdit}
              />
              <Route exact path="/Patients" component={Patients} />
              <Route exact path="/PatientDetailReports" component={Report} />
              <Route exact path="/PatientsHistory" component={PatientView} />
              <Route
                exact
                path="/Doctorsprescription"
                component={PatientReports}
              />
              <Route
                exact
                path="/DoctorsprescriptionAdd"
                component={PatientReportsAdd}
              />
              <Route
                exact
                path="/DoctorsprescriptionEdit"
                component={PatientReportEdit}
              />

              <Route exact path="/Settings" component={Schedule} />
              <Route exact path="/ChangePassword" component={ChangePasswrod} />
              <Route exact path="/Surveys" component={Profile} />
              <Route exact path="/TicketManagment" component={Profile} />
              <Route exact path="/AppointmentsAdd" component={AddAppointment} />
              <Route exact path="/EditAppointment" component={EditAppointment} />
            </>
          ) : (
            <>
              {role == "hospital" ? (
                <>
                  <Route exact path="/hProfile" component={HProfile} />
                  <Route exact path="/HDCheckUp" component={HDCheckUp} />
                  <Route exact path="/HDCheckUp/Add" component={HDAddCheckUp} />
                  <Route exact path="/HDCheckUp/Edit" component={HDEditCheckUp} />
                  <Route exact path="/HPatients" component={HPatients} />
                  <Route exact path="/HDoctors" component={HDoctors} />
                  <Route exact path="/HDoctors/Edit" component={HDoctorEdit} />
                  <Route exact path="/HDoctors/Add" component={HDoctorAdd} />
                  <Route exact path="/HPatients/View" component={HPatientsView} />
                  <Route exact path="/HAppointments" component={HAppointments} />
                  <Route exact path="/HAppointments/Edit" component={HAppointmentsEdit} />
                  <Route exact path="/HFeeback" component={HFeebacks} />

                </>
              ) : (
                <>
                  {role == "pharmacy" ? (
                    <>
                      <Route exact path="/PharmacyDashboard" component={PDashboard} />
                      <Route exact path="/Products" component={PharmacyCheckUp} />
                      <Route exact path="/Products/PAddCheckUp" component={PAddCheckUp} />
                      <Route exact path="/Products/PCheckUpEdit" component={PCheckUpEdit} />
                    </>
                  ) : (
                  <>
                  {role == "lab" ? (
                    <>
                      <Route exact path="/LabDashboard" component={LDashboard} />
                      <Route exact path="/LabProducts" component={LabCheckUp} />
                      <Route exact path="/LabProducts/labAddCheckUp" component={LabAddCheckUp} />
                      <Route exact path="/LabProducts/labEditCheckUp" component={LabEditCheckUp} />
                    </>
                  ): (
                    <>
                      <Route exact path="/admin" component={Admin} />
                      <Route exact path="/adminAdd" component={AdminAdd} />
                      <Route exact path="/doctor" component={Doctor} />
                      <Route exact path="/pharmacy" component={Pharmacy} />
                      <Route exact path="/pharmacyAdd" component={PharmacyAdd} />
                      <Route exact path="/pharmacyEdit" component={PharmacyEdit} />
                      <Route exact path="/ads" component={Ads} />
                      <Route exact path="/adsAdd" component={AdsAdd} />
                      <Route exact path="/adsEdit" component={AdsEdit} />
                      <Route exact path="/users" component={User} />
                      <Route exact path="/lab" component={Lab} />
                      <Route exact path="/labAdd" component={LabAdd} />
                      <Route exact path="/labEdit" component={LabEdit} />
                      <Route exact path="/doctorAdd" component={DoctorAdd} />
                      <Route exact path="/doctorEdit" component={DoctorEdit} />
                      <Route exact path="/hospital" component={Hospital} />
                      <Route exact path="/hospitalAdd" component={HospitalAdd} />
                      <Route exact path="/Categories" component={Categories} />
                      <Route exact path="/CategoriesAdd" component={CategoryAdd} />
                      <Route exact path="/CategoriesEdit" component={CategoryEdit} />
                      <Route exact path="/hospitalEdit" component={HospitalEdit} />
                      <Route exact path="/Appointments" component={Appointments} />
                      <Route exact path="/appointmentEdit" component={AppointmentEdit} />
                      <Route exact path="/patients" component={AdminPaitents} />
                      <Route exact path="/patients/ViewPatient" component={Patient_new} />
                      <Route exact path="/feedback" component={Feedback} />
                      <Route exact path="/EditFeedback" component={EditFeedback} />
                      <Route exact path="/HCheckUp" component={HCheckUp} />
                      <Route exact path="/HAddCheckUp" component={HCheckUpAdd} />
                      <Route exact path="/HCheckUpEdit" component={HCheckUpEdit} />
                      <Route exact path="/PAddCheckUp" component={PAddCheckUp} />
                      <Route exact path="/PCheckUpEdit" component={PCheckUpEdit} />
                      <Route exact path="/patients/ViewPatient/Reports" component={ViewReports} />
                      <Route exact path="/Cities" component={Cities} />
                      <Route exact path="/Cities/AddCity" component={AddCity} />
                    </>
                  )}
                  </>
                  )
                  }
                </>
              )}
            </>
          )
          }
        </MainLayout>
      </Switch>
    </>
  );
};

export default withRouter(App);
