import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import MUICard from "./Card";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { useDispatch } from "react-redux";
import swal from "sweetalert";
import { getSchedule } from "../../../Services/doctor/schedule";
const Schedule = () => {
  const [mondayTimes, setMondayTimes] = useState([]);
  const [tuesdayTimes, setTuesdayTimes] = useState([]);
  const [wednesdayTimes, setWednedayTimes] = useState([]);
  const [thursdayTimes, setThurdayTimes] = useState([]);
  const [fridayTimes, setFridayTimes] = useState([]);
  const [saturdayTimes, setSaturdayTimes] = useState([]);
  const [sundayTimes, setSundayTimes] = useState([]);
  const dispatch = useDispatch();
  useEffect(async () => {
    dispatch(showLoading());
    try {
      let { data } = await getSchedule();
      data = data.data;
      for (let i = 0; i < data.length; i++) {
        if (data[i].dayOfWeek == 0) setSundayTimes(data[i].times);
        if (data[i].dayOfWeek == 1) setMondayTimes(data[i].times);
        if (data[i].dayOfWeek == 2) {
          setTuesdayTimes(data[i].times);
        }
        if (data[i].dayOfWeek == 3) setWednedayTimes(data[i].times);
        if (data[i].dayOfWeek == 4) setThurdayTimes(data[i].times);
        if (data[i].dayOfWeek == 5) setFridayTimes(data[i].times);
        if (data[i].dayOfWeek == 6) setSaturdayTimes(data[i].times);
      }
    } catch (error) {
      //
    } finally {
      dispatch(hideLoading());
    }
  }, []);
  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col md="4" className="mt-2">
            <MUICard
              title="Monday"
              day={1}
              times={mondayTimes}
              setTime={setMondayTimes}
            />
          </Col>
          <Col md="4" className="mt-2">
            <MUICard
              title="Tuesday"
              day={2}
              times={tuesdayTimes}
              setTime={setTuesdayTimes}
            />
          </Col>
          <Col md="4" className="mt-2">
            <MUICard
              title="Wednesday"
              day={3}
              times={wednesdayTimes}
              setTime={setWednedayTimes}
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="4" className="mt-2">
            <MUICard
              title="Thursday"
              day={4}
              times={thursdayTimes}
              setTime={setThurdayTimes}
            />
          </Col>
          <Col md="4" className="mt-2">
            <MUICard
              title="friday"
              day={5}
              times={fridayTimes}
              setTime={setFridayTimes}
            />
          </Col>
          <Col md="4" className="mt-2">
            <MUICard
              title="saturday"
              day={6}
              times={saturdayTimes}
              setTime={setSaturdayTimes}
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="4" className="mt-2">
            <MUICard
              title="sunday"
              day={0}
              times={sundayTimes}
              setTime={setSundayTimes}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Schedule;
