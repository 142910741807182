import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { TextField, InputAdornment, IconButton, Typography, CircularProgress } from "@material-ui/core";
import * as icons from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import FTable from "./fTable";
import { getHospitalFeedbacks } from "../../../Services/Hospital/Feedback";
import { Pagination } from "@material-ui/lab";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import swal from "sweetalert";
import StarRatings from 'react-star-ratings';
import { root } from "../../../Services/config.json";
import { set } from "date-fns/esm";

const useStyles = makeStyles((theme) => ({
    Btn: {
        borderRadius: 0,
        color: "#fff",
        backgroundColor: "#6DBCDB",
        "&:hover": {
            backgroundColor: "#0097e6",
        },
    },
}));

const Feedback = ({ history, location }) => {
    let hid = localStorage.getItem("id")
    let hospitalName = localStorage.getItem("name");
    let hospitalPhoto = localStorage.getItem("photo")
    const dispatch = useDispatch();
    const classes = useStyles();
    const [AvarageHospitalRating, setAvarageHospitalRating] = useState(0);
    const [totalHospitalVisiter,setTotalHospitalVisiter] = useState(0);
    const [page, setPage] = useState(1);
    const [rows, setRows] = useState([]);
    const [count, setCount] = useState(1);
    const [load, setLoad] = useState(false);







    const initRows = async (page) => {
        dispatch(showLoading());
        let { data } = await getHospitalFeedbacks(hid);
        dispatch(hideLoading());
        console.log(data.data);
        setAvarageHospitalRating(data.AvarageHospitalRating);
        setTotalHospitalVisiter(data.totalHospitalVisiter);
        setCount(data.data.length)
        setRows(data.data);
        setLoad(true)
    };
    const handleImgLoad = () => {
        if (!load) setLoad(true);
    };
    const handlePage = async (e, value) => {
        setPage(value);
    };
    useEffect(async () => {
        try {
            await initRows(page);
        } catch (error) {
            let msg = "some error occured";
            let { status, data } = error.response;
            if (status == 400 || status == 401) {
                msg = data.msg;
            }
            swal("wrong!", msg, "error");
        } finally {

        }
    }, [page]);

    return (
        <>
            <Container>
                <Row className="justify-content-center mb-2 mt-3">
                    <Col lg={4}>
                        {load ? null : (
                            <div
                                style={{
                                    width: 250,
                                    backgroundColor: "white",
                                    height: 250,
                                }}
                                className="text-center"
                            >
                                <CircularProgress style={{ marginTop: "100px" }} />
                            </div>
                        )}
                        <img
                            src={`${root}${hospitalPhoto}`}
                            id="profile"
                            onLoad={handleImgLoad}
                            style={{
                                width: "250px",
                                height: "250px",
                                objectFit: "contain",
                                display: load ? "block" : "none",
                            }}
                        />
                    </Col>
                    <Col lg={6}>
                        <Row className="mb-2 mt-3 ">
                            <Typography variant="h6">Name : {hospitalName} </Typography></Row>
                        <Row className="mb-2 mt-3" >
                            <Typography variant="h6">Total Visitors = {totalHospitalVisiter} </Typography></Row>
                        <Row className="mt-3">
                            <Col lg={12}>
                                <StarRatings
                                    rating={AvarageHospitalRating}
                                    starRatedColor="blue"
                                    changeRating={null}
                                    numberOfStars={5}
                                    name='rating'
                                />
                            </Col>
                            <Col><Typography variant="h6">(Average Hospital Rating)</Typography></Col>
                        </Row>
                    </Col>

                </Row>
                <Row>
                    <Col xs={12} className="mt-2">
                        <FTable rows={rows} load={load} />
                    </Col>
                </Row>
                {count > 6 ? (
                    <div className="mt-3">
                        <Pagination
                            count={parseInt((count + 5) / 6)}
                            onChange={handlePage}
                            page={page}
                            variant="outlined"
                            color="secondary"
                            style={{ display: "flex", justifyContent: "center" }}
                            variant="outlined"
                            color="secondary"
                        />
                    </div>
                ) : null}
            </Container>
        </>
    );
};

export default withRouter(Feedback);
