import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  postEducation,
  getEducations,
  delEducations,
} from "../../Services/doctor/profile";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { useDispatch } from "react-redux";
import swal from "sweetalert";
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import * as icons from "@material-ui/icons";

const Qualifications = ({ classes }) => {
  const [name, setName] = useState("");
  const [educations, setEducations] = useState([]);
  const [sYear, setSYear] = useState("");
  const [eYear, setEYear] = useState("");
  const dispatch = useDispatch();

  const years = [];
  for (let i = 2030; i >= 1940; i--) {
    years.push(i);
  }
  const handleSYear = (e) => {
    setSYear(e.target.value);
  };
  const handleEYear = (e) => {
    setEYear(e.target.value);
  };
  const handleName = (e) => {
    setName(e.target.value);
  };
  const handleAdd = async () => {
    dispatch(showLoading());
    try {
      let { data } = await postEducation({
        name,
        start: years[sYear],
        end: years[eYear],
      });
      let tmp = [...educations];
      data = data.data;
      tmp.push(data);
      setEducations(tmp);
      swal("success!", "successfully added!", "success");
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401) {
        msg = data.msg;
      }
      swal("wrong!", msg, "error");
    } finally {
      dispatch(hideLoading());
    }
  };
  useEffect(async () => {
    dispatch(showLoading());
    try {
      {
        let { data } = await getEducations();
        data = data.data;
        setEducations(data);
      }
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401) {
        msg = data.msg;
      }
      swal("wrong!", msg, "error");
    } finally {
      dispatch(hideLoading());
    }
  }, []);
  const handleDelete = async (id, name) => {
    let isSure = await swal({
      title: "Are you sure?",
      text: `Are you sure to delete ${name}!`,
      icon: "warning",
      buttons: ["No", "Yes"],
      dangerMode: true,
    });
    if (isSure) {
      try {
        dispatch(showLoading());
        await delEducations(id);
        let tmp = educations.filter((item) => item._id != id);
        setEducations(tmp);
        swal("success!", "successfully deleted!", "success");
      } catch (error) {
        let msg = "some error occured";
        let { status, data } = error.response;
        if (status == 400 || status == 401) {
          msg = data.msg;
        }
        swal("wrong!", msg, "error");
      } finally {
        dispatch(hideLoading());
      }
    }
  };
  return (
    <Container>
      <Row className="justify-content-center">
        <Col xs={12} lg={8}>
          <div className="d-flex justify-content-between">
            <span>Education</span>
            <div>
              <Button
                onClick={handleAdd}
                variant="contained"
                className={classes.addBtn}
              >
                Add +
              </Button>{" "}
            </div>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={12} lg={8}>
          <Row className="mb-4">
            <Col xs={12} lg={5} className="mt-2">
              <TextField
                size="small"
                id="exName"
                value={name}
                onChange={handleName}
                fullWidth
                label="Name"
                variant="outlined"
              />
            </Col>
            <Col xs={12} lg={3} className="mt-2">
              <FormControl
                size="small"
                variant="outlined"
                fullWidth
                className={classes.formControl}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  From
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={sYear}
                  onChange={handleSYear}
                  label="From"
                >
                  {years.map((item, idx) => (
                    <MenuItem key={item} value={idx}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Col>
            <Col xs={1} className="mt-3 mb-1">
              To
            </Col>
            <Col xs={12} lg={3} className="mt-2">
              <FormControl
                size="small"
                variant="outlined"
                fullWidth
                className={classes.formControl}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  End
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={eYear}
                  onChange={handleEYear}
                  label="End"
                >
                  {years.map((item, idx) => (
                    <MenuItem key={item} value={idx}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Col>
          </Row>
          {educations.map((item) => (
            <Row key={item._id}>
              <Col xs={12} lg={5} className="mt-3">
                <TextField
                  size="small"
                  id="exName"
                  value={item.name}
                  fullWidth
                  disabled
                  label="Name"
                  variant="outlined"
                />
              </Col>
              <Col xs={12} lg={3} className="mt-3">
                <TextField
                  size="small"
                  value={item.start}
                  fullWidth
                  disabled
                  label="from"
                  variant="outlined"
                />
              </Col>
              <Col xs={1} className="mt-3 mb-2">
                To
              </Col>
              <Col xs={12} lg={2} className="mt-3">
                <TextField
                  size="small"
                  value={item.end}
                  fullWidth
                  disabled
                  label="end"
                  variant="outlined"
                />
              </Col>
              <Col lg={1} className="mt-3">
                <Button
                  fullWidth
                  size="small"
                  onClick={() => {
                    handleDelete(item._id, item.name);
                  }}
                >
                  <icons.DeleteOutline style={{ color: "red" }} />
                </Button>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default Qualifications;
