import { get, post, put, del } from "../http";
import { root } from "../config.json";

export const getAds = (page, name) => {
  return get(
    `${root}/api/v1/ads?page=${page}&limit=6${
      name.length > 0 ? `&name=${name}` : ""
    }`
  );
};
export const delAds = (id) => {
  return del(`${root}/api/v1/ads?id=${id}`);
};
export const createAds = (body) => {
  return post(`${root}/api/v1/ads`, body);
};
export const uploadImg = (id, body) => {
  return post(`${root}/api/v1/ads/img/${id}`, body);
};
export const updateAds = (body) => {
  return put(`${root}/api/v1/ads`, body);
};
