import React from "react";
import { render } from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store";
import LoadingBar from "react-redux-loading-bar";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";

const THEME = createMuiTheme({
  typography: {
    fontFamily: "Vazir",
    fontSize: 15,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});

render(
  <MuiThemeProvider theme={THEME}>
    <Provider store={store}>
      <LoadingBar
        style={{
          backgroundColor: "#e74c3c",
          position: "fixed",
          height: "2px",
        }}
      />
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </MuiThemeProvider>,
  document.getElementById("root")
);
