import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { login } from "../../Services/doctor/auth";
import { TextField, Button } from "@material-ui/core";
import swal from "sweetalert";
import { withRouter } from "react-router";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { useDispatch } from "react-redux";
const Login = ({ history }) => {
  const [email, setEmail] = useState();
  const dispatch = useDispatch();
  const [pwd, setPwd] = useState();
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handlePwd = (e) => {
    setPwd(e.target.value);
  };

  const handleLogin = async () => {
    dispatch(showLoading());
    try {
      const { data } = await login({ email, pwd });
      localStorage.setItem("jwtoken", data.jwtoken);
      localStorage.setItem("id", data.id)
      if(data.name){
        localStorage.setItem("name", data.name)
      }
      window.location.replace("/");
    } catch (error) {
      const { status, data } = error.response;
      if (status == 400 || status == 401) {
        swal("wrong!", data.msg, "error");
      } else {
        swal("Error!", "please try agean later!", "error");
      }
    } finally {
      dispatch(hideLoading());
    }
  };

  return (
    <Container>
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          height: "350px",
          width: "35%",
          textAlign: "center",
        }}
      >
        <h3 className="text-center">Login</h3>
        <TextField
          fullWidth
          placeholder="Email"
          className="mt-4"
          onChange={handleEmail}
        />
        <TextField
          fullWidth
          placeholder="password"
          type="password"
          className="mt-4"
          onChange={handlePwd}
          onKeyDown={(e) => {
            if (e.key === "Enter") handleLogin();
          }}
        />
        <Button
          variant="contained"
          className="mt-4"
          color="secondary"
          onClick={handleLogin}
        >
          Login
        </Button>
      </div>
    </Container>
  );
};

export default withRouter(Login);
