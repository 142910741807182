import { post, get, del, put } from "../../http";
import { root } from "../../config.json";

export const createAssistant = (body) => {
  return post(`${root}/api/v1/assistant`, body);
};

export const getListOfAssistant = (page) => {
  return get(`${root}/api/v1/assistant?limit=6&page=${page}`);
};

export const deleteAssistant = (id) => {
  return del(`${root}/api/v1/assistant?aid=${id}`);
};

export const updateAssistant = (id, body) => {
  return put(`${root}/api/v1/assistant/${id}`, body);
};
