import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { ViewSinglePatient } from "../../Services/admin/patients";
import {
  TextField,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { useDispatch } from "react-redux";
import swal from "sweetalert";
import { root } from "../../Services/config.json";



const ViewPatient = ({ history, location }) => {
  const { id } = location.state;
  const [rows, setRows] = useState([]);
  const [user, setUser] = useState(null)
  const [load, setLoad] = useState(false);
  const [loadReport, setLoadReport] = useState(false);
  const [url, setUrl] = useState("");
  const [flag, setFlag] = useState(false)
  const [ALen, setALen] = useState(0)
  const [AState, setAState] = useState(true);
  const [ReportsAndDescription, setReportsAndDescription] = useState(null)
  const dispatch = useDispatch();


  const initRows = async (id) => {

    if (id > 0 && id != "undefined") {
      let userData = await ViewSinglePatient(id)
      setUser(userData.data.user);
      setUrl(`${root}${userData.data.user.photo}`);
      setReportsAndDescription(userData.data.ReportsAndDescription)
      setFlag(true)
      setALen(userData.data.ReportsAndDescription.length)
    }

  };
  const handleImgLoad = () => {
    if (!load) setLoad(true);
  };
  const handleRepLoad = () => {
    if (!loadReport) setLoadReport(true)
  }
  useEffect(async () => {
    dispatch(showLoading());
    try {
      await initRows(id);
      setAState(false)
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401) {
        msg = data.msg;
      }
      swal("wrong!", msg, "error");
    } finally {
      dispatch(hideLoading());
    }
  }, [id]);
  return (
    <>
      {flag ? <Container>
        <Row className="justify-content-center"  >
          <Col lg={4} xs={12}>
            <Typography variant="h6" component="h2">
              User Profile Detail
            </Typography>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg={8} xs={12} md={6} className="mt-3">
            <Row className="justify-content-center mt-4">
              <Col lg={6} xs={6} md="3" className="mt-2">
                <TextField
                  size="small"
                  fullWidth
                  label="Paitent Name"
                  inputProps={
                    { readOnly: true, }}
                  value={user.name}
                  variant="outlined"
                />
              </Col>
              <Col lg={6} xs={6} md="3" className="mt-2">
                <TextField
                  size="small"
                  fullWidth
                  label="Paitent ID"
                  inputProps={
                    { readOnly: true, }}
                  value={user.patientID}
                  variant="outlined"
                />
              </Col>
            </Row>
            <Row className="justify-content-center mt-4">
              <Col lg={6} xs={6} md="3" className="mt-2">
                <TextField
                  size="small"
                  fullWidth
                  label="Age"
                  inputProps={
                    { readOnly: true, }}
                  value={user.age}
                  variant="outlined"
                />
              </Col>
              <Col lg={6} xs={6} md="3" className="mt-2">
                <TextField
                  size="small"
                  fullWidth
                  label="Create date"
                  inputProps={
                    { readOnly: true, }}
                  value={user.createAt}
                  variant="outlined"
                />
              </Col>
            </Row>
            <Row className="justify-content-center mt-4">
              <Col lg={6} xs={6} md="3" className="mt-2">
                <TextField
                  size="small"
                  fullWidth
                  label="Phone"
                  inputProps={
                    { readOnly: true, }}
                  value={user.phone}
                  variant="outlined"
                />
              </Col>
              <Col lg={6} xs={6} md="3" className="mt-2">
                <TextField
                  size="small"
                  fullWidth
                  label="Number of Appointment"
                  inputProps={
                    { readOnly: true, }}
                  value={ALen}
                  variant="outlined"
                />
              </Col>
            </Row>
          </Col>
          <Col lg={2} xs={12} className="mt-2">
            {load ? null : (
              <div
                style={{
                  width: 250,
                  backgroundColor: "white",
                  height: 250,
                }}
                className="text-center"
              >
                <CircularProgress style={{ marginTop: "100px" }} />
              </div>

            )}
            <img
              src={url}
              id="profile"
              onLoad={handleImgLoad}
              style={{
                width: "250px",
                height: "250px",
                objectFit: "contain",
                display: load ? "block" : "none",
              }}
            />
            <div className="mt-2 ml-5" style={{
              width: "250px"
            }}>
              <h4>Profile Picture</h4>
            </div>
          </Col>
        </Row>
        {!AState ? ReportsAndDescription.map((obj, idx) => {
          return <Container><Row className="justify-content-center mt-3"  >
            <Col lg={4} xs={6}>
              <Typography variant="h6" component="h2">
                Appointment No {idx + 1}
              </Typography>
            </Col>
          </Row>
            <Row className="justify-content-center">
              <Col lg={8} xs={12} md={6} className="mt-2">
                <Row className="justify-content-center mt-2">
                  <Col lg={6} xs={6} md="3" className="mt-2">
                    <TextField
                      size="small"
                      fullWidth
                      label="Doctor Name"
                      inputProps={
                        { readOnly: true, }}
                      value={obj.name}
                      variant="outlined"
                    />
                  </Col>
                  <Col lg={6} xs={6} md="3" className="mt-2">
                    <TextField
                      size="small"
                      fullWidth
                      label="Doctor Email"
                      inputProps={
                        { readOnly: true, }}
                      value={obj.doctor[0].email}
                      variant="outlined"
                    />
                  </Col>
                </Row>
                <Row className="justify-content-center mt-2">
                  <Col lg={6} xs={6} md="3" className="mt-2">
                    <TextField
                      size="small"
                      fullWidth
                      label="Phone"
                      inputProps={
                        { readOnly: true, }}
                      value={obj.phone}
                      variant="outlined"
                    />
                  </Col>
                  <Col lg={6} xs={6} md="3" className="mt-2">
                    <TextField
                      size="small"
                      fullWidth
                      label="speciality"
                      inputProps={
                        { readOnly: true, }}
                      value={obj.doctor[0].speciality}
                      variant="outlined"
                    />
                  </Col>
                </Row>
                <Row className="justify-content-center mt-2">
                  <Col lg={4} xs={6} md="3" className="mt-2">
                    <TextField
                      size="small"
                      fullWidth
                      label="Title"
                      inputProps={
                        { readOnly: true, }}
                      value={obj.title}
                      variant="outlined"
                    />
                  </Col>
                  <Col lg={8} xs={6} md="3" className="mt-2">
                    <TextField
                      size="small"
                      fullWidth
                      label="Address"
                      inputProps={
                        { readOnly: true, }}
                      value={obj.doctor[0].address}
                      variant="outlined"
                    />
                  </Col>
                </Row>
                <Row className="justify-content-center mt-2">
                  <Col lg={12} xs={6} md="3" className="mt-2">
                    <TextField
                      size="small"
                      fullWidth
                      label="Descripton"
                      inputProps={
                        { readOnly: true, }}
                      value={obj.description}
                      variant="outlined"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            {obj.documents.map((val, idx) => {
              return <Row key={idx} className="justify-content-center mt-2">
                <Col lg={2} xs={12} className="mt-2">
                {loadReport ? null : (
                  <div
                    style={{
                      width: 250,
                      backgroundColor: "white",
                      height: 250,
                    }}
                    className="text-center"
                  >
                    <CircularProgress style={{ marginTop: "100px" }} />
                  </div>

                )}
                <img
                  src={`${root}${val}`}
                  id="profile"
                  onLoad={handleRepLoad}
                  style={{
                    width: "250px",
                    height: "250px",
                    objectFit: "contain",
                    display: loadReport ? "block" : "none",
                  }}
                />
                <div className="mt-2 text-center" style={{
                  width: "250px"
                }}>
                  <h4>Report</h4>
                </div>
              </Col></Row>
            })
            }
          </Container>
        }) : (<Container> <Row className="justify-content-center mt-3"  >
          <Col lg={4} xs={6}>
            <Typography variant="h6" component="h2">
              Appointment Not Found!
            </Typography>
          </Col>
        </Row> </Container>)
        }

      </Container> : <Container></Container>}

    </>
  );
};

export default withRouter(ViewPatient);
