import { post, get,del } from "../http";
import { root } from "../config.json";


export const getCitiesForAdmin = (page) => {
    return get(`${root}/api/v1/city/AllCities?limit=6&page=${page}`);
  };
  

  export const delCity = (cityId) => {
    return del(`${root}/api/v1/city/${cityId}`);
  };

  export const CreateCity = (body) => {
    return post(`${root}/api/v1/city`, body);
  };
  