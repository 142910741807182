import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import * as icons from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import Tabel from "./Tabel";
import { getUsers } from "../../Services/users";
import { Pagination } from "@material-ui/lab";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import swal from "sweetalert";

const useStyles = makeStyles((theme) => ({
  Btn: {
    borderRadius: 0,
    color: "#fff",
    backgroundColor: "#6DBCDB",
    "&:hover": {
      backgroundColor: "#0097e6",
    },
  },
}));

const Categories = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(1);

  const initRows = async (page) => {
    dispatch(showLoading());
    let { data } = await getUsers(page, search);
    dispatch(hideLoading());
    setCount(data.count);
    setRows(data.data);
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  const handleSearchEnter = async () => {
    try {
      if (page != 1) setPage(1);
      else initRows(1);
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401) {
        msg = data.msg;
      }
      swal("wrong!", msg, "error");
    } finally {
    }
  };

  const handlePage = async (e, value) => {
    setPage(value);
  };
  useEffect(async () => {
    try {
      await initRows(page);
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401) {
        msg = data.msg;
      }
      swal("wrong!", msg, "error");
    } finally {
    }
  }, [page]);

  return (
    <>
      <Container>
        <Row className="justify-content-between">
          <Col md="3" className="mt-2"></Col>
          <Col md="4" className="mt-2">
            <TextField
              fullWidth
              size="small"
              id="search"
              label="Search by Name"
              variant="outlined"
              value={search}
              onChange={handleSearch}
              onKeyDown={(e) => {
                if (e.key === "Enter") handleSearchEnter();
              }}
              className={classes.textField}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton onClick={handleSearchEnter}>
                      <icons.Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="mt-2">
            <Tabel rows={rows} />
          </Col>
        </Row>
        {count > 8 ? (
          <div className="mt-3">
            <Pagination
              count={parseInt((count + 7) / 8)}
              onChange={handlePage}
              page={page}
              variant="outlined"
              color="secondary"
              style={{ display: "flex", justifyContent: "center" }}
              variant="outlined"
              color="secondary"
            />
          </div>
        ) : null}
      </Container>
    </>
  );
};

export default withRouter(Categories);
