import React from "react";
import { makeStyles, Card, CardContent } from "@material-ui/core";

const CardAppointment = ({ text, color, count }) => {
  const useStyles = makeStyles({
    root: {
      backgroundColor: color,
      color: "#fff",
    },
  });
  const classes = useStyles();
  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <span className="d-block text-center p-3">{text}</span>
        <h1 style={{ fontWeight: "bold" }}>{count}</h1>
      </CardContent>
    </Card>
  );
};

export default CardAppointment;
