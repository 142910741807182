import { put, del, post, get } from "../../http";
import { root } from "../../config.json";

export const getDoctors = (id) => {
    return get(`${root}/api/v1/hospital/${id}`);
  };
  export const getDoctorsById = (id) => {
    return get(`${root}/api/v1/doctor/${id}`);
  };
  export const UpdateDoctorsById = (did,body) => {
    return put(`${root}/api/v1/doctor/${did}`,body);
  };
  export const uploadProfile = (id,data) => {
    return post(`${root}/api/v1/doctor/img/${id}`, data, {
      headers: { "content-type": "multipart/form-data" },
    });
  };
  export const DeleteDoctorsById = (did) => {
    return put(`${root}/api/v1/hospital/DelDoc/${did}`);
  };
  


  