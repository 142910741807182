import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { useDispatch } from "react-redux";
import swal from "sweetalert";
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import * as icons from "@material-ui/icons";
import { delAward, getAwards, postAward } from "../../Services/doctor/profile";
const Awards = ({ classes }) => {
  const years = [];
  for (let i = 2020; i >= 1940; i--) {
    years.push(i);
  }
  const [year, setYear] = useState("");
  const [name, setName] = useState("");
  const [awards, setAwards] = useState([]);
  const dispatch = useDispatch();
  const handleYear = (e) => {
    setYear(e.target.value);
  };

  const handleAdd = async () => {
    dispatch(showLoading());
    try {
      let { data } = await postAward({ name, year: years[year] });
      data = data.data;
      let tmp = [...awards];
      tmp.push(data);
      setAwards(tmp);
      swal("success!", "successfully added!", "success");
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401) {
        msg = data.msg;
      }
      swal("wrong!", msg, "error");
    } finally {
      dispatch(hideLoading());
    }
  };
  const handleName = (e) => {
    setName(e.target.value);
  };
  const handleDelete = async (id, name) => {
    let isSure = await swal({
      title: "Are you sure?",
      text: `Are you sure to delete ${name}!`,
      icon: "warning",
      buttons: ["No", "Yes"],
      dangerMode: true,
    });
    if (isSure) {
      try {
        dispatch(showLoading());
        await delAward(id);
        let tmp = awards.filter((item) => item._id != id);
        setAwards(tmp);
        swal("success!", "successfully deleted!", "success");
      } catch (error) {
        let msg = "some error occured";
        let { status, data } = error.response;
        if (status == 400 || status == 401) {
          msg = data.msg;
        }
        swal("wrong!", msg, "error");
      } finally {
        dispatch(hideLoading());
      }
    }
  };

  useEffect(async () => {
    dispatch(showLoading());
    try {
      let { data } = await getAwards();
      data = data.data;
      setAwards(data);
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401) {
        msg = data.msg;
      }
      swal("wrong!", msg, "error");
    } finally {
      dispatch(hideLoading());
    }
  }, []);

  return (
    <Container>
      <Row className="justify-content-center">
        <Col xs={12} lg={8}>
          <div className="d-flex justify-content-between">
            <span>Award</span>
            <div>
              <Button
                onClick={handleAdd}
                variant="contained"
                className={classes.addBtn}
              >
                Add +
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={12} lg={8}>
          <Row className="mb-4">
            <Col xs={12} lg={9} className="mt-2">
              <TextField
                size="small"
                id="langName"
                value={name}
                onChange={handleName}
                fullWidth
                label="Award name"
                variant="outlined"
              />
            </Col>
            <Col xs={12} lg={3} className="mt-2">
              <FormControl
                size="small"
                variant="outlined"
                fullWidth
                className={classes.formControl}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Year
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={year}
                  onChange={handleYear}
                  label="year"
                >
                  {years.map((item, idx) => (
                    <MenuItem key={item} value={idx}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Col>
          </Row>
          {awards.map((item) => (
            <Row key={item._id}>
              <Col xs={12} lg={9} className="mt-3">
                <TextField
                  size="small"
                  value={item.name}
                  fullWidth
                  disabled
                  label="Award name"
                  variant="outlined"
                />
              </Col>
              <Col xs={12} lg={2} className="mt-3">
                <TextField
                  size="small"
                  value={item.year}
                  fullWidth
                  disabled
                  label="year"
                  variant="outlined"
                />
              </Col>
              <Col lg={1} className="mt-3">
                <Button
                  onClick={() => {
                    handleDelete(item._id, item.name);
                  }}
                  fullWidth
                >
                  <icons.DeleteOutline style={{ color: "red" }} />
                </Button>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default Awards;
