import { get, post, put, del } from "../http";
import { root } from "../config.json";

export const getPharmacies = (page, name) => {
  return get(
    `${root}/api/v1/pharmacy/777?page=${page}${
      name.length > 0 ? `&name=${name}` : ""
    }`
  );
};

export const searchPharmacy = (ID) => {
  return get(`${root}/api/v1/pharmacy/searchByID/${ID}`);
};

export const deletePharmacy = (id) => {
  return del(`${root}/api/v1/pharmacy/${id}`);
};
export const createPharmacy = (body) => {
  return post(`${root}/api/v1/pharmacy`, body);
};
export const uploadImg = (id, body) => {
  return post(`${root}/api/v1/pharmacy/img/${id}`, body);
};
export const updatePharmacy = (body) => {
  return put(`${root}/api/v1/pharmacy`, body);
};

export const getCheckUps = (id) => {
  return get(`${root}/api/v1/pharmacy/checkUp/${id}`);
};
export const upload_image_no_id = (body) => {
  return post(`${root}/api/v1/pharmacy/img`, body);
};

export const switchToPharmacy = (pid) => {
  return get(`${root}/api/v1/admin/pharmacy/${pid}`);
};

export const getPharmacyInfo = (pid) => {
  return get(`${root}/api/v1/pharmacy/info/${pid}`);
};