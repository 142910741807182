import React, { useState, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { getPatientInfo } from "../../Services/doctor/Appointment";
import { postLabReport, sendImage } from "../../Services/doctor/Laboratory";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { useDispatch } from "react-redux";
import swal from "sweetalert";

const AddAppointment = ({ history }) => {
  const [patientId, setPatientId] = useState("");
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [phone, setPhone] = useState("");
  const [images, setImages] = useState([]);
  const imgs = useRef(null);
  const dispatch = useDispatch();
  const handleFind = async () => {
    dispatch(showLoading());
    try {
      const { data } = await getPatientInfo(patientId);
      setName(data.name);
      setPhone(data.phone);
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401) {
        msg = data.msg;
      }
      swal("wrong!", msg, "error");
    } finally {
      dispatch(hideLoading());
    }
  };
  const handlePaitentId = (e) => {
    setPatientId(e.target.value);
  };
  const handleName = (e) => {
    setName(e.target.value);
  };
  const handlePhone = (e) => {
    setPhone(e.target.value);
  };

  const handleDesc = (e) => {
    setDesc(e.target.value);
  };
  const handleTitle = (e) => {
    setTitle(e.target.value);
  };
  const recReadFile = (idx, tmp) => {
    if (imgs.current.files.length == idx) {
      setImages(tmp);
      return;
    }
    let file = imgs.current.files[idx];
    let read = new FileReader();
    read.readAsDataURL(file);
    read.onload = (e) => {
      tmp.push(e.target.result);
    };
    read.onloadend = () => {
      recReadFile(idx + 1, tmp);
    };
  };
  const handleOnchangeFile = () => {
    let tmp = [];
    recReadFile(0, tmp);
  };
  const handleSave = async () => {
    dispatch(showLoading());
    try {
      const { data } = await postLabReport({
        patientId,
        name,
        phone,
        title,
        description: desc,
      });
      let tmp = [...imgs.current.files];
      if (tmp.length > 0) await sendImage(tmp, data._id);
      history.goBack();
      swal("success!", "successfully added!", "success");
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401) {
        msg = data.msg;
      }
      swal("wrong!", msg, "error");
    } finally {
      dispatch(hideLoading());
    }
  };
  return (
    <>
      <Container>
        <Row className="justify-content-center mb-5">
          <Col md="3" className="mt-2">
            <TextField
              size="small"
              fullWidth
              id="pid"
              onChange={handlePaitentId}
              value={patientId}
              variant="outlined"
              label="Patient id"
            />
          </Col>
          <Col md="3" className="mt-2 text-right">
            <Button variant="contained" color="secondary" onClick={handleFind}>
              Find
            </Button>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="6">Patient</Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="4" className="mt-2">
            <TextField
              size="small"
              fullWidth
              value={name}
              onChange={handleName}
              id="fname"
              label="full name"
              variant="outlined"
            />
          </Col>
          <Col md="2" className="mt-2">
            <TextField
              size="small"
              fullWidth
              value={phone}
              onChange={handlePhone}
              inputProps={{ maxLength: 10 }}
              id="phone"
              label="Phone"
              variant="outlined"
            />
          </Col>
        </Row>

        <Row className="justify-content-center mt-4">
          <Col md="6">Title</Col>
        </Row>
        <Row className="justify-content-center mt-2">
          <Col md="6">
            <TextField
              size="small"
              value={title}
              fullWidth
              onChange={handleTitle}
              id="title"
              label="title"
              variant="outlined"
            />
          </Col>
        </Row>

        <Row className="justify-content-center mt-4">
          <Col md="6">Description</Col>
        </Row>
        <Row className="justify-content-center mt-2">
          <Col md="6">
            <TextField
              size="small"
              fullWidth
              value={desc}
              onChange={handleDesc}
              id="fname"
              placeholder="write here..."
              variant="outlined"
              multiline
              rows={5}
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="6">
            <Row>
              {images.map((url, idx) => (
                <Col key={idx} md="6" className="mt-3">
                  <img
                    src={url}
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-center mb-5">
          <Col md="3" className="mt-3 text-left">
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={() => {
                imgs.current.click();
              }}
            >
              Select images
            </Button>
          </Col>
          <Col md="3" className="mt-3"></Col>
        </Row>
        <input
          multiple
          type="file"
          accept="image/png, image/jpeg"
          onChange={handleOnchangeFile}
          style={{ display: "none" }}
          ref={imgs}
        />
        <Row className="justify-content-center mt-2">
          <Col md="6">
            <Row className="justify-content-end">
              <Col md="2">
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={handleSave}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddAppointment;
