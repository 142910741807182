import React, { useEffect, useState } from "react";
import { makeStyles, MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { Container, Row, Col } from "react-bootstrap";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import { withRouter } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import PatientViewTable from './PatientViewTable';
import { Pagination } from "@material-ui/lab";
import { useDispatch } from "react-redux";
import { ViewSinglePatient } from "../../Services/admin/patients";
import { root } from "../../Services/config.json";
import swal from "sweetalert";
import { showLoading, hideLoading } from "react-redux-loading-bar";


const THEME = createMuiTheme({
    typography: {
        "fontFamily": `"sans-serif", "Helvetica", "Arial"`,
        "fontSize": 14,
        "fontWeightLight": 300,
        "fontWeightRegular": 400,
        "fontWeightMedium": 500
    }
});


const PatientView = ({ history, location }) => {
    const { pid } = location.state;
    console.log()
    let length;
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);

    const [rows, setRows] = useState([]);
    const [user, setUser] = useState(null)
    const [load, setLoad] = useState(false);
    const [loadReport, setLoadReport] = useState(false);
    const [url, setUrl] = useState("");
    const [flag, setFlag] = useState(false)
    const [ALen, setALen] = useState(0)
    const [lastApp, setLastApp] = useState(null)
    const [AState, setAState] = useState(true);
    const [ReportsAndDescription, setReportsAndDescription] = useState(null)
    const dispatch = useDispatch();


    const initRows = async (pid) => {

        if (pid > 0 && pid != "undefined") {
            let userData = await ViewSinglePatient(pid)

            setUser(userData.data.user);
            setRows(userData.data)
            setUrl(`${root}${userData.data.user.photo}`);
            setReportsAndDescription(userData.data.ReportsAndDescription)
            setALen(userData.data.ReportsAndDescription.length)
            length = userData.data.ReportsAndDescription.length;
            setCount(length)
            setLastApp(userData.data.ReportsAndDescription[length - 1])
            setFlag(true)

        }

    };

    const handleImgLoad = () => {
        if (!load) setLoad(true);
    };
    const handleRepLoad = () => {
        if (!loadReport) setLoadReport(true)
    }
    useEffect(async () => {
        dispatch(showLoading());
        try {
            await initRows(pid);
            setAState(false)
        } catch (error) {
            let msg = "some error occured";
            let { status, data } = error.response;
            if (status == 400 || status == 401) {
                msg = data.msg;
            }
            swal("wrong!", msg, "error");
        } finally {
            dispatch(hideLoading());
        }
    }, [pid]);




    const handlePage = async (e, value) => {
        setPage(value);
    };
    return (
        <>
            <MuiThemeProvider theme={THEME}>


                <div style={{ display: "flex", flexDirection: "row" }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            '& > :not(style)': {
                                m: 0,
                                width: 350,
                                height: 420,
                                borderRadius: 3,
                            },
                        }}
                    >
                        <Paper elevation={3}>
                            <Row className="justify-content-center mt-4">
                                <Avatar
                                    alt={user ? user.name : "N/A"}
                                    src={url ? url : ""}
                                    sx={{ width: 100, height: 100 }}
                                />
                            </Row>
                            <Row className="justify-content-center mt-2">
                                <Typography variant="h6" color={'#0096FF'} fontFamily={'serif'}>
                                    {user ? user.name : "N/A"}
                                </Typography>
                            </Row>
                            <Row className="justify-content-center mt-0">
                                <Typography variant="h8" color={'#000080'} fontWeight="bold" fontFamily={'sans-serif'}>
                                    {user ? user.phone : "N/A"}
                                </Typography>
                            </Row>
                            <Row className="justify-content-left mt-2 ml-2 mb-2">
                                <Typography align="left" variant="h8" color={'#0096FF'} fontWeight="bold" fontFamily={'sans-serif'}>
                                    Details
                                </Typography>
                            </Row>
                            <Divider />
                            <Row className="justify-content-center mt-3">
                                <Col className=" ml-2" >
                                    <Typography align="left" variant="h8" color={'#808080'} fontWeight="bold" fontFamily={'sans-serif'}>
                                        Patient ID :
                                    </Typography>
                                </Col>
                                <Col className=" ml-2" >
                                    <Typography align="left" variant="h8" color={'#0096FF'} fontWeight="bold" fontFamily={'sans-serif'}>
                                        {user ? user.patientID : "N/A"}
                                    </Typography>
                                </Col>
                            </Row>
                            <Row className="justify-content-center mt-3">
                                <Col className=" ml-2" >
                                    <Typography align="left" variant="h8" color={'#808080'} fontWeight="bold" fontFamily={'sans-serif'}>
                                        Age :
                                    </Typography>
                                </Col>
                                <Col className="ml-2" >
                                    <Typography align="left" variant="h8" color={'#0096FF'} fontWeight="bold" fontFamily={'sans-serif'}>
                                        {user ? user.age : "N/A"}
                                    </Typography>
                                </Col>
                            </Row>
                            <Row className="justify-content-center mt-3">
                                <Col className="ml-2" >
                                    <Typography align="left" variant="h8" color={'#808080'} fontWeight="bold" fontFamily={'sans-serif'}>
                                        Create Date :
                                    </Typography>
                                </Col>
                                <Col className="ml-2" >
                                    <Typography align="left" variant="h8" color={'#0096FF'} fontWeight="bold" fontFamily={'sans-serif'}>
                                        {user ? user.createAt : "N/A"}
                                    </Typography>
                                </Col>
                            </Row>
                            <Row className="justify-content-center mt-3">
                                <Col className="ml-2" >
                                    <Typography align="left" variant="h8" color={'#808080'} fontWeight="bold" fontFamily={'sans-serif'}>
                                        Appointment :
                                    </Typography>
                                </Col>
                                <Col className="ml-2" >
                                    <Typography align="left" variant="h8" color={'#0096FF'} fontWeight="bold" fontFamily={'sans-serif'}>
                                        {ALen ? ALen : 0}
                                    </Typography>
                                </Col>
                            </Row>
                        </Paper>

                    </Box>
                    <div style={{ display: "inline-block", border: '1px solid rgba(0, 0, 0, 0.05)', width: '90%', margin: 10, padding: 5, height: 170, backgroundColor: '#b0e3e2', borderRadius: 10 }}>

                        <Row style={{ display: "flex", flexDirection: 'wrap', width: '100%', height: 65, margin: 5 }}>
                            <Col xs={2} className="mt-2">
                                <Typography align="left" variant="h6" color={'#0096FF'}>
                                    Last Seen By :
                                </Typography>
                            </Col>
                            <Col xs={4} className="mt-2">
                                <Typography align="left" variant="h6" color={'#000080'}>
                                    {lastApp ? lastApp.doctor[0].fullname : "N/A"}
                                </Typography>
                            </Col>
                            <Col xs={2} className="mt-2">
                                <Typography align="left" variant="h6" color={'#0096FF'}>
                                    Last Appointment :
                                </Typography>
                            </Col>
                            <Col xs={4} className="mt-2">
                                <Typography align="left" variant="h6" color={'#000080'}>
                                    {"N/A"}
                                </Typography>
                            </Col>
                        </Row>
                        <Row style={{ width: '100%', height: 65, margin: 5 }}>
                            <Col xs={2} className="mt-2">
                                <Typography align="left" variant="h6" color={'#0096FF'}>
                                    Complaint :
                                </Typography>
                            </Col>
                            <Col xs={2} className="mt-2">
                                <Typography align="left" variant="h6" color={'#000080'}>
                                    {lastApp ? lastApp.title : "N/A"}
                                </Typography>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xs={4} className="mt-3">
                                <Typography align="left" variant="h8" color={'#000080'}>
                                    Appointment History:
                                </Typography>
                            </Col>
                        </Row>
                        {flag ? <Row className="">
                            <Col xs={12} className="mt-2">
                                <PatientViewTable rows={rows}  />
                            </Col>
                        </Row> : ""}

                        {count > 8 ? (
                            <div className="mt-3">
                                <Pagination
                                    count={parseInt((count + 5) / 6)}
                                    onChange={handlePage}
                                    page={page}
                                    variant="outlined"
                                    color="secondary"
                                    style={{ display: "flex", justifyContent: "center" }}
                                />
                            </div>
                        ) : null}

                    </div>
                </div>


            </MuiThemeProvider>
        </>
    )
}


export default withRouter(PatientView);