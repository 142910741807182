import { get, put, del } from "../../http";
import { root } from "../../config.json";

export const getListOFAllAppointment = (page) => {
  return get(`${root}/api/v1/appointment/appointment?page=${page}&limit=6`);
};

export const deleteAppointment = (id) => {
    return del(`${root}/api/v1/appointment/${id}`);
  };

  
  export const ViewDoctor = (id) => {
    return get(`${root}/api/v1/appointment/doctor/${id}`);
  };

  export const getListOfDoctor = () => {
    return get(`${root}/api/v1/appointment/doctors`);
  };
export const updateAppointment = (id,body) => {
  return put(`${root}/api/v1/appointment/${id}`,body);
};

