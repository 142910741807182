import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import * as icons from "@material-ui/icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { createAdmin } from "../../Services/admin/admin";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { useDispatch } from "react-redux";
import {
  TextField,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";

const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};
const useStyles = makeStyles((theme) => ({
  menuPaper: {
    maxHeight: 200,
  },
}));
const AMAdd = () => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");

  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleSave = async () => {
    if (validateEmail(email)) {
      if (email.length > 0 && values.password.length > 0) {
        try {
          dispatch(showLoading());
          await createAdmin({
            email,
            password: values.password,
          });
          swal("success!", "successfully admin created!", "success");
        } catch (error) {
          let msg = "some error occured";
          let { status, data } = error.response;
          if (status == 400 || status == 401) {
            msg = data.msg;
          }

          swal("wrong!", msg, "error");
        } finally {
          dispatch(hideLoading());
        }
      } else {
        swal("wrong!", "please fill all field!", "error");
      }
    } else {
      swal("wrong!", "Email is invalid.", "error");
    }
  };

  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col md="3" className="mt-2">
            <TextField
              size="small"
              fullWidth
              label="Email Address"
              value={email}
              onChange={handleEmail}
              variant="outlined"
            />
          </Col>
          <Col md="3" className="mt-2">
            <FormControl fullWidth size="small" variant="outlined">
              <OutlinedInput
                placeholder="Password"
                id="outlined-adornment-password"
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChange("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? (
                        <icons.Visibility />
                      ) : (
                        <icons.VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Col>
        </Row>
        <Row className="justify-content-center mt-4">
          <Col md="6">
            <Row className="justify-content-end">
              <Col md="2">
                <Button
                  fullWidth
                  onClick={handleSave}
                  variant="contained"
                  color="secondary"
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AMAdd;
