import { post, get, del } from "../../http";
import { root } from "../../config.json";

export const addTime = (body) => {
  return post(`${root}/api/v1/schedule`, body);
};

export const getSchedule = () => {
  return get(`${root}/api/v1/schedule`);
};

export const delTime = (time, day) => {
  return del(`${root}/api/v1/schedule?time=${time}&dayOfWeek=${day}`);
};
