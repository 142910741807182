import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import * as icons from "@material-ui/icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { searchDoctor } from "../../../Services/admin/Hospital";
import {
  getHospitalInfo,
  updateHospital
} from "../../../Services/admin/Hospital";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { useDispatch } from "react-redux";
import StarRatings from 'react-star-ratings';
import { root } from "../../../Services/config.json";

import {
  TextField,
  Typography,
  CircularProgress,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";

const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};
const useStyles = makeStyles((theme) => ({
  menuPaper: {
    maxHeight: 200,
  },
}));
const Add = ({ history }) => {
  console.log(localStorage.getItem("id"));
  let id = localStorage.getItem("id")
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [doctors, setDoctors] = useState([]);
  const [load, setLoad] = useState(false);
  const [ExpYear, setExpYear] = useState([]);


  const handleImgLoad = () => {
    if (!load) setLoad(true);
  };
  const handleDocDel = (id) => {
    let tmp = doctors.filter((obj) => obj._id != id);
    setDoctors(tmp);
  };
  const handleDoctorFind = async () => {
    try {
      let { data } = await searchDoctor(email);
      console.log(data);
      let tmp = [...doctors];
      tmp.push({ fullname: data.fullname, _id: data._id, photo: data.photo, Edu: data.Edu, Exp: data.Exp, stars: data.stars, speciality: data.speciality });
      setDoctors(tmp);
    } catch (error) {
      swal("wrong!", "Doctor not find!", "error");
    }
  };
  useEffect(async () => {
    try {
      dispatch(showLoading());
      let { data } = await getHospitalInfo(id);
      setDoctors(data.docts);
      let All_years = [];
      await data.docts.map((obj, idx) => {
        let years = 0;
        for (let i = 0; i < obj.Exp.length; i++) {
          years = years + obj.Exp[i].year;
        }
        All_years.push(years)
      })
      setExpYear(All_years)

    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401) {
        msg = data.msg;
      }
      swal("wrong!", msg, "error");
    } finally {
      dispatch(hideLoading());
    }
  }, []);


  const handleSave = async () => {
    try {
      dispatch(showLoading());

      let docs = [];
      doctors.map((item) => {
        docs.push(item._id)

      });

      await updateHospital({
        AddDoctors: {
          doctors: docs
        } ,
        id: id
      });
      swal("success!", "successfully Doctor Added into Hospital!", "success");
      history.goBack();
    } catch (error) {
      let msg = "some error occured";
      let { status, data} = error.response;
      if (status == 400 || status == 401) {
        msg = data.msg;
      }
      swal("wrong!", msg, "error");
    } finally {
      dispatch(hideLoading());
    }
  };

  return (
    <>
      <Container>
        <Row className="justify-content-center mt-2">
          <Col md="5" className="mt-2">
            <TextField
              size="small"
              fullWidth
              value={email}
              label="Email of doctor"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              variant="outlined"
            />
          </Col>
          <Col md="1" className="mt-2">
            <Button
              variant="contained"
              color="secondary"
              onClick={handleDoctorFind}
            >
              Find
            </Button>
          </Col>
        </Row>
        {doctors.map((obj, idx) => (
          <Row key={idx} lg={8} className="justify-content-center ">
            <Col lg={12} >
              <Row className="justify-content-center mb-2 mt-3">
                <Col lg={6}>
                  <Row className="mb-2 mt-3" >
                    <Typography variant="h6">Name: {obj.fullname} </Typography></Row>
                  <Row className="mb-2 mt-3">
                    <Typography variant="h6"> Speciality : {obj.speciality} </Typography></Row>
                  <Row className="mb-2 mt-3">
                    <Typography variant="h6"> Education : {obj.Edu.length > 0 ? obj.Edu[0].name : "Not Defined"} </Typography></Row>
                  <Row className="mb-2 mt-3">
                    <Typography variant="h6">Experience : {obj.Exp.length > 0 ? ExpYear[idx] + "    years" : "Not Defined"}</Typography></Row>
                  <Row className="mt-2">
                    <StarRatings
                      rating={obj.stars}
                      starRatedColor="blue"
                      changeRating={null}
                      numberOfStars={5}
                      name='rating'
                    />
                  </Row>
                </Col>
                <Col lg={4}>
                  {load ? null : (
                    <div
                      style={{
                        width: 250,
                        backgroundColor: "white",
                        height: 250,
                      }}
                      className="text-center"
                    >
                      <CircularProgress style={{ marginTop: "100px" }} />
                    </div>
                  )}
                  <img
                    src={`${root}${obj.photo}`}
                    id="profile"
                    onLoad={handleImgLoad}
                    style={{
                      width: "250px",
                      height: "250px",
                      objectFit: "contain",
                      display: load ? "block" : "none",
                    }}
                  />
                </Col>
              </Row>
              <Row className="justify-content-end mb-2 mt-3" lg={6} >
                <Col lg={3} >
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      handleDocDel(obj._id);
                    }}
                  >
                    Delete
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        ))}
        <Row className="justify-content-center pt-5 pr-2">
          <Col md="2" >
            <Button
              fullWidth
              onClick={handleSave}
              variant="contained"
              color="secondary"
            >
              Save
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Add;
