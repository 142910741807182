import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Pagination } from "@material-ui/lab";
import {
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import * as icons from "@material-ui/icons";
import PTabel from "./PTabel";
import { withRouter } from "react-router-dom";
import { getAllPatient, searchPatient } from "../../Services/doctor/Patient";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { useDispatch } from "react-redux";
import swal from "sweetalert";

const useStyles = makeStyles((theme) => ({
  textField: {
    [`& fieldset`]: {
      borderRadius: 0,
    },
  },
  searchBtn: {
    borderRadius: 0,
    color: "#fff",
    backgroundColor: "#6DBCDB",
    "&:hover": {
      backgroundColor: "#0097e6",
    },
  },
}));

const Patients = ({ history }) => {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const initRows = async (page) => {
    let { data } =
      search.length > 0
        ? await searchPatient(page, search)
        : await getAllPatient(page);

    setCount(data.count);
    data = data.data;
    console.log(data)
    let tmp = [];
    for (let i = 0; i < data.length; i++) {
      let x = data[i];
      let date = new Date(x.createAt);
      date = date.toLocaleString().split(",")[0];
      tmp.push({
        id: x._id.pid,
        name: x._id.name,
        count: x.numberOfAppointment,
      });
    }
    setRows(tmp);
  };
  useEffect(async () => {
    dispatch(showLoading());
    try {
      await initRows(page);
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401) {
        msg = data.msg;
      }
      swal("wrong!", msg, "error");
    } finally {
      dispatch(hideLoading());
    }
  }, [page]);
  const handleAddAppointment = () => {
    const { push } = history;
    push("/AppointmentsAdd");
  };
  const handlePage = async (e, value) => {
    setPage(value);
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleSearchEnter = async () => {
    dispatch(showLoading());
    try {
      await initRows(page);
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401) {
        msg = data.msg;
      }
      swal("wrong!", msg, "error");
    } finally {
      dispatch(hideLoading());
    }
  };
  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col md={12}>
            <Row className="justify-content-between">
              <Col
                xs={{ order: "last" }}
                md={{ order: "first", span: 3 }}
                className="mt-2"
              >
                <Button
                  fullWidth
                  variant="contained"
                  className={classes.searchBtn}
                  onClick={handleAddAppointment}
                >
                  Give Appointment
                </Button>
              </Col>
              <Col
                xs={{ order: "first", span: 12 }}
                md={{ order: "last", span: 3 }}
                className="mt-2"
              >
                <TextField
                  fullWidth
                  size="small"
                  id="search"
                  type="number"
                  label="Search by ID"
                  variant="outlined"
                  value={search}
                  onChange={handleSearch}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") handleSearchEnter();
                  }}
                  className={classes.textField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <IconButton onClick={handleSearchEnter}>
                          <icons.Search />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={12} md={12} className="mt-4">
            <PTabel rows={rows} />
          </Col>
        </Row>
        {count > 6 ? (
          <div className="mt-5">
            <Pagination
              count={parseInt((count + 5) / 6)}
              onChange={handlePage}
              page={page}
              style={{ display: "flex", justifyContent: "center" }}
              variant="outlined"
              color="secondary"
            />
          </div>
        ) : null}
      </Container>
    </>
  );
};

export default withRouter(Patients);
