import React from "react";
import clsx from "clsx";
import { v4 } from "uuid";
import { makeStyles } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import {
  ListItemText,
  ListItemIcon,
  ListItem,
  IconButton,
  Divider,
  List,
  CssBaseline,
  Drawer,
  Avatar,
  Collapse,
} from "@material-ui/core";
import * as icons from "@material-ui/icons";
import MUIAppBar from "../components/appBar/AppBar";
import LocationCityIcon from '@mui/icons-material/LocationCity';
import { NavLink, withRouter } from "react-router-dom";

const drawerWidth = 240;
const adminSections = [
  {
    text: "Admins (مدیران)",
    icon: icons.SupervisorAccount,
    link: "/admin",
  },
  {
    text: "doctors (داکتران)",
    icon: icons.AccountBox,
    link: "/doctor",
  },
  {
    text: "Appointments (قرار ملاقات)",
    icon: icons.LocalHospitalOutlined,
    link: "/Appointments",
  },
  {
    text: "Patients (مریض ها)",
    icon: icons.AccessibilityOutlined,
    link: "/patients",
  },
  {
    text: "Hospital (شفاخانه ها)",
    icon: icons.LocalHospital,
    link: "/hospital",
  },
  {
    text: "Categories (بخش ها)",
    icon: icons.Category,
    link: "/Categories",
  },
  {
    text: "Laboratory (لابراتوار)",
    icon: icons.Healing,
    link: "/lab",
  },
  {
    text: "Pharmacy (دواخانه)",
    icon: icons.LocalPharmacy,
    link: "/pharmacy",
  },
  {
    text: "Ads (تبلیغات)",
    icon: icons.Notifications,
    link: "/ads",
  },
  {
    text: "Users (کاربران)",
    icon: icons.People,
    link: "/users",
  },
  {
    icon: icons.FeedbackOutlined,
    link: "/feedback",
    text: "FeedBack",
  },
  {
    icon: LocationCityIcon,
    link: "/Cities",
    text: "Cities",
  },
];
const doctorSections = [
  {
    text: "Dashboard (وضعیت)",
    icon: icons.Speed,
    link: "/Dashboard",
  },
  {
    text: "Profile (پروفایل)",
    icon: icons.AccountCircleOutlined,
    link: "/Profile",
  },
  {
    text: "Appointments (قرار ملاقات)",
    icon: icons.LocalHospitalOutlined,
    link: "/Appointments",
  },
  {
    text: "Patients (مریض ها)",
    icon: icons.AccessibilityOutlined,
    link: "/Patients",
  },
  {
    text: "Laboratory report (گزارش لابراتوار)",
    icon: icons.ReceiptOutlined,
    link: "/LaboratoryReport",
  },

  {
    text: "Doctors prescription (گزارش داکتر)",
    icon: icons.ReceiptOutlined,
    link: "/Doctorsprescription",
  },
  {
    text: "Assistant management (مدریت همکارها)",
    icon: icons.HeadsetMicOutlined,
    link: "/AssistantManagement",
  },
  {
    icon: icons.ScheduleOutlined,
    link: "/Settings",
    text: "Schedule (تقسیم اوقات)",
  },
  {
    text: "Settings (تنظیمات)",
    icon: icons.SettingsOutlined,
    collapse: true,
    other: [
      {
        icon: icons.VpnKeyOutlined,
        link: "/ChangePassword",
        text: "Change Password (تغییر رمز)",
      },
    ],
  },
];
const hospitalSections = [
  {
    text: "Profile (پروفایل)",
    icon: icons.AccountCircleOutlined,
    link: "/hProfile",
  },
  {
    text: "doctors (داکتران)",
    icon: icons.AccountBox,
    link: "/HDoctors",
  },
  {
    text: "Appointments (قرار ملاقات)",
    icon: icons.LocalHospitalOutlined,
    link: "/HAppointments",
  },
  {
    text: "Patients (مریض ها)",
    icon: icons.AccessibilityOutlined  ,
    link: "/HPatients",
  
  },
  {
    text: "Services (خدمتونه)",
    icon: icons.LocalHospitalOutlined  ,
    link: "/HDCheckUp",
  
  },
  {
    icon: icons.FeedbackOutlined,
    link: "/HFeeback",
    text: "FeedBack",
  },
]

const pharmacySections = [
  {
    text: "Profile (پروفایل)",
    icon: icons.AccountCircleOutlined,
    link: "/PharmacyDashboard",
  },
  {
    text: "Product & Servies (محصول و خدمات)",
    icon: icons.TableChart,
    link: "/Products",
  },
]
const LabSections = [
  {
    text: "Profile (پروفایل)",
    icon: icons.AccountCircleOutlined,
    link: "/LabDashboard",
  },
  {
    text: "Product & Servies (محصول و خدمات)",
    icon: icons.TableChart,
    link: "/LabProducts",
  },
]

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginTop: "2px",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    marginTop: "2px",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    width: "100vw",
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  listItemText: {
    fontSize: "0.8em",
  },
  activeNavLink: {
    backgroundColor: "#6DBCDB",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#2980b9",
      color: "#fff",
    },
  },
  avatarLarge: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    margin: "auto",
    marginTop: "20px",
    marginBottom: "20px",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const MainLayout = ({ children, location, role }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [settingIsOpen, setSettingIsOpen] = React.useState(false);
  const { pathname } = location;
  const handleChangeState = (text) => {
    if (text === "Settings (تنظیمات)") {
      setSettingIsOpen(!settingIsOpen);
    }
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const sections = role == "doctor" ? doctorSections : role == "hospital" ? hospitalSections : role == "pharmacy" ? pharmacySections : role == "lab" ? LabSections :  adminSections;
  const handleDrawerClose = () => {
    setOpen(false);
  };
  return (
    <div className={classes.root}>
      <CssBaseline />
      <MUIAppBar handleDrawerOpen={handleDrawerOpen} open={open} role={role} />

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <Avatar
            alt="icon"
            src="/imgs/doctoryab.jpg"
            className={classes.avatarLarge}
          />
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>

        <List>
          {sections.map((item) => (
            <div key={v4()}>
              <ListItem
                component={NavLink}
                isActive={(_, { pathname }) => pathname.startsWith(item.link)}
                activeClassName={
                  pathname.startsWith(item.link) ? classes.activeNavLink : ""
                }
                to={item.collapse ? "" : item.link}
                onClick={(e) => {
                  if (!item.link) e.preventDefault();
                  handleChangeState(item.text);
                }}
                button
              >
                <ListItemIcon>
                  {
                    <item.icon
                      style={{
                        color: pathname.startsWith(item.link)
                          ? "#fff"
                          : "#6DBCDB",
                      }}
                    />
                  }
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.listItemText }}
                  primary={item.text}
                />
                {item.collapse ? (
                  settingIsOpen ? (
                    <icons.ExpandLess />
                  ) : (
                    <icons.ExpandMore />
                  )
                ) : null}
              </ListItem>
              {item.collapse ? (
                <Collapse in={settingIsOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.other.map((x) => (
                      <ListItem
                        component={NavLink}
                        to={x.link}
                        button
                        className={classes.nested}
                        isActive={(_, { pathname }) =>
                          pathname.startsWith(x.link)
                        }
                        activeClassName={
                          pathname.startsWith(x.link)
                            ? classes.activeNavLink
                            : ""
                        }
                        key={x.text}
                      >
                        <ListItemIcon>
                          <x.icon
                            style={{
                              color: pathname.startsWith(x.link)
                                ? "#fff"
                                : "#6DBCDB",
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          classes={{ primary: classes.listItemText }}
                          primary={x.text}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              ) : null}
            </div>
          ))}
        </List>
      </Drawer>

      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        {children}
      </main>
    </div>
  );
};

export default withRouter(MainLayout);
