import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import * as icons from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { root } from "../../Services/config.json";


const useStyles = makeStyles((theme) => ({
    Btn: {
        borderRadius: 0,
        color: "#fff",
        backgroundColor: "#6DBCDB",
        "&:hover": {
            backgroundColor: "#0097e6",
        },
    },
}));

const Reports = ({ location }) => {
    let report = location.reports;
    const [reports, setReports] = useState(report)

    // useEffect(async () => {

    // }, []);

    return (
        <>
            <Container>
                {reports && reports.length > 0 ?
                    <>{reports.map((val, idx) => {
                        return <Row className="justify-content-center p-3 m-2">
                            <Card sx={{ minWidth: 275 }} style={{margin: 5}}>
                                <CardContent>
                                    <img src={`${root}${val}`} alt="report" width='200' height='200' />
                                </CardContent>
                                <CardActions>
                                    <a href={`${root}${val}`} download="report" target="_blank">
                                        <Button size="small">View Full</Button>
                                    </a>

                                </CardActions>
                            </Card>
                        </Row>
                    })}

                    </> : <Container className="justify-content-center">
                        <Row className="justify-content-center align-item-center" style={{ width: '100%', height: "100%" }}>
                            <Typography variant="h6" color={'#0096FF'} fontFamily={'serif'}>
                                Reports Not Found !
                            </Typography>
                        </Row>
                    </ Container >}
            </Container>
        </>
    );
};

export default withRouter(Reports);
