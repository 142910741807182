import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import {CreateCity} from "../../Services/Cities";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { useDispatch } from "react-redux";
import {
    TextField,
    Button,
} from "@material-ui/core";
import swal from "sweetalert";

const AddCity = ({ history }) => {
      const dispatch = useDispatch();
    const [e_name, setEname] = useState("");
    const [p_name, setPname] = useState("");
    const [f_name, setFname] = useState("");
    const [r_name, setRname] = useState("");
    const [u_name, setUname] = useState("");





 
      const handleEname = (e) => {
        setEname(e.target.value);
      };
      const handlePname = (e) => {
        setPname(e.target.value);
      };
      const handleFname = (e) => {
        setFname(e.target.value);
      };
      const handleRname = (e) => {
        setRname(e.target.value);
      };
      const handleUname = (e) => {
        setUname(e.target.value);
      };

      const handleSave = async () => {
       
          if (
            e_name.length > 0 &&
            // p_name.length > 0 &&
            // f_name.length > 0 
            r_name.length > 0 &&
            u_name.length > 0 
          ) {
            try {
              dispatch(showLoading());
              let body = {e_name : e_name, r_name: r_name, u_name: u_name}
              await CreateCity(body);
              swal("success!", "successfully City Add!", "success");
              history.goBack();
            } catch (error) {
              let msg = "some error occured";
              let { status, data } = error.response;
              if (status == 400 || status == 401) {
                msg = data.msg;
              }

              swal("wrong!", msg, "error");
            } finally {
              dispatch(hideLoading());
            }
          } else {
            swal("wrong!", "please fill all field!", "error");
          }
      };

    return (
        <>
            <Container>
                <Row className="justify-content-center">
                    <Col md="3" className="mt-2">
                        <TextField
                            size="small"
                            fullWidth
                            id="Englishname"
                            label="English Name"
                            value={e_name}
                            onChange={handleEname}
                            variant="outlined"
                        />
                    </Col>
                     {/* <Col md="3" className="mt-2">
                        <TextField
                            size="small"
                            fullWidth
                            id="russianname"
                            label="Russian Name"
                            value={r_name}
                            onChange={handleRname}
                            variant="outlined"
                        />
                    </Col> */}
                    {/* <Col md="3" className="mt-2">
                        <TextField
                            size="small"
                            fullWidth
                            id="Pashtoname"
                            label="Pashto Name"
                            value={p_name}
                            onChange={handlePname}
                            variant="outlined"
                        />
                    </Col> */}
                </Row>
                <Row className="justify-content-center">
                    {/* <Col md="3" className="mt-2">
                        <TextField
                            size="small"
                            fullWidth
                            id="farsiname"
                            label="Dari Name"
                            value={f_name}
                            onChange={handleFname}
                            variant="outlined"
                        />
                    </Col> */}
                    <Col md="3" className="mt-2">
                        <TextField
                            size="small"
                            fullWidth
                            id="russianname"
                            label="Russian Name"
                            value={r_name}
                            onChange={handleRname}
                            variant="outlined"
                        />
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col md="3" className="mt-2">
                        <TextField
                            size="small"
                            fullWidth
                            id="Uzbekname"
                            label="Uzbek Name"
                            value={u_name}
                            onChange={handleUname}
                            variant="outlined"
                        />
                    </Col>
                </Row>
                <Row className="justify-content-center mt-4">
                    <Col md="6">
                        <Row className="justify-content-end">
                            <Col md="2">
                                <Button
                                    fullWidth
                                    onClick={handleSave}
                                    variant="contained"
                                    color="secondary"
                                >
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default AddCity;
