import { get } from "../http";
import { root } from "../config.json";

export const getUsers = (page, name) => {
  return get(
    `${root}/api/v1/user/users?page=${page}&limit=8${
      name.length > 0 ? `&name=${name}` : ""
    }`
  );
};
