import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Button,
  Card,
  CardActions,
  CardActionArea,
  CircularProgress,
  Dialog,
} from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { updateLabReport, sendImage } from "../../Services/doctor/Laboratory";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { useDispatch } from "react-redux";
import swal from "sweetalert";
import { getImage } from "../../Services/doctor/Laboratory";

const EditLabReport = ({ location, history }) => {
  const { info } = location.state;
  const [patientId, setPatientId] = useState(info.id);
  const [name, setName] = useState(info.name);
  const [title, setTitle] = useState(info.fullTitle);
  const [desc, setDesc] = useState(info.fullDescription);
  const [phone, setPhone] = useState(info.phone);
  const [images, setImages] = useState(info.imgs);
  const [newImages, setNewImages] = useState([]);
  const [open, setOpen] = useState(false);
  const [flag, setFlag] = useState(false);
  const [openIdx, setOpenIdx] = useState(0);
  const [dow, setDow] = useState([]);
  const [mustBeDelImgs, setMustBeDelImgs] = useState([]);
  const imgs = useRef(null);
  const dispatch = useDispatch();
  const handlePaitentId = (e) => {
    setPatientId(e.target.value);
  };
  const handleName = (e) => {
    setName(e.target.value);
  };
  const handlePhone = (e) => {
    setPhone(e.target.value);
  };

  const handleDesc = (e) => {
    setDesc(e.target.value);
  };
  const handleTitle = (e) => {
    setTitle(e.target.value);
  };
  const recDowImg = (i, tmp) => {
    if (i == info.imgs.length) return;
    getImage(info.imgs[i]).then((res) => {
      tmp[i] = Buffer.from(res.data, "binary").toString("base64");
      let x = [...tmp];
      setDow(x);
      recDowImg(i + 1, tmp);
    });
  };
  useEffect(() => {
    let tmp = [];
    for (let i = 0; i < info.imgs.length; i++) {
      tmp.push("");
    }
    setDow(tmp);
    recDowImg(0, tmp);
  }, []);
  const recReadFile = (idx, tmp) => {
    if (imgs.current.files.length == idx) {
      setNewImages(tmp);
      return;
    }
    let file = imgs.current.files[idx];
    let read = new FileReader();
    read.readAsDataURL(file);
    read.onload = (e) => {
      tmp.push(e.target.result);
    };
    read.onloadend = () => {
      recReadFile(idx + 1, tmp);
    };
  };
  const handleOnchangeFile = () => {
    let tmp = [];
    recReadFile(0, tmp);
  };
  const handleSave = async () => {
    if (patientId && name && phone && title && desc) {
      dispatch(showLoading());
      try {
        const { data } = await updateLabReport(info._id, {
          patientId,
          name,
          phone,
          title,
          description: desc,
          imgs: mustBeDelImgs,
        });
        let tmp = [...imgs.current.files];
        if (tmp.length > 0) await sendImage(tmp, info._id);
        history.goBack();
        swal("success!", "successfully updated!", "success");
      } catch (error) {
        let msg = "some error occured";
        let { status, data } = error.response;
        if (status == 400 || status == 401) {
          msg = data.msg;
        }
        swal("wrong!", msg, "error");
      } finally {
        dispatch(hideLoading());
      }
    } else {
      swal("error!", "please fill all fields.", "error");
    }
  };
  const handleDeleteImg = async (path, idx) => {
    let tmp = [...mustBeDelImgs];
    tmp.push(path);
    setMustBeDelImgs(tmp);
    tmp = [...images];
    tmp.splice(idx, 1);
    setImages(tmp);
    tmp = [...dow];
    tmp.splice(idx, 1);
    setDow(tmp);
  };
  const handleModalClose = () => {
    setOpen(false);
  };
  const handleModalOpen = (idx, newImgs) => {
    setOpen(true);
    setFlag(newImgs);
    setOpenIdx(idx);
  };
  return (
    <>
      <Container>
        <Dialog open={open} onClose={handleModalClose}>
          <img
            src={
              flag
                ? `${newImages[openIdx]}`
                : `data:image/jpeg;base64,${dow[openIdx]}`
            }
          />
        </Dialog>
        <Row className="justify-content-center mb-5">
          <Col md="3" className="mt-2">
            <TextField
              size="small"
              fullWidth
              id="pid"
              onChange={handlePaitentId}
              value={patientId}
              variant="outlined"
              label="Patient id"
            />
          </Col>
          <Col md="3" className="mt-2 text-right"></Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="6">Patient</Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="4" className="mt-2">
            <TextField
              size="small"
              fullWidth
              value={name}
              onChange={handleName}
              id="fname"
              label="full name"
              variant="outlined"
            />
          </Col>
          <Col md="2" className="mt-2">
            <TextField
              size="small"
              fullWidth
              value={phone}
              inputProps={{ maxLength: 10 }}
              onChange={handlePhone}
              id="phone"
              label="Phone"
              variant="outlined"
            />
          </Col>
        </Row>

        <Row className="justify-content-center mt-4">
          <Col md="6">Title</Col>
        </Row>
        <Row className="justify-content-center mt-2">
          <Col md="6">
            <TextField
              size="small"
              value={title}
              fullWidth
              onChange={handleTitle}
              id="title"
              label="title"
              variant="outlined"
            />
          </Col>
        </Row>

        <Row className="justify-content-center mt-4">
          <Col md="6">Description</Col>
        </Row>
        <Row className="justify-content-center mt-2">
          <Col md="6">
            <TextField
              size="small"
              fullWidth
              value={desc}
              onChange={handleDesc}
              id="fname"
              placeholder="write here..."
              variant="outlined"
              multiline
              rows={5}
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="6">
            <Row>
              {newImages.map((url, idx) => (
                <Col key={idx} md="6" className="mt-3">
                  <img
                    onClick={() => {
                      handleModalOpen(idx, true);
                    }}
                    src={url}
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-center ">
          <Col md="3" className="mt-3 text-left">
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={() => {
                imgs.current.click();
              }}
            >
              Select new images
            </Button>
          </Col>
          <Col md="3" className="mt-3"></Col>
        </Row>
        <Row className="justify-content-center mb-5">
          <Col md="6">
            <Row>
              {images.map((url, idx) => (
                <Col key={idx} md="6" className="mt-3">
                  <Card>
                    <CardActionArea>
                      {dow[idx] && dow[idx].length > 0 ? (
                        <img
                          onClick={() => {
                            handleModalOpen(idx, false);
                          }}
                          src={`data:image/jpeg;base64,${dow[idx]}`}
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "contain",
                          }}
                        />
                      ) : (
                        <div style={{ height: 140 }} className="text-center">
                          <CircularProgress style={{ marginTop: "55px" }} />
                        </div>
                      )}
                    </CardActionArea>
                    <CardActions>
                      <Button
                        size="small"
                        color="secondary"
                        variant="contained"
                        onClick={() => {
                          handleDeleteImg(url, idx);
                        }}
                      >
                        Delete
                      </Button>
                    </CardActions>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>

        <input
          multiple
          type="file"
          accept="image/png, image/jpeg"
          onChange={handleOnchangeFile}
          style={{ display: "none" }}
          ref={imgs}
        />
        <Row className="justify-content-center mt-2">
          <Col md="6">
            <Row className="justify-content-end">
              <Col md="2">
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={handleSave}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EditLabReport;
