import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Pagination } from "@material-ui/lab";
import {
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import * as icons from "@material-ui/icons";
import PTabel from "./PTabel";
import { withRouter } from "react-router-dom";
import { getPatients} from "../../../Services/Hospital/Patients";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { useDispatch } from "react-redux";
import swal from "sweetalert";

const useStyles = makeStyles((theme) => ({
  textField: {
    [`& fieldset`]: {
      borderRadius: 0,
    },
  },
  searchBtn: {
    borderRadius: 0,
    color: "#fff",
    backgroundColor: "#6DBCDB",
    "&:hover": {
      backgroundColor: "#0097e6",
    },
  },
}));

const Patients = ({ history }) => {
  let id = localStorage.getItem("id")
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);


  const initRows = async (page) => {
    let {data} = await getPatients(id)
    setCount(data.count);
    console.log(data.users);
    data = data.users;
    let tmp = [];
    for (let i = 0; i < data.length; i++) {
      let x = data[i];
      let date = new Date(x.createAt);
      date = date.toLocaleString().split(",")[0];
      tmp.push({
        _id: x._id,
        name: x.name,
        id: x.patientID,
        age: x.age,
        date: x.date,
        photo: x.photo,
        phone: x.phone
      });
    }
    setRows(tmp);
  };

  const handleVisitDoc = (row) => {
    const { push } = history;
    push({ pathname: "/HPatients/View", state: row });
  };
  useEffect(async () => {
    dispatch(showLoading());
    try {
      await initRows(page);
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401) {
        msg = data.msg;
      }
      swal("wrong!", msg, "error");
    } finally {
      dispatch(hideLoading());
    }
  }, [page]);

  const handlePage = async (e, value) => {
    setPage(value);
  };



  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col xs={12} md={12} className="mt-4">
            <PTabel rows={rows} handleVisitDoc={handleVisitDoc} />
          </Col>
        </Row>
        {count > 6 ? (
          <div className="mt-5">
            <Pagination
              count={parseInt((count + 5) / 6)}
              onChange={handlePage}
              page={page}
              style={{ display: "flex", justifyContent: "center" }}
              variant="outlined"
              color="secondary"
            />
          </div>
        ) : null}
      </Container>
    </>
  );
};

export default withRouter(Patients);
