import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";

import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Button,
} from "@material-ui/core";

import * as icons from "@material-ui/icons";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const HTabel = ({ rows, handleRemove, handleEdit, handleCheckUp ,handleSwitch}) => {
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
          <StyledTableCell align="left">ID</StyledTableCell>
            <StyledTableCell align="left">Name</StyledTableCell>
            <StyledTableCell align="left">Address</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, idx) => (
            <StyledTableRow key={idx}>
              <StyledTableCell align="left">{row.ID ? row.ID : "N/A"}</StyledTableCell>
              <StyledTableCell align="left">{row.name}</StyledTableCell>
              <StyledTableCell align="left">
                <div className="d-flex justify-content-between">
                  <div>{row.address}</div>
                  <div className="d-flex">
                    <div>
                      <Button
                        className="p-0"
                        onClick={() => {
                          handleRemove(row.id, row.name);
                        }}
                      >
                        <icons.DeleteOutline className="text-danger" />
                      </Button>
                    </div>
                    <div>
                      <Button
                        className="p-0"
                        onClick={() => {
                          handleCheckUp(row);
                        }}
                      >
                        <icons.TableChart style={{ color: "#0abde3" }} />
                      </Button>
                    </div>
                    <div>
                      <Button
                        className="p-0"
                        onClick={() => {
                          handleEdit(idx);
                        }}
                      >
                        <icons.EditOutlined style={{ color: "#0abde3" }} />
                      </Button>
                    </div>
                    <div>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        handleSwitch(row.id,idx);
                      }}
                    >
                      Switch
                    </Button>
                    </div>
                  </div>
                </div>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default HTabel;
