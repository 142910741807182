import React, { useEffect, useState } from "react";
import { TextField, Button, Chip, Divider, Checkbox, FormControl, OutlinedInput, InputAdornment, IconButton } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import * as icons from "@material-ui/icons";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import { Row, Col, Container } from "react-bootstrap";
import DateFnsUtils from "@date-io/date-fns";
import Map from "../utils/Map";
import { getCities } from "../../Services/doctor/profile";
import ImgComp from "../utils/ImageComp";
import { createPharmacy, uploadImg } from "../../Services/Pharmacy";
import swal from "sweetalert";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { useDispatch } from "react-redux";

const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const TimeComp = (label, time, setTime, disabled) => {
  const handleTime = (e) => {
    setTime(e);
  };
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardTimePicker
        margin="normal"
        id="time-picker"
        label={label}
        value={time}
        disabled={disabled}
        onChange={handleTime}
        KeyboardButtonProps={{
          "aria-label": "change time",
        }}
      />
    </MuiPickersUtilsProvider>
  );
};
const PharmacyAdd = ({ history }) => {
  const dispatch = useDispatch();
  const [blob, setBlob] = useState(null);
  const [name, setName] = useState("");
  const [city, setCity] = useState("");
  const [value, setValue] = useState([]);
  const [currPhone, setCurrPhone] = useState("");
  const [citiesList, setCitiesList] = useState([]);
  const [address, setAddress] = useState("");
  const [Pemail, setPEmail] = useState("");
  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  const [lng, setLng] = useState(69.2075);
  const [lat, setLat] = useState(34.5553);

  let sDate = new Date(1627241340000);
  let eDate = new Date(1627155000000);
  const [A, setA] = useState(false);
  const [B, setB] = useState(false);
  const [C, setC] = useState(false);
  const [D, setD] = useState(false);
  const [E, setE] = useState(false);
  const [F, setF] = useState(false);
  const [G, setG] = useState(false);

  const [day_0_s, setDay_0_s] = useState(sDate);
  const [day_1_s, setDay_1_s] = useState(sDate);
  const [day_2_s, setDay_2_s] = useState(sDate);
  const [day_3_s, setDay_3_s] = useState(sDate);
  const [day_4_s, setDay_4_s] = useState(sDate);
  const [day_5_s, setDay_5_s] = useState(sDate);
  const [day_6_s, setDay_6_s] = useState(sDate);

  const [day_0_e, setDay_0_e] = useState(eDate);
  const [day_1_e, setDay_1_e] = useState(eDate);
  const [day_2_e, setDay_2_e] = useState(eDate);
  const [day_3_e, setDay_3_e] = useState(eDate);
  const [day_4_e, setDay_4_e] = useState(eDate);
  const [day_5_e, setDay_5_e] = useState(eDate);
  const [day_6_e, setDay_6_e] = useState(eDate);

  //////////////////////////////

  const handleCheck = (start, end, check, setCheck) => {
    if (!check) {
      start(sDate);
      end(eDate);
    }
    setCheck(!check);
  };

  const handleMap = (mapProps, map, e) => {
    setLat(e.latLng.lat());
    setLng(e.latLng.lng());
  };

  const handleName = (e) => {
    setName(e.target.value);
  };
  const emailHandle = (e) => {
    setPEmail(e.target.value);
  };
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleAddress = (e) => {
    setAddress(e.target.value);
  };
  const handleCity = (e, item) => {
    setCity(item ? item._id : "");
  };

  const handleCurrPhone = (e) => {
    setCurrPhone(e.target.value);
  };

  useEffect(() => {
    const call = async () => {
      let { data } = await getCities();
      setCitiesList(data.data);
    };
    call();
  }, []);

  const handleSave = async () => {
    if (validateEmail(Pemail)) {
      if (
        city.length > 0 &&
        value.length &&
        name.length > 0 &&
        address.length > 0 &&
        blob &&
        values.password.length > 0
      ) {
        try {
          dispatch(showLoading());
          let _24tmp = [B, C, D, E, F, G, A];
          let _24hours = [];
          for (let i in _24tmp) {
            if (_24tmp[i]) _24hours.push(i);
          }
          let { data } = await createPharmacy({
            name: name,
            phone: value,
            city: city,
            address,
            geometry: { coordinates: [lng, lat] },
            times: [
              [day_0_s, day_0_e],
              [day_1_s, day_1_e],
              [day_2_s, day_2_e],
              [day_3_s, day_3_e],
              [day_4_s, day_4_e],
              [day_5_s, day_5_e],
              [day_6_s, day_6_e],
            ],
            _24Hours: _24hours,
            email: Pemail,
            password: values.password,
          });
          let id = data.data._id;

          const fd = new FormData();
          fd.append("img", blob, "jack");
          await uploadImg(id, fd);

          swal("success!", "successfully Pharmacy created!", "success");
          history.goBack();
        } catch (error) {
          let msg = "some error occured";
          let { status, data } = error.response;
          if (status == 400 || status == 401) {
            
            msg = data.msg;
          }
          swal("wrong!", msg, "error");
        } finally {
          dispatch(hideLoading());
        }
      } else {
        swal("error", "please fill all filds.", "error");
      }
    }
    else {
      swal("wrong!", "Email is invalid.", "error");
    }

  };

  return (
    <Container>
      <Row className="justify-content-center">
        <Col md="3" className="mt-2">
          <TextField
            variant="outlined"
            value={name}
            onChange={handleName}
            size="small"
            fullWidth
            label="Name"
          />
        </Col>
        <Col md="3" className="mt-2">
          <Autocomplete
            options={citiesList}
            getOptionLabel={(option) => option.e_name}
            onChange={handleCity}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                label="City"
                variant="outlined"
              />
            )}
          />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="3" className="mt-2">
          <TextField
            size="small"
            fullWidth
            id="email"
            label="Email Address"
            value={Pemail}
            onChange={emailHandle}
            variant="outlined"
          />
        </Col>
        <Col md="3" className="mt-2">
          <FormControl fullWidth size="small" variant="outlined">
            <OutlinedInput
              placeholder="Password"
              id="outlined-adornment-password"
              type={values.showPassword ? "text" : "password"}
              value={values.password}
              onChange={handleChange("password")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? (
                      <icons.Visibility />
                    ) : (
                      <icons.VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="6" className="mt-2">
          <Autocomplete
            multiple
            options={[currPhone]}
            getOptionLabel={(option) => option}
            value={value}
            onChange={(event, newValue) => {
              setValue([...newValue]);
            }}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                label="Phone"
                onChange={handleCurrPhone}
                variant="outlined"
              />
            )}
          />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="6" className="mt-2">
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            label="Address"
            value={address}
            onChange={handleAddress}
          />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="6" className="mt-2">
          <Map lat={lat} lng={lng} handleMap={handleMap} />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="1" className="mt-5">
          <h6>Saturday</h6>
        </Col>
        <Col md="2" className="mt-2">
          {TimeComp("Start", day_0_s, setDay_0_s, A)}
        </Col>
        <Col md="2" className="mt-2">
          {TimeComp("End", day_0_e, setDay_0_e, A)}
        </Col>
        <Col md="1" className="mt-4">
          <p>
            <sub>24 Hours</sub>
            <Checkbox
              checked={A}
              onChange={() => {
                handleCheck(setDay_0_s, setDay_0_e, A, setA);
              }}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </p>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col md="1" className="mt-5">
          <h6>Sunday</h6>
        </Col>
        <Col md="2" className="mt-2">
          {TimeComp("Start", day_1_s, setDay_1_s, B)}
        </Col>
        <Col md="2" className="mt-2">
          {TimeComp("End", day_1_e, setDay_1_e, B)}
        </Col>
        <Col md="1" className="mt-4">
          <p>
            <sub>24 Hours</sub>
            <Checkbox
              checked={B}
              onChange={() => {
                handleCheck(setDay_1_s, setDay_1_e, B, setB);
              }}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </p>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col md="1" className="mt-5">
          <h6>Monday</h6>
        </Col>
        <Col md="2" className="mt-2">
          {TimeComp("Start", day_2_s, setDay_2_s, C)}
        </Col>
        <Col md="2" className="mt-2">
          {TimeComp("End", day_2_e, setDay_2_e, C)}
        </Col>
        <Col md="1" className="mt-4">
          <p>
            <sub>24 Hours</sub>
            <Checkbox
              checked={C}
              onChange={() => {
                handleCheck(setDay_2_s, setDay_2_e, C, setC);
              }}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </p>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col md="1" className="mt-5">
          <h6>Tuesday</h6>
        </Col>
        <Col md="2" className="mt-2">
          {TimeComp("Start", day_3_s, setDay_3_s, D)}
        </Col>
        <Col md="2" className="mt-2">
          {TimeComp("End", day_3_e, setDay_3_e, D)}
        </Col>
        <Col md="1" className="mt-4">
          <p>
            <sub>24 Hours</sub>
            <Checkbox
              checked={D}
              onChange={() => {
                handleCheck(setDay_3_s, setDay_3_e, D, setD);
              }}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </p>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col md="1" className="mt-5">
          <h6>Wednesday</h6>
        </Col>
        <Col md="2" className="mt-2">
          {TimeComp("Start", day_4_s, setDay_4_s, E)}
        </Col>
        <Col md="2" className="mt-2">
          {TimeComp("End", day_4_e, setDay_4_e, E)}
        </Col>
        <Col md="1" className="mt-4">
          <p>
            <sub>24 Hours</sub>
            <Checkbox
              checked={E}
              onChange={() => {
                handleCheck(setDay_4_s, setDay_4_e, E, setE);
              }}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </p>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col md="1" className="mt-5">
          <h6>Thursday</h6>
        </Col>
        <Col md="2" className="mt-2">
          {TimeComp("Start", day_5_s, setDay_5_s, F)}
        </Col>
        <Col md="2" className="mt-2">
          {TimeComp("End", day_5_e, setDay_5_e, F)}
        </Col>
        <Col md="1" className="mt-4">
          <p>
            <sub>24 Hours</sub>
            <Checkbox
              checked={F}
              onChange={() => {
                handleCheck(setDay_5_s, setDay_5_e, F, setF);
              }}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </p>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col md="1" className="mt-5">
          <h6>Friday</h6>
        </Col>
        <Col md="2" className="mt-2">
          {TimeComp("Start", day_6_s, setDay_6_s, G)}
        </Col>
        <Col md="2" className="mt-2">
          {TimeComp("End", day_6_e, setDay_6_e, G)}
        </Col>
        <Col md="1" className="mt-4">
          <p>
            <sub>24 Hours</sub>
            <Checkbox
              checked={G}
              onChange={() => {
                handleCheck(setDay_6_s, setDay_6_e, G, setG);
              }}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </p>
        </Col>
      </Row>

      <ImgComp loading={false} blob={blob} setBlob={setBlob} />
      <Row className="justify-content-center">
        <Col md="5"></Col>
        <Col md="1" className="mt-4">
          <Button
            onClick={handleSave}
            fullWidth
            variant="contained"
            color="secondary"
          >
            Save
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default PharmacyAdd;
