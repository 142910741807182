import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Row, Col } from "react-bootstrap";
import { Button } from "@material-ui/core";
import Table from "../services/PCheckUpTable";
import { useDispatch } from "react-redux";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { getCheckUps,updatePharmacy} from "../../../Services/Pharmacy";
import swal from "sweetalert";
const useStyles = makeStyles((theme) => ({
  Btn: {
    borderRadius: 0,
    color: "#fff",
    backgroundColor: "#6DBCDB",
    "&:hover": {
      backgroundColor: "#0097e6",
    },
  },
}));
const PharmacyCheckUp = ({ location, history }) => {
  let id = localStorage.id
  let pharmacy = location.state;
  const dispatch = useDispatch();
  const [checkUps, setCheckUps] = useState([]);
  const fetching = async () => {
    try {
      dispatch(showLoading());
      let { data } = await getCheckUps(id);
      setCheckUps(data.data);
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401) {
        msg = data.msg;
      }
      swal("wrong!", msg, "error");
    } finally {
      dispatch(hideLoading());
    }
  };
  useEffect(() => {
    fetching();
  }, []);
  const handleRemove = async (idx, name) => {
    let isSure = await swal({
      title: "Are you sure?",
      text: `Are you sure to delete ${name}!`,
      icon: "warning",
      buttons: ["No", "Yes"],
      dangerMode: true,
    });
    if (isSure) {
      let body = {
        id: id,
        deleteCheckUp: {
          idx,
        },
      };
      await updatePharmacy(body);
      fetching();
    }
  };
  const handleAddCheckUp = () => {
    history.push({ pathname: "/Products/PAddCheckUp", state: pharmacy });
  };
  const handleEdit = (idx, row) => {
    row["_id"] = id;
    row["idx"] = idx;
    history.push({ pathname: "/Products/PCheckUpEdit", state: row });
  };
  const classes = useStyles();
  return (
    <Container>
      <Row className="justify-content-between">
        <Col md="3" className="mt-2">
          <Button
            onClick={handleAddCheckUp}
            fullWidth
            variant="contained"
            className={classes.Btn}
          >
            + Add check up
          </Button>
        </Col>
        <Col md="4"></Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <Table
            rows={checkUps}
            handleRemove={handleRemove}
            handleEdit={handleEdit}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default PharmacyCheckUp;
