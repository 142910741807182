import { post, get, put, del } from "../../http";
import { root } from "../../config.json";

export const getCities = () => {
  return get(`${root}/api/v1/city/AllCities`);
};

// export const getCity = (id) => {
//   return get(`${root}/api/v1/city/${id}`);
// };
export const getCategories = () => {
  return get(`${root}/api/v1/category?limit=100`);
};

export const updateDoctor = (body) => {
  return put(`${root}/api/v1/doctor/`, body);
};

export const getDoctor = () => {
  return get(`${root}/api/v1/doctor/`);
};

export const postExperience = (body) => {
  return post(`${root}/api/v1/experience/`, body);
};

export const getExperience = () => {
  return get(`${root}/api/v1/experience/`);
};

export const delExperience = (id) => {
  return del(`${root}/api/v1/experience/${id}`);
};

export const postEducation = (body) => {
  return post(`${root}/api/v1/education/`, body);
};

export const getEducations = () => {
  return get(`${root}/api/v1/education/`);
};

export const delEducations = (id) => {
  return del(`${root}/api/v1/education/${id}`);
};

export const postLanguage = (body) => {
  return post(`${root}/api/v1/language/`, body);
};

export const getLanguagies = () => {
  return get(`${root}/api/v1/language/`);
};

export const delLanguage = (id) => {
  return del(`${root}/api/v1/language/${id}`);
};

export const postAward = (body) => {
  return post(`${root}/api/v1/award/`, body);
};

export const getAwards = () => {
  return get(`${root}/api/v1/award/`);
};

export const delAward = (id) => {
  return del(`${root}/api/v1/award/${id}`);
};

export const postDisease = (body) => {
  return post(`${root}/api/v1/disease/`, body);
};

export const getDisease = () => {
  return get(`${root}/api/v1/disease/`);
};

export const delDisease = (tag) => {
  return del(`${root}/api/v1/disease?tag=${tag}`);
};

export const uploadProfile = (data) => {
  return post(`${root}/api/v1/doctor/img`, data, {
    headers: { "content-type": "multipart/form-data" },
  });
};
