import { post, get, del, sendImagesConfig, put } from "../../http";
import { root } from "../../config.json";

export const postPatentReport = (body) => {
  return post(`${root}/api/v1/patientReport`, body);
};

export const sendImage = (files, id) => {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append("imgs", file);
  });
  return post(
    `${root}/api/v1/patientReport/imgs/${id}`,
    formData,
    sendImagesConfig
  );
};

export const getPatientReports = (page) => {
  return get(`${root}/api/v1/patientReport?page=${page}&limit=6`);
};

export const delReport = (id) => {
  return del(`${root}/api/v1/patientReport/${id}`);
};

export const searchReport = (page, pid) => {
  return get(
    `${root}/api/v1/patientReport/search?page=${page}&limit=6&patientId=${pid}`
  );
};

export const getImage = (path) => {
  return get(`${root}${path}`, {
    responseType: "arraybuffer",
  });
};

export const updateReport = (id, body) => {
  return put(`${root}/api/v1/patientReport/${id}`, body);
};

export const getAllPatient = (page) => {
  return get(`${root}/api/v1/patient?limit=6&page=${page}`);
};
export const getDoctorPatients = (pid) => {
  return get(`${root}/api/v1/patient/patients/${pid}`);
};
export const UpdateDoctorPatients = (pid,body) => {
  return put(`${root}/api/v1/patient/patients/${pid}`, body);
};

export const searchPatient = (page, pid) => {
  return get(
    `${root}/api/v1/patient/searchPatient?page=${page}&limit=6&pid=${pid}`
  );
};

export const getSpPatientReports = (page, pid, name) => {
  return get(
    `${root}/api/v1/patientReport/patientReports/${pid}?page=${page}&limit=6&name=${name}`
  );
};

export const getSpLabReports = (page, pid, name) => {
  return get(
    `${root}/api/v1/laboratory/patientReports/${pid}?page=${page}&limit=6&name=${name}`
  );
};
