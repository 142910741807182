import React, { useEffect, useState } from "react";
import { TextField, Button, Chip, Divider, FormControl, OutlinedInput, InputAdornment, IconButton } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import * as icons from "@material-ui/icons";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import { Row, Col, Container } from "react-bootstrap";
import DateFnsUtils from "@date-io/date-fns";
import Map from "../utils/Map";
import { getCities } from "../../Services/doctor/profile";
import ImgComp from "../utils/ImageComp";
import { createLab, uploadImg } from "../../Services/Lab";
import swal from "sweetalert";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { useDispatch } from "react-redux";


const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};



const TimeComp = (label, time, setTime) => {
  const handleTime = (e) => {
    setTime(e);
  };
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardTimePicker
        margin="normal"
        id="time-picker"
        label={label}
        value={time}
        onChange={handleTime}
        KeyboardButtonProps={{
          "aria-label": "change time",
        }}
      />
    </MuiPickersUtilsProvider>
  );
};
const LabAdd = ({ }) => {
  const dispatch = useDispatch();
  const [blob, setBlob] = useState(null);
  const [name, setName] = useState("");
  const [city, setCity] = useState("");
  const [value, setValue] = useState([]);
  const [currPhone, setCurrPhone] = useState("");
  const [citiesList, setCitiesList] = useState([]);
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  const [lng, setLng] = useState(69.2075);
  const [lat, setLat] = useState(34.5553);

  let date = new Date(1627155000000);
  const [day_0_s, setDay_0_s] = useState(date);
  const [day_1_s, setDay_1_s] = useState(date);
  const [day_2_s, setDay_2_s] = useState(date);
  const [day_3_s, setDay_3_s] = useState(date);
  const [day_4_s, setDay_4_s] = useState(date);
  const [day_5_s, setDay_5_s] = useState(date);
  const [day_6_s, setDay_6_s] = useState(date);

  const [day_0_e, setDay_0_e] = useState(date);
  const [day_1_e, setDay_1_e] = useState(date);
  const [day_2_e, setDay_2_e] = useState(date);
  const [day_3_e, setDay_3_e] = useState(date);
  const [day_4_e, setDay_4_e] = useState(date);
  const [day_5_e, setDay_5_e] = useState(date);
  const [day_6_e, setDay_6_e] = useState(date);

  //////////////////////////////

  const handleMap = (mapProps, map, e) => {
    setLat(e.latLng.lat());
    setLng(e.latLng.lng());
  };

  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleAddress = (e) => {
    setAddress(e.target.value);
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const emailHandle = (e) => {
    setEmail(e.target.value);
  };

  const handleCity = (e, item) => {
    setCity(item ? item._id : "");
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleCurrPhone = (e) => {
    setCurrPhone(e.target.value);
  };

  useEffect(() => {
    const call = async () => {
      let { data } = await getCities();
      setCitiesList(data.data);
    };
    call();
  }, []);

  const handleSave = async () => {
    if (validateEmail(email)) {
      if (
        city.length > 0 &&
        value.length &&
        name.length > 0 &&
        address.length > 0
      ) {
        try {
          dispatch(showLoading());
          let { data } = await createLab({
            name: name,
            phone: value,
            city: city,
            address,
            geometry: { coordinates: [lng, lat] },
            times: [
              [day_0_s, day_0_e],
              [day_1_s, day_1_e],
              [day_2_s, day_2_e],
              [day_3_s, day_3_e],
              [day_4_s, day_4_e],
              [day_5_s, day_5_e],
              [day_6_s, day_6_e],
            ],
            email: email,
            password: values.password,
          });
          let id = data.data._id;
          if (blob) {
            const fd = new FormData();
            fd.append("img", blob, "jack");
            await uploadImg(id, fd);
          }
          swal("success!", "successfully Laboratory created!", "success");
        } catch (error) {
          swal("error", error.message, "error");
        } finally {
          dispatch(hideLoading());
        }
      } else {
        swal("error", "please fill all filds.", "error");
      }
    }
    else {
      swal("wrong!", "Email is invalid.", "error");
    }
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <Col md="3" className="mt-2">
          <TextField
            variant="outlined"
            value={name}
            onChange={handleName}
            size="small"
            fullWidth
            label="Name"
          />
        </Col>
        <Col md="3" className="mt-2">
          <Autocomplete
            options={citiesList}
            getOptionLabel={(option) => option.e_name}
            onChange={handleCity}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                label="City"
                variant="outlined"
              />
            )}
          />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="3" className="mt-2">
          <TextField
            size="small"
            fullWidth
            id="email"
            label="Email Address"
            value={email}
            onChange={emailHandle}
            variant="outlined"
          />
        </Col>
        <Col md="3" className="mt-2">
          <FormControl fullWidth size="small" variant="outlined">
            <OutlinedInput
              placeholder="Password"
              id="outlined-adornment-password"
              type={values.showPassword ? "text" : "password"}
              value={values.password}
              onChange={handleChange("password")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? (
                      <icons.Visibility />
                    ) : (
                      <icons.VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="6" className="mt-2">
          <Autocomplete
            multiple
            options={[currPhone]}
            getOptionLabel={(option) => option}
            value={value}
            onChange={(event, newValue) => {
              setValue([...newValue]);
            }}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                label="Phone"
                onChange={handleCurrPhone}
                variant="outlined"
              />
            )}
          />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="6" className="mt-2">
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            label="Address"
            value={address}
            onChange={handleAddress}
          />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="6" className="mt-2">
          <Map lat={lat} lng={lng} handleMap={handleMap} />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="2" className="mt-5">
          <h6>Saturday</h6>
        </Col>
        <Col md="2" className="mt-2">
          {TimeComp("Start", day_0_s, setDay_0_s)}
        </Col>
        <Col md="2" className="mt-2">
          {TimeComp("End", day_0_e, setDay_0_e)}
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col md="2" className="mt-5">
          <h6>Sunday</h6>
        </Col>
        <Col md="2" className="mt-2">
          {TimeComp("Start", day_1_s, setDay_1_s)}
        </Col>
        <Col md="2" className="mt-2">
          {TimeComp("End", day_1_e, setDay_1_e)}
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col md="2" className="mt-5">
          <h6>Monday</h6>
        </Col>
        <Col md="2" className="mt-2">
          {TimeComp("Start", day_2_s, setDay_2_s)}
        </Col>
        <Col md="2" className="mt-2">
          {TimeComp("End", day_2_e, setDay_2_e)}
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col md="2" className="mt-5">
          <h6>Tuesday</h6>
        </Col>
        <Col md="2" className="mt-2">
          {TimeComp("Start", day_3_s, setDay_3_s)}
        </Col>
        <Col md="2" className="mt-2">
          {TimeComp("End", day_3_e, setDay_3_e)}
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col md="2" className="mt-5">
          <h6>Wednesday</h6>
        </Col>
        <Col md="2" className="mt-2">
          {TimeComp("Start", day_4_s, setDay_4_s)}
        </Col>
        <Col md="2" className="mt-2">
          {TimeComp("End", day_4_e, setDay_4_e)}
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col md="2" className="mt-5">
          <h6>Thursday</h6>
        </Col>
        <Col md="2" className="mt-2">
          {TimeComp("Start", day_5_s, setDay_5_s)}
        </Col>
        <Col md="2" className="mt-2">
          {TimeComp("End", day_5_e, setDay_5_e)}
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col md="2" className="mt-5">
          <h6>Friday</h6>
        </Col>
        <Col md="2" className="mt-2">
          {TimeComp("Start", day_6_s, setDay_6_s)}
        </Col>
        <Col md="2" className="mt-2">
          {TimeComp("End", day_6_e, setDay_6_e)}
        </Col>
      </Row>

      <ImgComp loading={false} blob={blob} setBlob={setBlob} />
      <Row className="justify-content-center">
        <Col md="5"></Col>
        <Col md="1" className="mt-4">
          <Button
            onClick={handleSave}
            fullWidth
            variant="contained"
            color="secondary"
          >
            Save
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default LabAdd;
