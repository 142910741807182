import { post, put } from "../../http";
import { root } from "../../config.json";

export const login = (body) => {
  return post(`${root}/api/v1/login`, body);
};

export const changePwd = (body) => {
  return put(`${root}/api/v1/doctor/password`, body);
};
