import { useState } from "react";
import { TextField, Divider, Button } from "@material-ui/core";
import { Col, Row, Container } from "react-bootstrap";
import ImgComp from "../../utils/ImageComp";
import { updatePharmacy, upload_image_no_id } from "../../../Services/Pharmacy";
import { useDispatch } from "react-redux";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import swal from "sweetalert";
import { root } from "../../../Services/config.json";

const PCheckUpEdit = ({ location, history }) => {
  let info = location.state;
  const dispatch = useDispatch();
  const [blob, setBlob] = useState(null);
  const [title_brief, setTitle_brief] = useState(
    info.is_brief ? info.title : ""
  );
  const [title, setTitle] = useState(info.is_brief ? "" : info.title);
  const [price, setPrice] = useState(info.is_brief ? "" : info.price);
  const [price_brief, setPrice_brief] = useState(
    info.is_brief ? info.price : ""
  );
  const [description, setDescription] = useState(
    info.is_brief ? "" : info.content
  );
  const [url, setUrl] = useState(info.is_brief ? "" : `${root}${info.img}`);
  const handleSaveBreif = async () => {
    if (title_brief.length > 0 && price_brief.length > 0) {
      try {
        dispatch(showLoading());

        await updatePharmacy({
          edit_checkup: {
            is_brief: true,
            price: price_brief,
            title: title_brief,
          },
          id: info._id,
          idx: info.idx,
        });
        swal("success!", "successfully CheckUp Updated!", "success");
        history.goBack();
      } catch (error) {
        swal("error", error.message, "error");
      } finally {
        dispatch(hideLoading());
      }
    } else {
      swal("error", "please fill all filds", "error");
    }
  };
  const handleSave = async () => {
    if (
      title.length > 0 &&
      (price.length > 0 || price >= 0) &&
      description.length > 0 &&
      (blob || info.img)
    ) {
      try {
        dispatch(showLoading());
        let body = {
          edit_checkup: {
            is_brief: false,
            price: price,
            title: title,
            content: description,
            img: info.img,
          },
          id: info._id,
          idx: info.idx,
        };
        if (blob) {
          const fd = new FormData();
          fd.append("img", blob, "jack");
          let { data } = await upload_image_no_id(fd);
          body.edit_checkup.img = data.name;
        }
        await updatePharmacy(body);
        swal("success!", "successfully CheckUp Updated!", "success");
        history.goBack();
      } catch (error) {
        swal("error", error.message, "error");
      } finally {
        dispatch(hideLoading());
      }
    } else {
      swal("error", "please fill all filds", "error");
    }
  };
  const handleTitle_b = (e) => {
    setTitle_brief(e.target.value);
  };
  const handleTitle = (e) => {
    setTitle(e.target.value);
  };
  const handlePrice = (e) => {
    setPrice(e.target.value);
  };
  const handlePrice_b = (e) => {
    setPrice_brief(e.target.value);
  };
  const handleDescription = (e) => {
    setDescription(e.target.value);
  };
  return (
    <Container>
      <Row className="justify-content-center">
        <Col md="3">
          <p>Brief</p>
        </Col>
        <Col md="3"></Col>
      </Row>
      <Row className="justify-content-center ">
        <Col md="3">
          <TextField
            variant="outlined"
            value={title_brief}
            onChange={handleTitle_b}
            label="Title"
            size="small"
            fullWidth
          />
        </Col>
        <Col md="3">
          <TextField
            value={price_brief}
            onChange={handlePrice_b}
            variant="outlined"
            label="Price"
            type="number"
            size="small"
            fullWidth
          />
        </Col>
      </Row>
      <Row className="justify-content-center mb-5">
        <Col md="5"></Col>
        <Col md="1" className="mt-4">
          <Button
            onClick={handleSaveBreif}
            fullWidth
            variant="contained"
            color="secondary"
          >
            Save
          </Button>
        </Col>
      </Row>
      <Divider />
      <Row className="justify-content-center mt-5">
        <Col md="3">
          <p>with Description</p>
        </Col>
        <Col md="3"></Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="3">
          <TextField
            variant="outlined"
            value={title}
            onChange={handleTitle}
            label="Title"
            size="small"
            fullWidth
          />
        </Col>
        <Col md="3">
          <TextField
            value={price}
            onChange={handlePrice}
            variant="outlined"
            label="Price"
            type="number"
            size="small"
            fullWidth
          />
        </Col>
      </Row>
      <Row className="justify-content-center mt-5">
        <Col md="6">
          <TextField
            value={description}
            onChange={handleDescription}
            variant="outlined"
            multiline
            rows={4}
            fullWidth
            label="Description"
          />
        </Col>
      </Row>
      <ImgComp
        loading={!info.is_brief}
        blob={blob}
        setBlob={setBlob}
        link={url}
      />
      <Row className="justify-content-center">
        <Col md="5"></Col>
        <Col md="1" className="mt-4">
          <Button
            onClick={handleSave}
            fullWidth
            variant="contained"
            color="secondary"
          >
            Save
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default PCheckUpEdit;
