import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { makeStyles } from "@material-ui/core/styles";
import DTabel from "./DTabel";
import {
  getListOfDoctor,
  deActiveDoctor,
  switchToDoctor,
  searchDoctor,
} from "../../../Services/admin/doctor";
import { DeleteDoctorsById} from '../../../Services/Hospital/Doctor'
import {
  getDoctors
} from '../../../Services/Hospital/Doctor'
import { Pagination } from "@material-ui/lab";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { useDispatch } from "react-redux";
import {
  Button,
  TextField,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import swal from "sweetalert";
import * as icons from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  Btn: {
    borderRadius: 0,
    color: "#fff",
    backgroundColor: "#6DBCDB",
    "&:hover": {
      backgroundColor: "#0097e6",
    },
  },
}));

const HDoctor = ({ history }) => {
  let id = localStorage.id;
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(1);
  const classes = useStyles();
  const handleAddDoctor = () => {
    const { push } = history;
    push("/HDoctors/Add");
  };
  const initRows = async (id) => {
    // let { data } =
    //   search.trim().length > 0
    //     ? await searchDoctor(search.trim())
    //     : await getListOfDoctor(page);

    let { data } = await getDoctors(id);
    data = data.data;
    const tmp = [];
    data.map((val, idx) => {
      tmp.push({
        id: val._id,
        email: val.email,
        fullname: val.fullname ? val.fullname : "nothing",
        is_active: val.is_active,
      });
    });
    setRows(tmp);
  };
  const handleEdit = (idx) => {
    const { push } = history;
    push({ pathname: "/HDoctors/Edit", state: rows[idx] });
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  const handleSearchEnter = async () => {
    dispatch(showLoading());
    try {
      await initRows(id);
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401) {
        msg = data.msg;
      }
      swal("wrong!", msg, "error");
    } finally {
      dispatch(hideLoading());
    }
  };
  const handleSwitch = async (id) => {
    dispatch(showLoading());
    try {
      let { data } = await switchToDoctor(id);
      let adminJwt = localStorage.getItem("jwtoken");
      localStorage.setItem("AdminJwtoken", adminJwt);
      localStorage.setItem("jwtoken", data.jwtoken);
      window.location.replace("/Profile");
    } catch (error) {
      const { status, data } = error.response;
      console.log(status);
      if (status == 400 || status == 401) {
        swal("wrong!", data.msg, "error");
      } else {
        swal("Error!", "please try agean later!", "error");
      }
    } finally {
      dispatch(hideLoading());
    }
    // const { push } = history;
    // push({ pathname: "/doctorEdit", state: { info: rows[idx] } });
  };
  const handleRemove = async (id) => {
    try {
      dispatch(showLoading());
      console.log(id);
      await DeleteDoctorsById(id);
      await swal("success!", "successfully Deleted!", "success");
      window.history.go("/HDoctors")
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401) {
        msg = data.msg;
      }
      swal("wrong!", msg, "error");
    } finally {
      dispatch(hideLoading());
    }
  };

  const handlePage = async (e, value) => {
    setPage(value);
  };
  useEffect(async () => {
    try {
      dispatch(showLoading());
      
      // console.log(id);
      // let data = await getDoctors(id); 
      await initRows(id);
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401) {
        msg = data.msg;
      }
      swal("wrong!", msg, "error");
    } finally {
      dispatch(hideLoading());
    }
  }, [id]);

  return (
    <>
      <Container>
        <Row className="justify-content-between">
          <Col
            xs={{ order: "last" }}
            md={{ order: "first", span: 3 }}
            className="mt-2"
          >
            <Button
              onClick={handleAddDoctor}
              fullWidth
              variant="contained"
              className={classes.Btn}
            >
              + Add doctor
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="mt-2">
            <DTabel
              rows={rows}
              handleRemove={handleRemove}
              handleEdit={handleEdit}
              handleSwitch={handleSwitch}
            />
          </Col>
        </Row>
        {count > 6 ? (
          <div className="mt-3">
            <Pagination
              count={parseInt((count + 5) / 6)}
              onChange={handlePage}
              page={page}
              style={{ display: "flex", justifyContent: "center" }}
              variant="outlined"
              color="secondary"
            />
          </div>
        ) : null}
      </Container>
    </>
  );
};

export default withRouter(HDoctor);
