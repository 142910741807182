import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Tab, Tabs, Box, Typography } from "@material-ui/core";
import Basic from "./Basic";
import Experience from "./Experience";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Qualifications from "./Qualifications";
import Languages from "./Languages";
import Awards from "./Awards";
import Tags from "./Tags";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  activeTab: {
    color: "#6DBCDB",
  },
  defaultTab: {
    color: "#636e72",
  },
  saveBtn: {
    backgroundColor: "#6DBCDB",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#0984e3",
      color: "#fff",
    },
  },
  addBtn: {
    backgroundColor: "#6DBCDB",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#0984e3",
      color: "#fff",
    },
  },
  deleteBtn: {
    backgroundColor: "#e84118",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#c23616",
      color: "#fff",
    },
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Profile = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col>
            <Paper className={classes.root}>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                variant="scrollable"
                // scrollButtons="auto"
                TabIndicatorProps={{
                  style: { background: "#6DBCDB" },
                }}
                style={{ color: "#6DBCDB" }}
              >
                <Tab
                  label="Basic info"
                  className={
                    value === 0 ? classes.activeTab : classes.defaultTab
                  }
                  {...a11yProps(0)}
                />
                <Tab
                  label="Experience"
                  className={
                    value === 1 ? classes.activeTab : classes.defaultTab
                  }
                  {...a11yProps(1)}
                />
                <Tab
                  label="Qualifications"
                  className={
                    value === 2 ? classes.activeTab : classes.defaultTab
                  }
                  {...a11yProps(2)}
                />
                <Tab
                  label="Languages"
                  className={
                    value === 3 ? classes.activeTab : classes.defaultTab
                  }
                  {...a11yProps(3)}
                />
                <Tab
                  label="Awards"
                  className={
                    value === 4 ? classes.activeTab : classes.defaultTab
                  }
                  {...a11yProps(4)}
                />
                <Tab
                  label="Tags"
                  className={
                    value === 5 ? classes.activeTab : classes.defaultTab
                  }
                  {...a11yProps(5)}
                />
              </Tabs>
            </Paper>
            <TabPanel value={value} index={0}>
              <Basic classes={classes} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Experience classes={classes} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Qualifications classes={classes} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Languages classes={classes} />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <Awards classes={classes} />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <Tags classes={classes} />
            </TabPanel>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profile;

{
  /* <Row>
        <Col xs="8" lg="12">
          <Paper className={classes.root}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              TabIndicatorProps={{
                style: { background: "#6DBCDB" },
              }}
              style={{ color: "#6DBCDB" }}
              centered
            >
              <Tab
                label="Basic info"
                className={value === 0 ? classes.activeTab : classes.defaultTab}
                {...a11yProps(0)}
              />
              <Tab
                label="Experience"
                className={value === 1 ? classes.activeTab : classes.defaultTab}
                {...a11yProps(1)}
              />
              <Tab
                label="Qualifications"
                className={value === 2 ? classes.activeTab : classes.defaultTab}
                {...a11yProps(2)}
              />
              <Tab
                label="Languages"
                className={value === 3 ? classes.activeTab : classes.defaultTab}
                {...a11yProps(3)}
              />
              <Tab
                label="Awards"
                className={value === 4 ? classes.activeTab : classes.defaultTab}
                {...a11yProps(4)}
              />
              <Tab
                label="Diseases"
                className={value === 5 ? classes.activeTab : classes.defaultTab}
                {...a11yProps(5)}
              />
            </Tabs>
          </Paper>

          <TabPanel value={value} index={0}>
            <Basic classes={classes} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Experience classes={classes} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Qualifications classes={classes} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Languages classes={classes} />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <Awards classes={classes} />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <Diseases classes={classes} />
          </TabPanel>
        </Col>
      </Row> */
}
