import { useEffect, useState } from "react";
import {
  Button,
  TextField,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { makeStyles } from "@material-ui/core/styles";
import { Col, Row, Container } from "react-bootstrap";
import * as icons from "@material-ui/icons";
import { useDispatch } from "react-redux";
import swal from "sweetalert";
import LTabel from "./LabTabel";
import { getLabs, deleteLab, searchLab, switchToLab } from "../../Services/Lab";

const useStyles = makeStyles((theme) => ({
  Btn: {
    borderRadius: 0,
    color: "#fff",
    backgroundColor: "#6DBCDB",
    "&:hover": {
      backgroundColor: "#0097e6",
    },
  },
}));
const Lab = ({ history }) => {
  const classes = useStyles();
  const { push } = history;
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [userList, setUserList] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const dispatch = useDispatch();
  const handleSearchEnter = async () => {
    dispatch(showLoading());
    try {
      fetching(1);
      setPage(1);
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401) {
        msg = data.msg;
      }
      swal("wrong!", msg, "error");
    } finally {
      dispatch(hideLoading());
    }
  };
  const handleRemove = async (id, name) => {
    let isSure = await swal({
      title: "Are you sure?",
      text: `Are you sure to delete ${name}!`,
      icon: "warning",
      buttons: ["No", "Yes"],
      dangerMode: true,
    });
    if (isSure) {
      try {
        dispatch(showLoading());
        await deleteLab(id);
        swal("success!", "successfully Laboratory deleted!", "success");
        if (page == 1) await fetching(1);
        else setPage(1);
      } catch (error) {
        let msg = "some error occured";
        let { status, data } = error.response;
        if (status == 400 || status == 401) {
          msg = data.msg;
        }
        swal("wrong!", msg, "error");
      } finally {
        dispatch(hideLoading());
      }
    }
  };
  const handleEdit = (idx) => {
    push({ pathname: "/labEdit", state: { ...userList[idx] } });
  };
  const handleAddLab = () => {
    push("/labAdd");
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  const handleCheckUp = (data) => {
    push({ pathname: "/labCheckUp", state: { ...data } });
  };

  const handleSwitch = async (id, idx) => {
    dispatch(showLoading());
    try {
      let { data } = await switchToLab(id);
      let adminJwt = localStorage.getItem("jwtoken");
      localStorage.setItem("AdminJwtoken", adminJwt);
      localStorage.setItem("jwtoken", data.jwtoken);
      await localStorage.setItem("id", data.id)
      const { push } = history;
      push({ pathname: "/LabDashboard" , state: userList[idx]});
    } catch (error) {
      const { status, data } = error.response;
      if (status == 400 || status == 401) {
        swal("wrong!", data.msg, "error");
      } else {
        swal("Error!", "please try agean later!", "error");
      }
    } finally {
      dispatch(hideLoading());
    }
    // const { push } = history;
    // push({ pathname: "/doctorEdit", state: { info: rows[idx] } });
  };

  const fetching = async (page) => {
    try {
      dispatch(showLoading());
      let { data } =
        search.trim().length > 0
          ? await searchLab(search) :
          await getLabs(page, search);
      setUserList(data.data);
      setTotalPage(data.count);
    } catch (error) {
      swal("wrong!", error.message, "error");
    } finally {
      dispatch(hideLoading());
    }
  };
  useEffect(() => {
    fetching(1);
  }, []);
  const handlePagination = (e, p) => {
    setPage(p);
    fetching(p);
  };
  return (
    <Container>
      <Row className="justify-content-between">
        <Col md="3" className="mt-2">
          <Button
            onClick={handleAddLab}
            fullWidth
            variant="contained"
            className={classes.Btn}
          >
            + Add Laboratory
          </Button>
        </Col>
        <Col md="4" className="mt-2">
          <TextField
            fullWidth
            size="small"
            id="search"
            label="Search by ID"
            variant="outlined"
            value={search}
            onChange={handleSearch}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleSearchEnter();
            }}
            className={classes.textField}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton onClick={handleSearchEnter}>
                    <icons.Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <LTabel
            rows={userList}
            handleEdit={handleEdit}
            handleRemove={handleRemove}
            handleCheckUp={handleCheckUp}
            handleSwitch={handleSwitch}
          />
        </Col>
      </Row>
      <div className="mt-3 text-center">
        <Pagination
          count={parseInt((totalPage + 5) / 6)}
          style={{ display: "inline-block" }}
          onChange={handlePagination}
          variant="outlined"
          color="secondary"
        />
      </div>
    </Container>
  );
};

export default Lab;
