import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { makeStyles } from "@material-ui/core/styles";
import HTabel from "./HTabel";
import {
  getAllHospitals,
  delHospital,
  searchHospital,
} from "../../Services/admin/Hospital";
import {switchToHospital} from '../../Services/admin/Hospital'
import { Pagination } from "@material-ui/lab";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { useDispatch } from "react-redux";
import {
  Button,
  TextField,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import swal from "sweetalert";
import * as icons from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  Btn: {
    borderRadius: 0,
    color: "#fff",
    backgroundColor: "#6DBCDB",
    "&:hover": {
      backgroundColor: "#0097e6",
    },
  },
}));

const Hospital = ({ history }) => {
  const dispatch = useDispatch();
  const { push } = history;
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(1);
  const classes = useStyles();
  const handleAddHospital = () => {
    const { push } = history;
    push("/hospitalAdd");
  };
  const initRows = async (page) => {
    let { data } =
      search.trim().length > 0
        ? await searchHospital(search)
        : await getAllHospitals(page);
    setCount(data.count);
    data = data.data;
    const tmp = [];
    data.map((val, idx) => {
      tmp.push({
        id: val._id,
        address: val.address,
        name: val.name ? val.name : "nothing",
        description: val.description,
        times: val.times,
        ID: val.ID ? val.ID : 0,
      });
    });
    setRows(tmp);
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  const handleCheckUp = (data) => {
    push({ pathname: "/HCheckUp", state: { ...data } });
  }
  const handleSearchEnter = async () => {
    dispatch(showLoading());
    try {
      await initRows(page);
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401) {
        msg = data.msg;
      }
      swal("wrong!", msg, "error");
    } finally {
      dispatch(hideLoading());
    }
  };
  const handleEdit = (idx) => {
    const { push } = history;
    push({ pathname: "/hospitalEdit", state: { info: rows[idx] } });
  };
  const handleRemove = async (id, name) => {
    let isSure = await swal({
      title: "Are you sure?",
      text: `Are you sure to delete ${name}!`,
      icon: "warning",
      buttons: ["No", "Yes"],
      dangerMode: true,
    });
    if (isSure) {
      try {
        dispatch(showLoading());
        await delHospital(id);
        swal("success!", "successfully deleted!", "success");
        if (page == 1) await initRows(1);
        else setPage(1);
      } catch (error) {
        let msg = "some error occured";
        let { status, data } = error.response;
        if (status == 400 || status == 401) {
          msg = data.msg;
        }
        swal("wrong!", msg, "error");
      } finally {
        dispatch(hideLoading());
      }
    }
  };
  
  const handleSwitch = async (id,idx) => {
    dispatch(showLoading());
    try {
      let { data } = await switchToHospital(id);
      let adminJwt = localStorage.getItem("jwtoken");
      localStorage.setItem("AdminJwtoken", adminJwt);
      localStorage.setItem("jwtoken", data.jwtoken);
      await localStorage.setItem("id", data.id)
      const { push } = history;
      push({ pathname: "/hProfile", state: rows[idx] });
    } catch (error) {
      const { status, data } = error.response;
      if (status == 400 || status == 401) {
        swal("wrong!", data.msg, "error");
      } else {
        swal("Error!", "please try agean later!", "error");
      }
    } finally {
      dispatch(hideLoading());
    }
    // const { push } = history;
    // push({ pathname: "/doctorEdit", state: { info: rows[idx] } });
  };
  const handlePage = async (e, value) => {
    setPage(value);
  };
  useEffect(async () => {
    try {
      dispatch(showLoading());
      await initRows(page);
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401) {
        msg = data.msg;
      }
      swal("wrong!", msg, "error");
    } finally {
      dispatch(hideLoading());
    }
  }, [page]);

  return (
    <>
      <Container>
        <Row className="justify-content-between">
          <Col
            xs={{ order: "last" }}
            md={{ order: "first", span: 3 }}
            className="mt-2"
          >
            <Button
              onClick={handleAddHospital}
              fullWidth
              variant="contained"
              className={classes.Btn}
            >
              + Add Hospital
            </Button>
          </Col>
          <Col
            xs={{ order: "first", span: 12 }}
            md={{ order: "last", span: 3 }}
            className="mt-2"
          >
            <TextField
              fullWidth
              size="small"
              id="search"
              label="Search by ID"
              variant="outlined"
              value={search}
              onChange={handleSearch}
              onKeyDown={(e) => {
                if (e.key === "Enter") handleSearchEnter();
              }}
              className={classes.textField}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton onClick={handleSearchEnter}>
                      <icons.Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="mt-2">
            <HTabel
              rows={rows}
              handleRemove={handleRemove}
              handleEdit={handleEdit}
              handleCheckUp={handleCheckUp}
              handleSwitch={handleSwitch}
            />
          </Col>
        </Row>
        {count > 6 ? (
          <div className="mt-3">
            <Pagination
              count={parseInt((count + 5) / 6)}
              onChange={handlePage}
              page={page}
              style={{ display: "flex", justifyContent: "center" }}
              variant="outlined"
              color="secondary"
            />
          </div>
        ) : null}
      </Container>
    </>
  );
};

export default withRouter(Hospital);
