import React, { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";

import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Button,
} from "@material-ui/core";

import * as icons from "@material-ui/icons";
import { withRouter } from "react-router-dom";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const ATabel = ({ rows ,handleRemove,handleEdit}) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>ID</StyledTableCell>
            <StyledTableCell align="left">Patient name</StyledTableCell>
            <StyledTableCell align="left">Phone</StyledTableCell>
            <StyledTableCell align="left">Date</StyledTableCell>
            <StyledTableCell align="left"></StyledTableCell>
            {/*<StyledTableCell align="right">Visit reason</StyledTableCell>
            <StyledTableCell align="right">Status</StyledTableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
        {rows.map((row,idx) => (
            <StyledTableRow key={idx}>
              <StyledTableCell component="th" scope="row">
                {row.id}
              </StyledTableCell>
              <StyledTableCell align="left">{row.name}</StyledTableCell>
              <StyledTableCell align="left">{row.phone}</StyledTableCell>
              <StyledTableCell align="left">{row.date}</StyledTableCell>
              <StyledTableCell align="right">
              <div>
                    <Button
                      className="p-0"
                      onClick={() => {
                        handleRemove(row._id,row.name);
                      }}
                    >
                      <icons.DeleteOutline className="text-danger" />
                    </Button>
                    <Button
                      className="p-0"
                      onClick={() => {
                        handleEdit(idx);
                      }}
                    >
                      <icons.EditOutlined style={{ color: "#0abde3" }} />
                    </Button>
                  </div>
              </StyledTableCell>
              {/* <StyledTableCell align="right">{row.reason}</StyledTableCell>
              <StyledTableCell align="right">{row.status}</StyledTableCell> */}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default withRouter(ATabel);
