import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
const MapComponent = ({ google, lat, lng, handleMap }) => {
  return (
    <div
      style={{
        height: "300px",
      }}
    >
      <Map
        google={google}
        zoom={14}
        style={{
          width: "96%",
          height: "100%",
        }}
        initialCenter={{ lat, lng }}
        onClick={handleMap}
      >
        <Marker position={{ lat, lng }} />
      </Map>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyAVUbtrhKdhW0g9imSAwXKNS4paUuZ9zLs",
})(MapComponent);
