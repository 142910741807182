import React, { useEffect, useState } from "react";
import { TextField, Button, Chip, Divider, Checkbox, FormControl, OutlinedInput, InputAdornment, IconButton } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import * as icons from "@material-ui/icons";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import { Row, Col, Container } from "react-bootstrap";
import DateFnsUtils from "@date-io/date-fns";
import Map from "../utils/Map";
import { getCities } from "../../Services/doctor/profile";
import ImgComp from "../utils/ImageComp";
import { updatePharmacy, uploadImg, getPharmacyInfo } from "../../Services/Pharmacy";
import swal from "sweetalert";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { useDispatch } from "react-redux";
import { root } from "../../Services/config.json";


const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};


const TimeComp = (label, time, setTime, disabled) => {
  const handleTime = (e) => {
    setTime(e);
  };
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardTimePicker
        margin="normal"
        id="time-picker"
        label={label}
        value={time}
        disabled={disabled}
        onChange={handleTime}
        KeyboardButtonProps={{
          "aria-label": "change time",
        }}
      />
    </MuiPickersUtilsProvider>
  );
};
const Profile = ({ location, history }) => {
  let date = new Date(1627155000000);
  const info = location.state;
  let id;
  if (info) {
    id = info.id;
  }
  else {
    id = localStorage.getItem("id");

  }
  const dispatch = useDispatch();
  const [blob, setBlob] = useState(null);
  const [_id, set_Id] = useState(null)
  const [name, setName] = useState(info ? info.name : "");
  const [city, setCity] = useState(info ? info.city : "");
  const [defCity, setDefCity] = useState(info ? info.defCity : "");
  const [value, setValue] = useState(info ? info.phone : "");
  const [currPhone, setCurrPhone] = useState("");
  const [citiesList, setCitiesList] = useState([]);
  const [address, setAddress] = useState(info ? info.address : "");
  const [url, setUrl] = useState(info ? `${root}${info.photo}` : "");
  const [ID, setID] = useState(info ? info.ID : 0);
  const [Pemail, setPEmail] = useState(info ? info.email : "");
  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  const [lng, setLng] = useState(info ? info.geometry.coordinates[0] : 69.2075);
  const [lat, setLat] = useState(info ? info.geometry.coordinates[1] : 34.5553);
  let sDate = new Date(1627241340000);
  let eDate = new Date(1627155000000);
  let _24tmp = [0, 0, 0, 0, 0, 0, 0];
  if (info) {
    for (let x of info._24Hours) {
      _24tmp[x] = true;
    }
  }
  const [A, setA] = useState(info ? _24tmp[6] : true);
  const [B, setB] = useState(info ? _24tmp[0] : true);
  const [C, setC] = useState(info ? _24tmp[1] : true);
  const [D, setD] = useState(info ? _24tmp[2] : true);
  const [E, setE] = useState(info ? _24tmp[3] : true);
  const [F, setF] = useState(info ? _24tmp[4] : true);
  const [G, setG] = useState(info ? _24tmp[5] : true);

  const [day_0_s, setDay_0_s] = useState(info ? info.times[0][0] : date);
  const [day_1_s, setDay_1_s] = useState(info ? info.times[1][0] : date);
  const [day_2_s, setDay_2_s] = useState(info ? info.times[2][0] : date);
  const [day_3_s, setDay_3_s] = useState(info ? info.times[3][0] : date);
  const [day_4_s, setDay_4_s] = useState(info ? info.times[4][0] : date);
  const [day_5_s, setDay_5_s] = useState(info ? info.times[5][0] : date);
  const [day_6_s, setDay_6_s] = useState(info ? info.times[6][0] : date);

  const [day_0_e, setDay_0_e] = useState(info ? info.times[0][1] : date);
  const [day_1_e, setDay_1_e] = useState(info ? info.times[1][1] : date);
  const [day_2_e, setDay_2_e] = useState(info ? info.times[2][1] : date);
  const [day_3_e, setDay_3_e] = useState(info ? info.times[3][1] : date);
  const [day_4_e, setDay_4_e] = useState(info ? info.times[4][1] : date);
  const [day_5_e, setDay_5_e] = useState(info ? info.times[5][1] : date);
  const [day_6_e, setDay_6_e] = useState(info ? info.times[6][1] : date);

  const [load, setLoad] = useState(false);

  //////////////////////////////
  const handleCheck = (start, end, check, setCheck) => {
    if (!check) {
      start(sDate);
      end(eDate);
    }
    setCheck(!check);
  };

  const handleMap = (mapProps, map, e) => {
    setLat(e.latLng.lat());
    setLng(e.latLng.lng());
  };

  const handleID = (e) => {
    setID(e.target.value);
  };

  const initRows = async () => {
    {
      let { data } = await getPharmacyInfo(id);
      let pharmacy = data.data
      localStorage.setItem("name", pharmacy.name);
      localStorage.setItem("photo", pharmacy.photo)
      set_Id(pharmacy._id)
      setName(pharmacy.name)
      setCity(pharmacy.city)
      setValue(pharmacy.phone)
      setAddress(pharmacy.address)
      setUrl(`${root}${pharmacy.photo}`)
      setID(pharmacy.ID)
      setPEmail(pharmacy.email)
      setLng(pharmacy.geometry.coordinates[0])
      setLat(pharmacy.geometry.coordinates[1])
      for (let x of pharmacy._24Hours) {
        _24tmp[x] = true;
      }
      setA(_24tmp[6])
      setB(_24tmp[0])
      setC(_24tmp[1])
      setD(_24tmp[2])
      setE(_24tmp[3])
      setF(_24tmp[4])
      setG(_24tmp[5])
      setDay_0_s(pharmacy.times[0][0])
      setDay_1_s(pharmacy.times[1][0])
      setDay_2_s(pharmacy.times[2][0])
      setDay_3_s(pharmacy.times[3][0])
      setDay_4_s(pharmacy.times[4][0])
      setDay_5_s(pharmacy.times[5][0])
      setDay_6_s(pharmacy.times[6][0])
      setDay_0_e(pharmacy.times[0][1])
      setDay_1_e(pharmacy.times[1][1])
      setDay_2_e(pharmacy.times[2][1])
      setDay_3_e(pharmacy.times[3][1])
      setDay_4_e(pharmacy.times[4][1])
      setDay_5_e(pharmacy.times[5][1])
      setDay_6_e(pharmacy.times[6][1])
      let cities = await getCities();
      let x = cities.data.data.filter((item) => item._id == pharmacy.city)[0];
      setDefCity(x);
      setCitiesList(cities.data.data);
      setLoad(true)
    }
  }


  const handleName = (e) => {
    setName(e.target.value);
  };
  const emailHandle = (e) => {
    setPEmail(e.target.value);
  };
  const handleAddress = (e) => {
    setAddress(e.target.value);
  };
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleCity = (e, item) => {
    setCity(item ? item._id : "");
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleCurrPhone = (e) => {
    setCurrPhone(e.target.value);
  };

  useEffect(async () => {
    try {
      dispatch(showLoading());
      if(info){
        setLoad(true)
      const call = async () => {
        let { data } = await getCities();
        let x = data.data.filter((item) => item._id == info.city)[0];
        setDefCity(x);
        setCitiesList(data.data);
      };
      call();
    }
    else{
      await initRows()
    }

        
      

    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401) {
        msg = data.msg;
      }
      swal("wrong!", msg, "error");
    } finally {
      dispatch(hideLoading());
    }
  }, []);
  // const handleSave = async () => {
  //   if (validateEmail(Pemail)) {
  //   if (
  //     city.length > 0 &&
  //     value.length &&
  //     name.length > 0 &&
  //     address.length > 0
  //   ) {
  //     try {
  //       dispatch(showLoading());
  //       _24tmp = [B, C, D, E, F, G, A];
  //       let _24hours = [];
  //       for (let i in _24tmp) {
  //         if (_24tmp[i]) _24hours.push(i);
  //       }

  //       let body = {
  //         id: info ? info._id : _id,
  //         name: name,
  //         phone: value,
  //         city: city,
  //         address,
  //         geometry: { coordinates: [lng, lat] },
  //         times: [
  //           [day_0_s, day_0_e],
  //           [day_1_s, day_1_e],
  //           [day_2_s, day_2_e],
  //           [day_3_s, day_3_e],
  //           [day_4_s, day_4_e],
  //           [day_5_s, day_5_e],
  //           [day_6_s, day_6_e],
  //         ],
  //         _24Hours: _24hours,
  //         ID: ID,
  //         email: Pemail,
  //       }
  //       if (values.password.length > 0) {
  //         body["password"] = values.password;
  //       }
  //       let { data } = await updatePharmacy(body);
  //       let id = info ? info._id : _id;
  //       if (blob) {
  //         const fd = new FormData();
  //         fd.append("img", blob, "jack");
  //         await uploadImg(id, fd);
  //       }
  //       swal("success!", "successfully pharmacy updated!", "success");
  //       history.go("/PharmacyDashboard");
  //     } catch (error) {
  //       swal("error", error.message, "error");
  //     } finally {
  //       dispatch(hideLoading());
  //     }
  //   } else {
  //     swal("error", "please fill all filds.", "error");
  //   }
  // }
  // else{
  //   swal("wrong!", "Email is invalid.", "error");
  // }
  // };

  return (
    <>
      {load ?
        <Container>
          <Row className="justify-content-center">
            <Col md="3" className="mt-2">
              <TextField
                size="small"
                fullWidth
                id="ID"
                label="ID"
                value={ID}
                inputProps={
                  { readOnly: true, }}
                onChange={handleID}
                variant="outlined"
              />

            </Col>
            <Col md="3" className="mt-2">

            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="3" className="mt-2">
              <TextField
                variant="outlined"
                value={name}
                onChange={handleName}
                inputProps={
                  { readOnly: true, }}
                size="small"
                fullWidth
                label="Name"
              />
            </Col>
            <Col md="3" className="mt-2">
              <Autocomplete
                options={citiesList}
                defaultValue={defCity}
                getOptionLabel={(option) => option.e_name}
                onChange={()=>{}}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="City"
                    variant="outlined"
                  />
                )}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="3" className="mt-2">
              <TextField
                size="small"
                fullWidth
                id="email"
                label="Email Address"
                value={Pemail}
                onChange={()=>{}}
                inputProps={
                  { readOnly: true, }}
                variant="outlined"
              />
            </Col>
            <Col md="3" className="mt-2">
              <FormControl fullWidth size="small" variant="outlined">
                <OutlinedInput
                  placeholder="Password"
                  id="outlined-adornment-password"
                  type={values.showPassword ? "text" : "password"}
                  value={values.password}
                  onChange={handleChange("password")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={()=>{}}
                        onMouseDown={()=> {}}
                        edge="end"
                      >
                        {values.showPassword ? (
                          <icons.Visibility />
                        ) : (
                          <icons.VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="6" className="mt-2">
              <Autocomplete
                multiple
                options={[currPhone]}
                getOptionLabel={(option) => option}
                value={value}
                onChange={(event, newValue) => {
                  setValue([...newValue]);
                }}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip label={option} {...getTagProps({ index })} />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="Phone"
                    onChange={()=>{}}
                    variant="outlined"
                    inputProps={
                      { readOnly: true, }}
                  />
                )}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="6" className="mt-2">
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label="Address"
                value={address}
                onChange={handleAddress}
                  inputProps={
                    { readOnly: true, }}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="6" className="mt-2">
              <Map lat={lat} lng={lng} handleMap={handleMap} />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="1" className="mt-5">
              <h6>Saturday</h6>
            </Col>
            <Col md="2" className="mt-2">
              {TimeComp("Start", day_0_s, setDay_0_s, A)}
            </Col>
            <Col md="2" className="mt-2">
              {TimeComp("End", day_0_e, setDay_0_e, A)}
            </Col>
            <Col md="1" className="mt-4">
              <p>
                <sub>24 Hours</sub>
                <Checkbox
                  checked={A}
                  onChange={() => {
                    handleCheck(setDay_0_s, setDay_0_e, A, setA);
                  }}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </p>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col md="1" className="mt-5">
              <h6>Sunday</h6>
            </Col>
            <Col md="2" className="mt-2">
              {TimeComp("Start", day_1_s, setDay_1_s, B)}
            </Col>
            <Col md="2" className="mt-2">
              {TimeComp("End", day_1_e, setDay_1_e, B)}
            </Col>
            <Col md="1" className="mt-4">
              <p>
                <sub>24 Hours</sub>
                <Checkbox
                  checked={B}
                  onChange={() => {
                    handleCheck(setDay_1_s, setDay_1_e, B, setB);
                  }}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </p>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col md="1" className="mt-5">
              <h6>Monday</h6>
            </Col>
            <Col md="2" className="mt-2">
              {TimeComp("Start", day_2_s, setDay_2_s, C)}
            </Col>
            <Col md="2" className="mt-2">
              {TimeComp("End", day_2_e, setDay_2_e, C)}
            </Col>
            <Col md="1" className="mt-4">
              <p>
                <sub>24 Hours</sub>
                <Checkbox
                  checked={C}
                  onChange={() => {
                    handleCheck(setDay_2_s, setDay_2_e, C, setC);
                  }}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </p>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col md="1" className="mt-5">
              <h6>Tuesday</h6>
            </Col>
            <Col md="2" className="mt-2">
              {TimeComp("Start", day_3_s, setDay_3_s, D)}
            </Col>
            <Col md="2" className="mt-2">
              {TimeComp("End", day_3_e, setDay_3_e, D)}
            </Col>
            <Col md="1" className="mt-4">
              <p>
                <sub>24 Hours</sub>
                <Checkbox
                  checked={D}
                  onChange={() => {
                    handleCheck(setDay_3_s, setDay_3_e, D, setD);
                  }}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </p>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col md="1" className="mt-5">
              <h6>Wednesday</h6>
            </Col>
            <Col md="2" className="mt-2">
              {TimeComp("Start", day_4_s, setDay_4_s, E)}
            </Col>
            <Col md="2" className="mt-2">
              {TimeComp("End", day_4_e, setDay_4_e, E)}
            </Col>
            <Col md="1" className="mt-4">
              <p>
                <sub>24 Hours</sub>
                <Checkbox
                  checked={E}
                  onChange={() => {
                    handleCheck(setDay_4_s, setDay_4_e, E, setE);
                  }}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </p>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col md="1" className="mt-5">
              <h6>Thursday</h6>
            </Col>
            <Col md="2" className="mt-2">
              {TimeComp("Start", day_5_s, setDay_5_s, F)}
            </Col>
            <Col md="2" className="mt-2">
              {TimeComp("End", day_5_e, setDay_5_e, F)}
            </Col>
            <Col md="1" className="mt-4">
              <p>
                <sub>24 Hours</sub>
                <Checkbox
                  checked={F}
                  onChange={() => {
                    handleCheck(setDay_5_s, setDay_5_e, F, setF);
                  }}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </p>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col md="1" className="mt-5">
              <h6>Friday</h6>
            </Col>
            <Col md="2" className="mt-2">
              {TimeComp("Start", day_6_s, setDay_6_s, G)}
            </Col>
            <Col md="2" className="mt-2">
              {TimeComp("End", day_6_e, setDay_6_e, G)}
            </Col>
            <Col md="1" className="mt-4">
              <p>
                <sub>24 Hours</sub>
                <Checkbox
                  checked={G}
                  onChange={() => {
                    handleCheck(setDay_6_s, setDay_6_e, G, setG);
                  }}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </p>
            </Col>
          </Row>

          <ImgComp loading={true} blob={blob} setBlob={setBlob} link={url} />
          {/* <Row className="justify-content-center">
            <Col md="5"></Col>
            <Col md="1" className="mt-4">
              <Button
                onClick={handleSave}
                fullWidth
                variant="contained"
                color="secondary"
              >
                Save
              </Button>
            </Col>
          </Row> */}
        </Container> : ""}

    </>
  );
};

export default Profile;
