import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import * as icons from "@material-ui/icons";
import { getCities, getCategories } from "../../Services/Hospital/hospital";
import "bootstrap/dist/css/bootstrap.min.css";
import { getHospitalInfo, ProfileUpload, editHospital } from '../../Services/Hospital/hospital';
import Map from "../utils/Map";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { root } from "../../Services/config.json";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { useDispatch } from "react-redux";
import { switchToDoctor } from '../../Services/admin/doctor'
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Dialog,
  DialogContent,
  DialogActions,
  CircularProgress,
  Typography,
  Card,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import swal from "sweetalert";
import ImageCropper from "./ImageCropper";
import StarRatings from 'react-star-ratings';

const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};


const Basic = ({ history, location, state }) => {
  let date = new Date(1627155000000);
  const dispatch = useDispatch();
  let id;
  if(state){
    id = state.id;
  }
  else{
    id = localStorage.getItem("id");
  }
  const [address, setAddress] = useState("");
  const [name, setName] = useState("");
  const [load, setLoad] = useState(false);
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState(0);
  const [tag, setTag] = useState("");
  const [cityId, setCityId] = useState(null);
  const [cities, setCities] = useState([]);
  const [category, setCategory] = useState("");
  const [blob, setBlob] = useState(null);
  const [inputImg, setInputImg] = useState("");
  const [url, setUrl] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedCat, setSelectedCat] = useState([]);
  const [tags, setTags] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [lng, setLng] = useState(69.2075);
  const [lat, setLat] = useState(34.5553);
  const [day_0_s, setDay_0_s] = useState(state ? state.times.length > 0 ? state.times[0][0] : date: date);
  const [day_0_e, setDay_0_e] = useState(state ? state.times.length > 0 ? state.times[0][1] : date: date);
  const [description, setDiscription] = useState(state ? state.description: "");
  const [Hemail, setHEmail] = useState("");
  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  const [showCropper, setShowCropper] = useState(false);
  const imgs = useRef();
  const handleAddress = (e) => {
    setAddress(e.target.value);
  };
  const handleName = (e) => {
    setName(e.target.value);
  };
  const handlePhone = (e) => {
    setPhone(e.target.value);
  };
  const emailHandle = (e) => {
    setHEmail(e.target.value);
  };
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const TimeComp = (label, time, setTime) => {
    const handleTime = (e) => {
      setTime(e);
    };

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardTimePicker
          margin="normal"
          id="time-picker"
          label={label}
          value={time}
          onChange={handleTime}
          KeyboardButtonProps={{
            "aria-label": "change time",
          }}
        />
      </MuiPickersUtilsProvider>
    );
  };

  const initRows = async () => {
    {
      let { data } = await getCities();
      data = data.data;
      setCities(data);
    }
    {
      let { data } = await getCategories();
      data = data.data;
      setCategories(data);
    }
    {
      let { data } = await getHospitalInfo(id);
      localStorage.setItem("name", data.data.name);
      localStorage.setItem("photo", data.data.photo)
      setHEmail(data.data.email);
      setSelectedCat(data.cats);
      setTags(data.data.tags);
      setName(data.data.name);
      setPhone(data.data.phone);
      setAddress(data.data.address);
      setUrl(`${root}${data.data.photo}`);
      setCityId(data.data.city);
      if (data.data.geometry.coordinates) {
        setLng(data.data.geometry.coordinates[0]);
        setLat(data.data.geometry.coordinates[1]);
      }
      if(!state){
        if(data.data.description){
          setDiscription(data.data.description)
        }
        else{
          setDiscription("")
        }
        if(data.data.times.length > 0){
          setDay_0_s(data.data.times[0][0]);
          setDay_0_e(data.data.times[0][1]);
        }
      }

    }
  }

  useEffect(async () => {
    try {
      dispatch(showLoading());
      await initRows()

    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401) {
        msg = data.msg;
      }
      swal("wrong!", msg, "error");
    } finally {
      dispatch(hideLoading());
    }
  }, []);

  const handleMap = (mapProps, map, e) => {
    setLat(e.latLng.lat());
    setLng(e.latLng.lng());
  };
  const handleDetail = (e) => {
    setDiscription(e.target.value);
  };
  const handleCity = (e) => {
    setCity(e.target.value);
  };
  const handleCategory = (e) => {
    setCategory(e.target.value);
  };

  const handleSave = async () => {
    if (validateEmail(Hemail)) {
      if (
        address.length > 0 &&
        name.length > 0 &&
        phone.length > 0 &&
        selectedCat.length > 0 &&
        tags.length > 0 &&
        description.length > 0 &&
        day_0_s &&
        day_0_e &&
        Hemail
      ) {
        try {
          dispatch(showLoading());

          //   let docs = [];
          //   doctors.map((item) => {
          //     docs.push({
          //       _id: item._id,
          //       fullname: item.fullname,
          //       photo: item.photo,
          //       Edu: item.Edu,
          //       Exp: item.Exp,
          //       stars: item.stars,
          //       speciality: item.speciality,
          //     })

          //   });
          let cats = [];
          selectedCat.map((item) => {
            cats.push(item.id);
          });
          const body = {
            address,
            name,
            categories: cats,
            tags,
            city: cities[city]._id,
            phone,
            geometry: {
              coordinates: [lng, lat],
            },
            description: description,
            times: [[day_0_s, day_0_e]],
            email: Hemail,
          }
          if (values.password.length > 0) {
            body["password"] = values.password;
          }
          await editHospital(id, body);
          if (blob) {
            const fd = new FormData();
            fd.append("img", blob, "jack");
            await ProfileUpload(id, fd);
          }
          swal("success!", "successfully hospital updated!", "success");
        } catch (error) {
          let msg = "some error occured";
          let { status, data } = error.response;
          if (status == 400 || status == 401) {
            msg = data.msg;
          }
          swal("wrong!", msg, "error");
        } finally {
          dispatch(hideLoading());
        }
      } else {
        swal("wrong!", "please fill all field!", "error");
      }
    }
    else {
      swal("wrong!", "Email is invalid.", "error");
    }
  };
  useEffect(() => {
    if (cities.length > 0 && cityId != null) {
      let idx = cities.findIndex((item) => item._id == cityId);
      if (idx == -1) idx = 0;
      setCity(idx);
    }
  }, [cityId, cities]);

  const handleCategoryAdd = () => {
    let tmp = [...selectedCat];
    const x = categories[category];
    tmp.push({ id: x._id, e_title: x.e_title });
    setSelectedCat(tmp);
  };

  const handleCatDel = (id) => {
    let tmp = selectedCat.filter((obj) => obj.id != id);
    setSelectedCat(tmp);
  };


  const handleTag = (e) => {
    setTag(e.target.value);
  };

  const handleTags = () => {
    let tmp = [...tags];
    tmp.push(tag);
    setTags(tmp);
  };

  const handleTagDel = (idx) => {
    let tmp = [...tags];
    tmp.splice(idx, 1);
    setTags(tmp);
  };



  const getBlob = (blob) => {
    // pass blob up from the ImageCropper component
    setBlob(blob);
  };

  const onInputChange = (e) => {
    // convert image file to base64 string
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      () => {
        setInputImg(reader.result);
        setShowCropper(true);
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const handleCrop = async () => {
    let reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => {
      setUrl(reader.result);
      setLoad(true);
    };
    setShowCropper(false);
  };
  const handleImgLoad = () => {
    if (!load) setLoad(true);
  };

  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col md="6" className="mt-2">
            <TextField
              size="small"
              fullWidth
              id="name"
              label="Name"
              value={name}
              onChange={handleName}
              variant="outlined"
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="3" className="mt-2">
            <TextField
              size="small"
              fullWidth
              id="email"
              label="Email Address"
              value={Hemail}
              onChange={emailHandle}
              variant="outlined"
            />
          </Col>
          <Col md="3" className="mt-2">
            <FormControl fullWidth size="small" variant="outlined">
              <OutlinedInput
                placeholder="Password"
                id="outlined-adornment-password"
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChange("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? (
                        <icons.Visibility />
                      ) : (
                        <icons.VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="3" className="mt-2">
            <TextField
              size="small"
              fullWidth
              id="address"
              label="Address"
              value={address}
              onChange={handleAddress}
              variant="outlined"
            />
          </Col>
          <Col md="3" className="mt-2">
            <TextField
              size="small"
              fullWidth
              id="phone"
              label="Phone"
              value={phone}
              onChange={handlePhone}
              inputProps={{ maxLength: 10 }}
              variant="outlined"
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="6" className="mt-2">
            <FormControl fullWidth>
              <InputLabel>City</InputLabel>
              <Select onChange={handleCity} value={city} label="city">
                {cities.map((obj, idx) => (
                  <MenuItem key={idx} value={idx}>
                    {obj.e_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="6" className="mt-2">
            <Map lat={lat} lng={lng} handleMap={handleMap} />
          </Col>
        </Row>
        <Row className="justify-content-center mt-2">
          <Col md="5" className="mt-2">
            <FormControl fullWidth>
              <InputLabel>Category</InputLabel>
              <Select
                onChange={handleCategory}
                value={category}
                label="category"
              >
                {categories.map((obj, idx) => (
                  <MenuItem key={idx} value={idx}>
                    {obj.e_title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Col>
          <Col md="1" className="mt-4">
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCategoryAdd}
            >
              ADD
            </Button>
          </Col>
        </Row>

        {selectedCat.map((obj, idx) => (
          <Row key={idx} className="justify-content-center mt-2">
            <Col md="5" className="mt-2">
              <TextField
                size="small"
                fullWidth
                value={obj.e_title}
                disabled
                onChange={handleName}
                variant="outlined"
              />
            </Col>
            <Col md="1" className="mt-2">
              <Button
                onClick={() => {
                  handleCatDel(obj.id);
                }}
              >
                <icons.DeleteOutline style={{ color: "red" }} />
              </Button>
            </Col>
          </Row>
        ))}
        <Row className="justify-content-center mt-2">
          <Col md="5" className="mt-2">
            <TextField
              size="small"
              fullWidth
              id="tag"
              label="Tag"
              value={tag}
              onChange={handleTag}
              variant="outlined"
            />
          </Col>
          <Col md="1" className="mt-2">
            <Button variant="contained" color="secondary" onClick={handleTags}>
              ADD
            </Button>
          </Col>
        </Row>
        {tags.map((item, idx) => (
          <span key={idx}>
            <Row className="justify-content-center mt-2">
              <Col md="5" className="mt-2">
                <TextField
                  size="small"
                  fullWidth
                  value={item}
                  disabled
                  variant="outlined"
                />
              </Col>
              <Col md="1" className="mt-2">
                <Button
                  onClick={() => {
                    handleTagDel(idx);
                  }}
                >
                  <icons.DeleteOutline style={{ color: "red" }} />
                </Button>
              </Col>
            </Row>
          </span>
        ))}
        <Row className="justify-content-center">
          <Col md="2" className="mt-5">
            <h6>Timings</h6>
          </Col>
          <Col md="2" className="mt-2">
            {TimeComp("Start", day_0_s, setDay_0_s)}
          </Col>
          <Col md="2" className="mt-2">
            {TimeComp("End", day_0_e, setDay_0_e)}
          </Col>
        </Row>
        <Row className="justify-content-center mb-2 mt-4">
          <Col xs={12}>
            <Row className="justify-content-center mb-2">
              <Col lg={8}>Description</Col>
            </Row>
          </Col>
          <Col xs={12}>
            <Row className="justify-content-center mb-2">
              <Col lg={8}>
                <TextField
                  size="small"
                  id="Description"
                  placeholder="write here..."
                  variant="outlined"
                  value={description}
                  onChange={handleDetail}
                  fullWidth
                  multiline
                  rows={6}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-center ">
          <Col>
            <Row className="justify-content-center mb-2 mt-4">
              <Col lg={6}>
                {load ? null : (
                  <div
                    style={{
                      width: 250,
                      backgroundColor: "white",
                      height: 250,
                    }}
                    className="text-center"
                  >
                    <CircularProgress style={{ marginTop: "100px" }} />
                  </div>
                )}
                <img
                  src={url}
                  id="profile"
                  onLoad={handleImgLoad}
                  style={{
                    width: "250px",
                    height: "250px",
                    objectFit: "contain",
                    display: load ? "block" : "none",
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-center ">
          <Col>
            <Row className="justify-content-center mb-2">
              <Col lg={6}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    imgs.current.click();
                  }}
                >
                  Select new profile images
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-center mt-4">
          <Col md="6">
            <Row className="justify-content-end">
              <Col md="2">
                <Button
                  fullWidth
                  onClick={handleSave}
                  variant="contained"
                  color="secondary"
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        {showCropper ? (
          <Dialog fullWidth open={true}>
            <DialogContent style={{ height: "300px" }}>
              <ImageCropper getBlob={getBlob} inputImg={inputImg} />
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleCrop}
              >
                Crop
              </Button>
            </DialogActions>
          </Dialog>
        ) : null}
        <input
          multiple
          type="file"
          accept="image/png, image/jpeg"
          onChange={onInputChange}
          style={{ display: "none" }}
          ref={imgs}
        />
      </Container>
    </>
  );
};

export default Basic;

