import { get, put,} from "../../http";
import { root } from "../../config.json";

export const getListOfUsers = (page) => {
  return get(`${root}/api/v1/feedback/feedback?limit=6&page=${page}`);
};


export const getSingleInfo = (id) => {
  return get(`${root}/api/v1/feedback/${id}`);
};
export const UpdateFeedback = (pid,did,body) => {
  return put(`${root}/api/v1/feedback/${pid}/${did}`,body);
};