import { get, post, put, del } from "../http";
import { root } from "../config.json";

export const getLabs = (page, name) => {
  return get(`${root}/api/v1/lab?name=${name}&page=${page}&limit=6`);
};

export const searchLab = (ID) => {
  return get(`${root}/api/v1/lab/searchByID/${ID}`);
};

export const createLab = (body) => {
  return post(`${root}/api/v1/lab`, body);
};
export const uploadImg = (id, body) => {
  return post(`${root}/api/v1/lab/img/${id}`, body);
};
export const updateLab = (body) => {
  return put(`${root}/api/v1/lab`, body);
};
export const deleteLab = (id) => {
  return del(`${root}/api/v1/lab/${id}`);
};
export const getCheckUps = (id) => {
  return get(`${root}/api/v1/lab/checkUp/${id}`);
};
export const upload_image_no_id = (body) => {
  return post(`${root}/api/v1/lab/img`, body);
};

export const switchToLab = (lid) => {
  return get(`${root}/api/v1/admin/lab/${lid}`);
};

export const getLabInfo = (lid) => {
  return get(`${root}/api/v1/lab/info/${lid}`);
};