import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";

import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Button,
  IconButton,
} from "@material-ui/core";

import * as icons from "@material-ui/icons";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const ATabel = ({ rows, handleRemove, handleEdit }) => {
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left">Full name</StyledTableCell>
            <StyledTableCell align="left">Phone</StyledTableCell>
            <StyledTableCell align="left">Email</StyledTableCell>
            <StyledTableCell align="left">Gender</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, idx) => (
            <StyledTableRow key={idx}>
              <StyledTableCell align="left">{row.fullname}</StyledTableCell>
              <StyledTableCell align="left">{row.phone}</StyledTableCell>
              <StyledTableCell align="left">{row.email}</StyledTableCell>
              <StyledTableCell align="left">
                <div className="d-flex justify-content-between">
                  <div>{row.gender}</div>
                  <div>
                    <Button
                      className="p-0"
                      onClick={() => {
                        handleRemove(row.id, row.fullname);
                      }}
                    >
                      <icons.DeleteOutline className="text-danger" />
                    </Button>
                    <Button
                      className="p-0"
                      onClick={() => {
                        handleEdit(idx);
                      }}
                    >
                      <icons.EditOutlined style={{ color: "#0abde3" }} />
                    </Button>
                  </div>
                </div>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ATabel;
