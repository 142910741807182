import { post, get, del, sendImagesConfig, put } from "../../http";
import { root } from "../../config.json";

export const postLabReport = (body) => {
  return post(`${root}/api/v1/laboratory`, body);
};

export const sendImage = (files, id) => {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append("imgs", file);
  });
  return post(
    `${root}/api/v1/laboratory/imgs/${id}`,
    formData,
    sendImagesConfig
  );
};



export const getLabReports = (page) => {
  return get(`${root}/api/v1/laboratory?page=${page}&limit=6`);
};

export const delReport = (id) => {
  return del(`${root}/api/v1/laboratory/${id}`);
};

export const searchReport = (page, pid) => {
  return get(
    `${root}/api/v1/laboratory/search?page=${page}&limit=6&patientId=${pid}`
  );
};

export const getImage = (path) => {
  return get(`${root}${path}`, {
    responseType: "arraybuffer",
  });
};

export const updateLabReport = (id, body) => {
  return put(`${root}/api/v1/laboratory/${id}`, body);
};
