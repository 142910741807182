import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme, Button } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import lightBlue from "@material-ui/core/colors/lightBlue";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { getDoctorPatients ,UpdateDoctorPatients} from "../../Services/doctor/Patient";
import {changePatientToVisit} from "../../Services/doctor/Appointment"
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { useDispatch } from "react-redux";
import swal from "sweetalert";

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: lightBlue,
  },
});
const EditAppointment = ({ location ,history}) => {
  let { id, doctor } = location.state;

  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [phone, setPhone] = useState("");
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const dispatch = useDispatch();
  const initRows = async (page) => {
    dispatch(showLoading());
    const data = await getDoctorPatients(id)
    let paitent = [data.data.data];
    console.log(paitent);
    if (paitent.length > 0) {
      setName(paitent[0].name)
      setPhone(paitent[0].phone)
      setAge(paitent[0].age) 
      if(paitent[0].DoctorTitle !== ""){
        setTitle(paitent[0].DoctorTitle)
      }  
      else{
        setTitle("")
      }  
      if(paitent[0].DoctorTitle !== ""){
        setDesc(paitent[0].DoctorDescription)
      }  
      else{
        setDesc("")
      }  
      dispatch(hideLoading());
    }
    else{
      swal("wrong!", "Not Found", "error");
    }

  };
  useEffect(async () => {
    try {
      await initRows(id);
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401) {
        msg = data.msg;
      }
      swal("wrong!", msg, "error");
    } 
  }, [id]);
  const handleName = (e) => {
    setName(e.target.value);
  };
  const handleAge = (e) => {
    setAge(e.target.value);
  };
  const handlePhone = (e) => {
    setPhone(e.target.value);
  };

  const handleDesc = (e) => {
    setDesc(e.target.value);
  };
  const handleTitle = (e) => {
    setTitle(e.target.value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleVisit = async () => {
    dispatch(showLoading());
    const payload = { name: name, age: age, phone: phone, visit_date: selectedDate , DoctorTiltle: title, DoctorDescription: desc};
    try {
      await UpdateDoctorPatients(id,payload);
      await changePatientToVisit(id);
      swal("success!", "successfully Visited!", "success");
      history.goBack()
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401) {
        msg = data.msg;
      }
      swal("wrong!", msg, "error");
    } finally {
      dispatch(hideLoading());
    }
  };
  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col md="6">Patient</Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="6" className="mt-2">
            <TextField
              size="small"
              fullWidth
              id="fname"
              value={name}
              onChange={handleName}
              label="Fullname"
              variant="outlined"
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="3" className="mt-2">
            <TextField
              size="small"
              fullWidth
              value={age}
              type="number"
              onChange={handleAge}
              id="age"
              label="Age"
              variant="outlined"
            />
          </Col>
          <Col md="3" className="mt-2">
            <TextField
              size="small"
              fullWidth
              value={phone}
              onChange={handlePhone}
              inputProps={{ maxLength: 10 }}
              id="phone"
              label="Phone"
              variant="outlined"
            />
          </Col>
        </Row>

        <Row className="justify-content-center mt-4">
          <Col md="6">Title</Col>
        </Row>
        <Row className="justify-content-center mt-2">
          <Col md="6">
            <TextField
              size="small"
              fullWidth
              id="problem"
              value={title}
              onChange={handleTitle}
              label="Problem"
              variant="outlined"
            />
          </Col>
        </Row>

        <Row className="justify-content-center mt-4">
          <Col md="6">Description</Col>
        </Row>
        <Row className="justify-content-center mt-2">
          <Col md="6">
            <TextField
              size="small"
              fullWidth
              id="desc"
              value={desc}
              onChange={handleDesc}
              placeholder="write here..."
              variant="outlined"
              multiline
              rows={5}
            />
          </Col>
        </Row>
        <Row className="justify-content-center mt-4">
          <Col md="4">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <ThemeProvider theme={defaultMaterialTheme}>
                <DateTimePicker
                  fullWidth
                  label="Visit Date"
                  value={selectedDate}
                  onChange={handleDateChange}
                  animateYearScrolling={false}
                />
              </ThemeProvider>
            </MuiPickersUtilsProvider>
          </Col>
          <Col md="2" onClick={handleVisit} className="text-right mt-3">
            <Button fullWidth variant="contained" color="secondary">
              Visit
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EditAppointment;
