import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import {
  Card,
  CardActionArea,
  CircularProgress,
  Dialog,
} from "@material-ui/core";
import { getImage } from "../../Services/doctor/Laboratory";

const Report = ({ location, history }) => {
  const info = location.state;
  const [images, setImages] = useState(info.imgs);
  const [open, setOpen] = useState(false);
  const [flag, setFlag] = useState(false);
  const [openIdx, setOpenIdx] = useState(0);
  const [dow, setDow] = useState([]);
  const handleModalOpen = (idx, newImgs) => {
    setOpen(true);
    setFlag(newImgs);
    setOpenIdx(idx);
  };
  const handleModalClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    let tmp = [];
    for (let i = 0; i < info.imgs.length; i++) {
      tmp.push("");
    }
    setDow(tmp);
    recDowImg(0, tmp);
  }, []);
  const recDowImg = (i, tmp) => {
    if (i == info.imgs.length) return;
    getImage(info.imgs[i]).then((res) => {
      tmp[i] = Buffer.from(res.data, "binary").toString("base64");
      let x = [...tmp];
      setDow(x);
      recDowImg(i + 1, tmp);
    });
  };
  const [newImages, setNewImages] = useState([]);
  return (
    <div>
      <Dialog open={open} onClose={handleModalClose}>
        <img
          src={
            flag
              ? `${newImages[openIdx]}`
              : `data:image/jpeg;base64,${dow[openIdx]}`
          }
        />
      </Dialog>
      <h4>Patient Info :</h4>
      <div style={{ marginLeft: "10px" }}>
        <p>ID : {info.id}</p>
        <p>Name : {info.name}</p>
        <p>Phone : {info.phone}</p>
        <hr />
        <p>Doctor : {info.doctor}</p>
        <p>Title : {info.fullTitle}</p>
        <p>
          Description <br /> <br />
          {info.fullDescription}
        </p>
        <p>Date : {info.date}</p>
      </div>
      <Row className="justify-content-center mb-5">
        <Col md="6">
          <Row>
            {images.map((url, idx) => (
              <Col key={idx} md="6" className="mt-3">
                <Card>
                  <CardActionArea>
                    {dow[idx] && dow[idx].length > 0 ? (
                      <img
                        onClick={() => {
                          handleModalOpen(idx, false);
                        }}
                        src={`data:image/jpeg;base64,${dow[idx]}`}
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "contain",
                        }}
                      />
                    ) : (
                      <div style={{ height: 140 }} className="text-center">
                        <CircularProgress style={{ marginTop: "55px" }} />
                      </div>
                    )}
                  </CardActionArea>
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Report;
