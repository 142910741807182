import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { useDispatch } from "react-redux";
import swal from "sweetalert";
import { Pagination } from "@material-ui/lab";
import {
  getAllNotVisited,
  changePatientToVisit,
  searchAllNotVisited,
} from "../../Services/doctor/Appointment";
import {
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { } from "@material-ui/lab";
import * as icons from "@material-ui/icons";
import ATabel from "./ATabel";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  textField: {
    [`& fieldset`]: {
      borderRadius: 0,
    },
  },
  searchBtn: {
    borderRadius: 0,
    color: "#fff",
    backgroundColor: "#6DBCDB",
    "&:hover": {
      backgroundColor: "#0097e6",
    },
  },
}));

const Appointment = ({ history }) => {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const dispatch = useDispatch();

  const initRows = async (page) => {
    let { data } =
      search.length > 0
        ? await searchAllNotVisited(page, search)
        : await getAllNotVisited(page);
    setCount(data.count);
    data = data.data;
    let tmp = [];
    for (let i = 0; i < data.length; i++) {
      let x = data[i];
      let date = new Date(x.visit_date);
      date = date.toLocaleString();
      tmp.push({
        id: x.patientId,
        _id: x._id,
        date,
        name: x.name,
        phone: x.phone,
        doctor: x.doctor,
        option: (
          <Button
            variant="contained"
            size="small"
            color="secondary"
            onClick={() => {
              visitPatient(x._id);
            }}
          >
            Visit
          </Button>
        ),
      });
    }
    setRows(tmp);
  };
  const handlePage = async (e, value) => {
    setPage(value);
  };
  const handleEdit = async (idx,doctor) => {
    const { push } = history;
    push({ pathname: "/EditAppointment", state: {id: idx, doctor:doctor} })
  };
  const visitPatient = async (id) => {
    dispatch(showLoading());
    try {
      await changePatientToVisit(id);
      if (page != 1) setPage(1);
      else await initRows(1);
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401) {
        msg = data.msg;
      }
      swal("wrong!", msg, "error");
    } finally {
      dispatch(hideLoading());
    }
  };

  useEffect(async () => {
    dispatch(showLoading());
    try {
      await initRows(page);
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401) {
        msg = data.msg;
      }
      swal("wrong!", msg, "error");
    } finally {
      dispatch(hideLoading());
    }
  }, [page]);

  const handleSearchEnter = async () => {
    dispatch(showLoading());
    try {
      await initRows(page);
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401) {
        msg = data.msg;
      }
      swal("wrong!", msg, "error");
    } finally {
      dispatch(hideLoading());
    }
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleAddAppointment = () => {
    const { push } = history;
    push("/AppointmentsAdd");
  };
  return (
    <>
      <Container>
        <Row className="justify-content-between">
          <Col
            xs={{ order: "last" }}
            md={{ order: "first", span: 3 }}
            className="mt-2"
          >
            <Button
              fullWidth
              variant="contained"
              className={classes.searchBtn}
              onClick={handleAddAppointment}
            >
              + Add Appointment
            </Button>
          </Col>
          <Col
            xs={{ order: "first", span: 12 }}
            md={{ order: "last", span: 3 }}
            className="mt-2"
          >
            <TextField
              fullWidth
              size="small"
              id="search"
              type="number"
              label="Search by ID"
              variant="outlined"
              value={search}
              onChange={handleSearch}
              onKeyDown={(e) => {
                if (e.key === "Enter") handleSearchEnter();
              }}
              className={classes.textField}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton onClick={handleSearchEnter}>
                      <icons.Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="mt-4">
            <ATabel rows={rows} handleEdit={handleEdit} />
          </Col>
        </Row>
        {count > 6 ? (
          <div className="mt-3">
            <Pagination
              count={parseInt((count + 5) / 6)}
              onChange={handlePage}
              page={page}
              style={{ display: "flex", justifyContent: "center" }}
              variant="outlined"
              color="secondary"
            />
          </div>
        ) : null}
      </Container>
    </>
  );
};

export default withRouter(Appointment);
