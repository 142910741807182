import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import * as icons from "@material-ui/icons";
import { changePwd } from "../../../Services/doctor/auth";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { useDispatch } from "react-redux";
import swal from "sweetalert";
import {
  TextField,
  InputAdornment,
  IconButton,
  Button,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  searchBtn: {
    borderRadius: 0,
    color: "#fff",
    backgroundColor: "#6DBCDB",
    "&:hover": {
      backgroundColor: "#0097e6",
    },
  },
}));

const ChangePasswrod = () => {
  const classes = useStyles();
  const [confirmPwdShow, setConfirmPwdShow] = useState(true);
  const [newPwdShow, setNewPwdShow] = useState(true);
  const [oldPwdShow, setOldPwdShow] = useState(true);
  const [oldPwd, setOldPwd] = useState("");
  const [newPwd, setNewPwd] = useState("");
  const [confPwd, setConfPwd] = useState("");
  const dispatch = useDispatch();

  const handleSave = async () => {
    dispatch(showLoading());
    try {
      if (newPwd == confPwd) {
        let { data } = await changePwd({ oldPwd, newPwd });
        data = data.data;
        swal("success!", "successfully changed!", "success");
      } else {
        swal(
          "wrong!",
          "new password and confirm password in not same",
          "error"
        );
      }
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401) {
        msg = data.msg;
      }
      swal("wrong!", msg, "error");
    } finally {
      dispatch(hideLoading());
    }
  };
  const handleConfirmPwdShow = () => {
    setConfirmPwdShow(!confirmPwdShow);
  };
  const handleNewPwdShow = () => {
    setNewPwdShow(!newPwdShow);
  };
  const handleOldPwdShow = () => {
    setOldPwdShow(!oldPwdShow);
  };
  const handleOldPwd = (e) => {
    setOldPwd(e.target.value);
  };
  const handleNewPwd = (e) => {
    setNewPwd(e.target.value);
  };
  const handleConfPwd = (e) => {
    setConfPwd(e.target.value);
  };
  return (
    <>
      <Container>
        <Row className="justify-content-center mt-2">
          <Col md="6">
            <TextField
              type={oldPwdShow ? "password" : "text"}
              size="small"
              variant="outlined"
              fullWidth
              value={oldPwd}
              onChange={handleOldPwd}
              label="old password"
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton onClick={handleOldPwdShow}>
                      {oldPwdShow ? (
                        <icons.Visibility className="text-secondary" />
                      ) : (
                        <icons.VisibilityOff className="text-secondary" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Col>
        </Row>
        <Row className="justify-content-center mt-4">
          <Col md="6">
            <TextField
              type={newPwdShow ? "password" : "text"}
              size="small"
              variant="outlined"
              fullWidth
              value={newPwd}
              onChange={handleNewPwd}
              label="new password"
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton onClick={handleNewPwdShow}>
                      {newPwdShow ? (
                        <icons.Visibility className="text-secondary" />
                      ) : (
                        <icons.VisibilityOff className="text-secondary" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Col>
        </Row>
        <Row className="justify-content-center mt-4">
          <Col md="6">
            <TextField
              type={confirmPwdShow ? "password" : "text"}
              size="small"
              variant="outlined"
              fullWidth
              value={confPwd}
              onChange={handleConfPwd}
              label="confirm password"
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton onClick={handleConfirmPwdShow}>
                      {confirmPwdShow ? (
                        <icons.Visibility className="text-secondary" />
                      ) : (
                        <icons.VisibilityOff className="text-secondary" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Col>
        </Row>
        <Row className="justify-content-center mt-4">
          <Col md="6">
            <Button
              onClick={handleSave}
              size="small"
              className={classes.searchBtn}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ChangePasswrod;
