import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import HTabel from "./HTabel";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { useDispatch } from "react-redux";
import swal from "sweetalert";
import { Pagination } from "@material-ui/lab";
import {
  getSpLabReports,
  getSpPatientReports,
} from "../../Services/doctor/Patient";
import * as icons from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  searchBtn: {
    borderRadius: 0,
    color: "#fff",
    backgroundColor: "#6DBCDB",
    "&:hover": {
      backgroundColor: "#0097e6",
    },
  },
}));

const History = ({ history, location }) => {
  const classes = useStyles();
  const { pid, name } = location.state;
  const [choose, setChoose] = useState("Doctor Reports");
  const [anchorEl, setAnchorEl] = useState(null);
  const [flag, setFlag] = useState(false);
  let page = 1;
  const [countOfPage, setCountOfPage] = useState(0);
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const initRows = async (page) => {
    let { data } = await (choose == "Doctor Reports"
      ? getSpPatientReports(page, pid, name)
      : getSpLabReports(page, pid, name));

    setCountOfPage(data.count);
    data = data.data;
    let tmp = [];
    for (let i = 0; i < data.length; i++) {
      let x = data[i];
      let date = new Date(x.createAt);
      date = date.toLocaleString().split(",")[0];
      tmp.push({
        doctor: x.doctor,
        id: x.patientId,
        _id: x._id,
        name: x.name,
        phone: x.phone,
        description: `${x.description.substr(0, 20)}${
          x.description.length > 20 ? "..." : ""
        }`,
        title: `${x.title.substr(0, 20)}${x.title.length > 20 ? "..." : ""}`,
        fullTitle: x.title,
        fullDescription: x.description,
        date,
        imgs: x.documents,
      });
    }
    setRows(tmp);
  };
  useEffect(async () => {
    dispatch(showLoading());
    await initRows(1);
    dispatch(hideLoading());
  }, []);
  const handlePage = async (e, value) => {
    dispatch(showLoading());
    try {
      page = value;
      await initRows(value);
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401) {
        msg = data.msg;
      }
      swal("wrong!", msg, "error");
    } finally {
      dispatch(hideLoading());
    }
  };
  const handleLaboratoryReport = () => {
    const { push } = history;
    push("/LaboratoryReportAdd");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  useEffect(async () => {
    if (!flag) {
      setFlag(true);
    } else {
      dispatch(showLoading());
      await initRows(1);
      dispatch(hideLoading());
    }
  }, [choose]);
  const handleClose = async (str) => {
    setChoose(str);
    setAnchorEl(null);
    page = 1;
  };
  return (
    <>
      <Container>
        <Row>
          <Col className="mt-2">
            <Button
              variant="contained"
              color="secondary"
              onClick={handleClick}
              endIcon={<icons.ArrowDropDown />}
            >
              {choose}
            </Button>
            <Menu
              keepMounted
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => {
                setAnchorEl(null);
              }}
            >
              <MenuItem
                onClick={() => {
                  handleClose("Doctor Reports");
                }}
              >
                Doctor Reports
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose("Laboratory Reports");
                }}
              >
                Laboratory Reports
              </MenuItem>
            </Menu>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="mt-2">
            <HTabel rows={rows} />
          </Col>
        </Row>
        <div className="mt-3">
          <Pagination
            count={parseInt((countOfPage + 5) / 6)}
            onChange={handlePage}
            value={page}
            style={{ display: "flex", justifyContent: "center" }}
            variant="outlined"
            color="secondary"
          />
        </div>
      </Container>
    </>
  );
};

export default withRouter(History);
