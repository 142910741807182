import { post, get, put, del } from "../../http";
import { root } from "../../config.json";

export const getAllNotVisited = (page) => {
  return get(`${root}/api/v1/patient/patients?page=${page}&limit=6`);
};

export const changePatientToVisit = (id) => {
  return post(`${root}/api/v1/patient/visit/${id}`);
};

export const getPatientInfo = (id) => {
  return get(`${root}/api/v1/patient/info/${id}`);
};
export const getPatientReport = (id) => {
  return get(`${root}/api/v1/patient/info/${id}`);
};

export const postPatient = (body) => {
  return post(`${root}/api/v1/patient`, body);
};

export const searchAllNotVisited = (page, patientId) => {
  return get(
    `${root}/api/v1/patient/search?page=${page}&limit=6&patientId=${patientId}`
  );
};
