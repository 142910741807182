import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme, Button } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import lightBlue from "@material-ui/core/colors/lightBlue";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { getPatientInfo, postPatient } from "../../Services/doctor/Appointment";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { useDispatch } from "react-redux";
import swal from "sweetalert";

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: lightBlue,
  },
});
const AddAppointment = () => {
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [patientId, setPatientId] = useState("");
  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [phone, setPhone] = useState("");
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const dispatch = useDispatch();
  const handleFind = async () => {
    dispatch(showLoading());
    try {
      const { data } = await getPatientInfo(patientId);
      setName(data.name);
      setAge(data.age);
      setPhone(data.phone);
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401) {
        msg = data.msg;
      }
      swal("wrong!", msg, "error");
    } finally {
      dispatch(hideLoading());
    }
  };
  const handlePaitentId = (e) => {
    setPatientId(e.target.value);
  };
  const handleName = (e) => {
    setName(e.target.value);
  };
  const handleAge = (e) => {
    setAge(e.target.value);
  };
  const handlePhone = (e) => {
    setPhone(e.target.value);
  };

  const handleDesc = (e) => {
    setDesc(e.target.value);
  };
  const handleTitle = (e) => {
    setTitle(e.target.value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleSave = async () => {
    dispatch(showLoading());
    const payload = { name: name, age: age, phone: phone, visit_date: selectedDate , DoctorTitle: title, DoctorDescription: desc };
    if (patientId) payload["patientId"] = patientId;
    try {
      await postPatient(payload);
      swal("success!", "successfully added!", "success");
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401) {
        msg = data.msg;
      }
      swal("wrong!", msg, "error");
    } finally {
      dispatch(hideLoading());
    }
  };
  return (
    <>
      <Container>
        <Row className="justify-content-center mb-5">
          <Col md="3" className="mt-2">
            <TextField
              size="small"
              fullWidth
              id="pid"
              onChange={handlePaitentId}
              value={patientId}
              variant="outlined"
              label="Patient id"
            />
          </Col>
          <Col md="3" className="mt-2 text-right">
            <Button variant="contained" color="secondary" onClick={handleFind}>
              Find
            </Button>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="6">Patient</Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="6" className="mt-2">
            <TextField
              size="small"
              fullWidth
              id="fname"
              value={name}
              onChange={handleName}
              label="Fullname"
              variant="outlined"
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="3" className="mt-2">
            <TextField
              size="small"
              fullWidth
              value={age}
              type="number"
              onChange={handleAge}
              id="age"
              label="Age"
              variant="outlined"
            />
          </Col>
          <Col md="3" className="mt-2">
            <TextField
              size="small"
              fullWidth
              value={phone}
              onChange={handlePhone}
              inputProps={{ maxLength: 10 }}
              id="phone"
              label="Phone"
              variant="outlined"
            />
          </Col>
        </Row>

        <Row className="justify-content-center mt-4">
          <Col md="6">Title</Col>
        </Row>
        <Row className="justify-content-center mt-2">
          <Col md="6">
            <TextField
              size="small"
              fullWidth
              id="problem"
              value={title}
              onChange={handleTitle}
              label="Problem"
              variant="outlined"
            />
          </Col>
        </Row>

        <Row className="justify-content-center mt-4">
          <Col md="6">Description</Col>
        </Row>
        <Row className="justify-content-center mt-2">
          <Col md="6">
            <TextField
              size="small"
              fullWidth
              id="desc"
              value={desc}
              onChange={handleDesc}
              placeholder="write here..."
              variant="outlined"
              multiline
              rows={5}
            />
          </Col>
        </Row>
        <Row className="justify-content-center mt-4">
          <Col md="4">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <ThemeProvider theme={defaultMaterialTheme}>
                <DateTimePicker
                  fullWidth
                  label="Date"
                  value={selectedDate}
                  onChange={handleDateChange}
                  animateYearScrolling={false}
                />
              </ThemeProvider>
            </MuiPickersUtilsProvider>
          </Col>
          <Col md="2" onClick={handleSave} className="text-right mt-3">
            <Button fullWidth variant="contained" color="secondary">
              Save
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddAppointment;
