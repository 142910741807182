import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { days, months } from "../../../utils/values.json";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import { useDispatch } from "react-redux";
import swal from "sweetalert";
import { root } from "../../../Services/config.json";
import ImageCropper from "../../profile/ImageCropper";
import {
  getCities,
  getCategories,
  updateDoctor,
  getDoctor,
} from "../../../Services/doctor/profile";
import { getDoctorsById, UpdateDoctorsById, uploadProfile } from '../../../Services/Hospital/Doctor'
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  FormControl,
  InputLabel,
  CircularProgress,
  Dialog,
  DialogContent,
  Select,
  MenuItem,
  Button,
  DialogActions,
} from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  activeTab: {
    color: "#6DBCDB",
  },
  defaultTab: {
    color: "#636e72",
  },
  saveBtn: {
    backgroundColor: "#6DBCDB",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#0984e3",
      color: "#fff",
    },
  },
  addBtn: {
    backgroundColor: "#6DBCDB",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#0984e3",
      color: "#fff",
    },
  },
  deleteBtn: {
    backgroundColor: "#e84118",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#c23616",
      color: "#fff",
    },
  },
});

const Edit = ({ google, location }) => {
  // let {info} = location.state;
  let id = location.state.id
  const [month, setMonth] = useState("");
  const [name, setName] = useState("");
  const [description, setDiscription] = useState("");
  const [address, setAddress] = useState("");
  const [load, setLoad] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [lname, setLName] = useState("");
  const [Fee, setFee] = useState("");
  const [day, setDay] = useState("");
  const [url, setUrl] = useState("");
  const [year, setYear] = useState("");
  const [blob, setBlob] = useState(null);
  const [inputImg, setInputImg] = useState("");
  const [gender, setGender] = useState("");
  const [city, setCity] = useState(0);
  const [cities, setCities] = useState([]);
  const [specialty, setSpecialty] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [lat, setLat] = useState(34.5553);
  const [lng, setLng] = useState(69.2075);
  const [showCropper, setShowCropper] = useState(false);
  const [specialties, setSpecialties] = useState([]);
  const dispatch = useDispatch();
  const imgs = useRef();

  const classes = useStyles();
  const handleImage = () => {
    setLoad(true);
  };
  const years = [];
  for (let i = 2020; i >= 1940; i--) {
    years.push(i);
  }

  const handleMonth = (e) => {
    setMonth(e.target.value);
  };

  const handleMap = (mapProps, map, e) => {
    setLat(e.latLng.lat());
    setLng(e.latLng.lng());
  };

  const handleGender = (e) => {
    setGender(e.target.value);
  };

  const handleCity = (e) => {
    setCity(e.target.value);
  };

  const handleSpecialty = (e) => {
    setSpecialty(e.target.value);
  };

  const handleYear = (e) => {
    setYear(e.target.value);
  };

  const handleAddress = (e) => {
    setAddress(e.target.value);
  };

  const handleDescription = (e) => {
    setDiscription(e.target.value);
  };

  const handleDay = (e) => {
    setDay(e.target.value);
  };

  const handleName = (e) => {
    setName(e.target.value);
  };
  const handleFee = (e) => {
    setFee(e.target.value);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleLName = (e) => {
    setLName(e.target.value);
  };
  const handlePhone = (e) => {
    setPhone(e.target.value);
  };

  const handleSave = async () => {
    if (
      name.length > 0 &&
      lname.length > 0 &&
      phone.length > 0 &&
      address.length > 0 &&
      gender.length > 0 &&
      description.length > 0
    ) {
      dispatch(showLoading());
      try {
        await UpdateDoctorsById(id, {
          name,
          lname,
          phone,
          address,
          gender,
          detail: description,
          year,
          month,
          day,
          city: cities[city].id,
          category: specialties[specialty].id,
          speciality: specialties[specialty].text,
          geometry: {
            coordinates: [lng, lat],
          },
          fee: Fee
        });
        swal("success!", "successfully updated!", "success");
        window.history.go("/HDoctors")
      } catch (error) {
        let msg = "some error occured";

        let { status, data } = error.response;
        if (status == 400 || status == 401) {
          msg = data.msg;
        }

        swal("wrong!", msg, "error");
      } finally {
        dispatch(hideLoading());
      }
    } else {
      swal("wrong!", "please fill all field!", "error");
    }
  };
  useEffect(async () => {
    dispatch(showLoading());
    let cityId, categoryId;
    try {
      {
        let { data } = await getDoctorsById(id)
        setPhone(data.phone ? data.phone : "");
        setEmail(data.email ? data.email : "");
        setGender(data.gender ? data.gender : "0");
        setLName(data.lname ? data.lname : "");
        setName(data.name ? data.name : "");
        setYear(data.year ? data.year : "");
        setMonth(data.month ? data.month : "");
        setDay(data.day ? data.day : "");
        setAddress(data.address ? data.address : "");
        setDiscription(data.detail ? data.detail : "");

        if (data.geometry.coordinates) {
          setLat(
            data.geometry.coordinates ? data.geometry.coordinates[1] : 34.5553
          );
          setLng(
            data.geometry.coordinates ? data.geometry.coordinates[0] : 69.2075
          );
        }
        if(data.fee){
          setFee(data.fee)
        }

        setUrl(data.photo ? data.photo : "");
        cityId = data.city;
        categoryId = data.category;
      }
      {
        let { data } = await getCities();
        data = data.data;
        let tmp = [];
        data.map((item, idx) => {
          tmp.push({ id: item._id, text: item.e_name });
          if (item._id == cityId) setCity(idx);
        });
        setCities(tmp);
      }
      {
        let { data } = await getCategories();
        data = data.data;
        let tmp = [];
        data.map((item, idx) => {
          tmp.push({ id: item._id, text: item.e_title });
          if (item._id == categoryId) setSpecialty(idx);
        });
        setSpecialties(tmp);
      }
      setIsLoaded(true);
      dispatch(hideLoading());
    } catch (error) {
      console.log(error);
      dispatch(hideLoading());
      //
    }
    dispatch(hideLoading());
  }, []);

  const getBlob = (blob) => {
    // pass blob up from the ImageCropper component
    setBlob(blob);
  };

  const onInputChange = (e) => {
    // convert image file to base64 string
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      () => {
        setInputImg(reader.result);
        setShowCropper(true);
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const handleCrop = async () => {
    const fd = new FormData();
    fd.append("img", blob, "jack");
    const { data } = await uploadProfile(id, fd);
    setShowCropper(false);
    setUrl(data.name);
  };

  return (
    <>
      {isLoaded ? (
        <Container>
          <Row className="justify-content-center">
            <Col xs={12}>
              <Row className="justify-content-center">
                <Col lg={8}>Full name</Col>
              </Row>
            </Col>
            <Col lg={4} className="mt-2">
              <TextField
                size="small"
                id="fname"
                label="First name"
                variant="outlined"
                onChange={handleName}
                value={name}
                fullWidth
                required
              />
            </Col>
            <Col lg={4} className="mt-2">
              <TextField
                size="small"
                id="lname"
                label="Last name"
                variant="outlined"
                onChange={handleLName}
                value={lname}
                fullWidth
                required
              />
            </Col>
          </Row>
          <Row className="justify-content-center mt-4">
            <Col xs={12}>
              <Row className="justify-content-center">
                <Col lg={8}>Contact info</Col>
              </Row>
            </Col>
            <Col lg={4} className="mt-2">
              <TextField
                size="small"
                id="email"
                disabled
                label="Email Address"
                variant="outlined"
                onChange={handleEmail}
                value={email}
                fullWidth
                required
              />
            </Col>
            <Col lg={4} className="mt-2">
              <TextField
                size="small"
                id="phone"
                label="Phone"
                variant="outlined"
                value={phone}
                onChange={handlePhone}
                inputProps={{ maxLength: 10 }}
                fullWidth
                required
              />
            </Col>
          </Row>
          <Row className="justify-content-center mb-2 mt-4">
            <Col xs={12}>
              <Row className="justify-content-center">
                <Col lg={8}>Date of birth</Col>
              </Row>
            </Col>
            <Col lg={8}>
              <Row>
                <Col lg={4} className="mt-2">
                  <FormControl
                    size="small"
                    variant="outlined"
                    fullWidth
                    className={classes.formControl}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Month
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="month"
                      value={month}
                      onChange={handleMonth}
                      label="Month"
                    >
                      {months.map((item, idx) => (
                        <MenuItem key={item} value={idx}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Col>
                <Col lg={4} className="mt-2">
                  <FormControl
                    size="small"
                    variant="outlined"
                    fullWidth
                    className={classes.formControl}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Day
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="day"
                      value={day}
                      onChange={handleDay}
                      label="Day"
                    >
                      {days.map((item, index) => (
                        <MenuItem key={item} value={index}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Col>
                <Col lg={4} className="mt-2">
                  <FormControl
                    size="small"
                    variant="outlined"
                    fullWidth
                    className={classes.formControl}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Year
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={year}
                      onChange={handleYear}
                      label="Year"
                    >
                      {years.map((item, idx) => (
                        <MenuItem key={item} value={idx}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="justify-content-center mt-4">
            <Col className="mt-2" lg={8}>
              Address and Specialty
            </Col>

            <Col lg={8} className="mt-2">
              <TextField
                size="small"
                id="address"
                label="Address"
                onChange={handleAddress}
                value={address}
                variant="outlined"
                fullWidth
                required
              />
            </Col>
            <Col className="mt-4" lg={8}>
              <span>
                Coordinate: X = {lat}, Y = {lng}
              </span>
            </Col>
            <Col lg={8} className="mt-2">
              <div
                style={{
                  height: "300px",
                }}
              >
                <Map
                  google={google}
                  zoom={14}
                  style={{
                    width: "96%",
                    height: "100%",
                  }}
                  initialCenter={{ lat, lng }}
                  onClick={handleMap}
                >
                  <Marker position={{ lat, lng }} />
                </Map>
              </div>
            </Col>

            <Col lg={8} className="mt-4">
              <Row>
                <Col lg={4} className="mt-2">
                  <FormControl
                    size="small"
                    variant="outlined"
                    fullWidth
                    className={classes.formControl}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      City
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="city"
                      value={city}
                      onChange={handleCity}
                      label="city"
                    >
                      {cities.map((item, idx) => (
                        <MenuItem key={item.id} value={idx}>
                          {item.text}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Col>
                <Col lg={4} className="mt-2">
                  <FormControl
                    size="small"
                    variant="outlined"
                    fullWidth
                    className={classes.formControl}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Specialty
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="Specialty"
                      value={specialty}
                      onChange={handleSpecialty}
                      label="Specialty"
                    >
                      {specialties.map((item, index) => (
                        <MenuItem key={item.id} value={index}>
                          {item.text}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Col>
                <Col lg={4} className="mt-2">
                  <FormControl
                    size="small"
                    variant="outlined"
                    fullWidth
                    className={classes.formControl}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Gender
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="Gender"
                      value={gender}
                      onChange={handleGender}
                      label="Gender"
                    >
                      <MenuItem value="0">Male</MenuItem>
                      <MenuItem value="1">Female</MenuItem>
                    </Select>
                  </FormControl>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="justify-content-center ">
            <Col>
              <Row className="justify-content-center mb-2 mt-4">
                <Col lg={8}>
                  {load ? null : (
                    <div
                      style={{
                        width: 250,
                        backgroundColor: "white",
                        height: 250,
                      }}
                      className="text-center"
                    >
                      <CircularProgress style={{ marginTop: "100px" }} />
                    </div>
                  )}
                  <img
                    src={`${root}${url}`}
                    id="profile"
                    onLoad={handleImage}
                    style={{
                      width: "250px",
                      height: "250px",
                      objectFit: "contain",
                      display: load ? "block" : "none",
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="justify-content-center ">
            <Col>
              <Row className="justify-content-center mb-2">
                <Col lg={8}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      imgs.current.click();
                    }}
                  >
                    Select new profile images
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="justify-content-center mb-2 mt-4">
            <Col xs={12}>
              <Row className="justify-content-center mb-2">
                <Col lg={8}>Description</Col>
              </Row>
            </Col>
            <Col xs={12}>
              <Row className="justify-content-center mb-2">
                <Col lg={8}>
                  <TextField
                    size="small"
                    id="Description"
                    placeholder="write here..."
                    variant="outlined"
                    value={description}
                    onChange={handleDescription}
                    fullWidth
                    multiline
                    rows={6}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="justify-content-center mb-2">
            <Col lg={8} className="mt-2">
              <TextField
                size="small"
                id="fee"
                label=" Doctor Fee"
                variant="outlined"
                onChange={handleFee}
                value={Fee}
                fullWidth
              />
            </Col>
          </Row>
          <Row className="justify-content-center mb-2 mt-4">
            <Col xs={12}>
              <Row className="justify-content-center mb-2">
                <Col lg={8}>
                  <Button
                    variant="contained"
                    className={classes.saveBtn}
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <input
            multiple
            type="file"
            accept="image/png, image/jpeg"
            onChange={onInputChange}
            style={{ display: "none" }}
            ref={imgs}
          />
          {showCropper ? (
            <Dialog fullWidth open={true}>
              <DialogContent style={{ height: "300px" }}>
                <ImageCropper getBlob={getBlob} inputImg={inputImg} />
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleCrop}
                >
                  Upload
                </Button>
              </DialogActions>
            </Dialog>
          ) : null}
        </Container>
      ) : null}
    </>
  );
};

//

export default GoogleApiWrapper({
  apiKey: "AIzaSyAVUbtrhKdhW0g9imSAwXKNS4paUuZ9zLs",
})(Edit);
