import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import * as icons from "@material-ui/icons";
import { getCities, getCategories } from "../../Services/admin/Hospital";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  createHospital,
  searchDoctor,
  uploadProfile,
} from "../../Services/admin/Hospital";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { useDispatch } from "react-redux";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  OutlinedInput,
  InputAdornment,
} from "@material-ui/core";
import swal from "sweetalert";
import ImageCropper from "./ImageCropper";

const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const HAdd = ({ history }) => {
  const dispatch = useDispatch();

  const [address, setAddress] = useState("");
  const [name, setName] = useState("");
  const [load, setLoad] = useState(false);
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState(0);
  const [tag, setTag] = useState("");
  const [email, setEmail] = useState("");
  const [cities, setCities] = useState([]);
  const [category, setCategory] = useState("");
  const [blob, setBlob] = useState(null);
  const [inputImg, setInputImg] = useState("");
  const [url, setUrl] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedCat, setSelectedCat] = useState([]);
  const [tags, setTags] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [showCropper, setShowCropper] = useState(false);
  const [Hemail, setHEmail] = useState("");
  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  const imgs = useRef();
  const handleAddress = (e) => {
    setAddress(e.target.value);
  };
  const handleName = (e) => {
    setName(e.target.value);
  };
  const handlePhone = (e) => {
    setPhone(e.target.value);
  };
  const emailHandle = (e) => {
    setHEmail(e.target.value);
  };
  useEffect(async () => {
    {
      let { data } = await getCities();
      data = data.data;
      setCities(data);
    }
    {
      let { data } = await getCategories();
      data = data.data;
      setCategories(data);
    }
  }, []);

  const handleCity = (e) => {
    setCity(e.target.value);
  };
  const handleCategory = (e) => {
    setCategory(e.target.value);
  };

  const handleSave = async () => {
    if (validateEmail(Hemail)) {
      if (
        Hemail.length > 0 &&
        values.password.length > 0 &&
        address.length > 0 &&
        name.length > 0 &&
        phone.length > 0 &&
        selectedCat.length > 0 &&
        doctors.length > 0 &&
        tags.length > 0 &&
        values.password.length > 0
      ) {
        try {
          dispatch(showLoading());

          let docs = [];
          doctors.map((item) => {
            docs.push(item.id);
          });
          let cats = [];
          selectedCat.map((item) => {
            cats.push(item._id);
          });

          let { data } = await createHospital({
            address,
            name,
            categories: cats,
            tags,
            doctors: docs,
            city: cities[city]._id,
            phone,
            email: Hemail,
            password: values.password,
          });
          let id = data.id;
          if (blob) {
            const fd = new FormData();
            fd.append("img", blob, "jack");
            await uploadProfile(id, fd);
          }
          swal("success!", "successfully Hospital created!", "success");
          history.goBack();
        } catch (error) {
          let msg = "some error occured";
          let { status, data } = error.response;
          if (status == 400 || status == 401) {
            
            msg = data.msg;
          }
          swal("wrong!", msg, "error");
        } finally {
          dispatch(hideLoading());
        }
      } else {
        swal("wrong!", "please fill all field!", "error");
      }
    } else {
      swal("wrong!", "Email is invalid.", "error");
    }
  };


  const handleCategoryAdd = () => {
    let tmp = [...selectedCat];
    tmp.push(categories[category]);
    setSelectedCat(tmp);
  };

  const handleCatDel = (id) => {
    let tmp = selectedCat.filter((obj) => obj._id != id);
    setSelectedCat(tmp);
  };

  const handleDocDel = (id) => {
    let tmp = doctors.filter((obj) => obj.id != id);
    setDoctors(tmp);
  };

  const handleTag = (e) => {
    setTag(e.target.value);
  };

  const handleTags = () => {
    let tmp = [...tags];
    tmp.push(tag);
    setTags(tmp);
  };

  const handleTagDel = (idx) => {
    let tmp = [...tags];
    tmp.splice(idx, 1);
    setTags(tmp);
  };

  const handleDoctorFind = async () => {
    try {
      let { data } = await searchDoctor(email);
      let tmp = [...doctors];
      tmp.push({ fullname: data.fullname, id: data._id });
      setDoctors(tmp);
    } catch (error) {
      swal("wrong!", "Doctor not find!", "error");
    }
  };

  const getBlob = (blob) => {
    // pass blob up from the ImageCropper component
    setBlob(blob);
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const onInputChange = (e) => {
    // convert image file to base64 string
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      () => {
        setInputImg(reader.result);
        setShowCropper(true);
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const handleCrop = async () => {
    let reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => {
      setUrl(reader.result);
      setLoad(true);
    };
    setShowCropper(false);
  };

  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col md="6" className="mt-2">
            <TextField
              size="small"
              fullWidth
              id="name"
              label="Name"
              value={name}
              onChange={handleName}
              variant="outlined"
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="3" className="mt-2">
            <TextField
              size="small"
              fullWidth
              id="email"
              label="Email Address"
              value={Hemail}
              onChange={emailHandle}
              variant="outlined"
            />
          </Col>
          <Col md="3" className="mt-2">
            <FormControl fullWidth size="small" variant="outlined">
              <OutlinedInput
                placeholder="Password"
                id="outlined-adornment-password"
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChange("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? (
                        <icons.Visibility />
                      ) : (
                        <icons.VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="3" className="mt-2">
            <TextField
              size="small"
              fullWidth
              id="address"
              label="Address"
              value={address}
              onChange={handleAddress}
              variant="outlined"
            />
          </Col>
          <Col md="3" className="mt-2">
            <TextField
              size="small"
              fullWidth
              id="phone"
              label="Phone"
              value={phone}
              onChange={handlePhone}
              inputProps={{ maxLength: 10 }}
              variant="outlined"
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="6" className="mt-2">
            <FormControl fullWidth>
              <InputLabel>City</InputLabel>
              <Select onChange={handleCity} value={city} label="city">
                {cities.map((obj, idx) => (
                  <MenuItem key={idx} value={idx}>
                    {obj.e_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Col>
        </Row>
        <Row className="justify-content-center mt-2">
          <Col md="5" className="mt-2">
            <FormControl fullWidth>
              <InputLabel>Category</InputLabel>
              <Select
                onChange={handleCategory}
                value={category}
                label="category"
              >
                {categories.map((obj, idx) => (
                  <MenuItem key={idx} value={idx}>
                    {obj.e_title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Col>
          <Col md="1" className="mt-4">
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCategoryAdd}
            >
              ADD
            </Button>
          </Col>
        </Row>

        {selectedCat.map((obj, idx) => (
          <Row key={idx} className="justify-content-center mt-2">
            <Col md="5" className="mt-2">
              <TextField
                size="small"
                fullWidth
                value={obj.e_title}
                disabled
                onChange={handleName}
                variant="outlined"
              />
            </Col>
            <Col md="1" className="mt-2">
              <Button
                onClick={() => {
                  handleCatDel(obj._id);
                }}
              >
                <icons.DeleteOutline style={{ color: "red" }} />
              </Button>
            </Col>
          </Row>
        ))}
        <Row className="justify-content-center mt-2">
          <Col md="5" className="mt-2">
            <TextField
              size="small"
              fullWidth
              id="tag"
              label="Tag"
              value={tag}
              onChange={handleTag}
              variant="outlined"
            />
          </Col>
          <Col md="1" className="mt-2">
            <Button variant="contained" color="secondary" onClick={handleTags}>
              ADD
            </Button>
          </Col>
        </Row>
        {tags.map((item, idx) => (
          <span key={idx}>
            <Row className="justify-content-center mt-2">
              <Col md="5" className="mt-2">
                <TextField
                  size="small"
                  fullWidth
                  value={item}
                  disabled
                  variant="outlined"
                />
              </Col>
              <Col md="1" className="mt-2">
                <Button
                  onClick={() => {
                    handleTagDel(idx);
                  }}
                >
                  <icons.DeleteOutline style={{ color: "red" }} />
                </Button>
              </Col>
            </Row>
          </span>
        ))}
        <Row className="justify-content-center mt-2">
          <Col md="5" className="mt-2">
            <TextField
              size="small"
              fullWidth
              value={email}
              label="Email of doctor"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              variant="outlined"
            />
          </Col>
          <Col md="1" className="mt-2">
            <Button
              variant="contained"
              color="secondary"
              onClick={handleDoctorFind}
            >
              Find
            </Button>
          </Col>
        </Row>
        {doctors.map((obj, idx) => (
          <Row key={idx} className="justify-content-center mt-2">
            <Col md="5" className="mt-2">
              <TextField
                size="small"
                fullWidth
                value={obj.fullname}
                disabled
                onChange={handleName}
                variant="outlined"
              />
            </Col>
            <Col md="1" className="mt-2">
              <Button
                onClick={() => {
                  handleDocDel(obj.id);
                }}
              >
                <icons.DeleteOutline style={{ color: "red" }} />
              </Button>
            </Col>
          </Row>
        ))}
        <Row className="justify-content-center ">
          <Col>
            <Row className="justify-content-center mb-2 mt-4">
              <Col lg={6}>
                {load ? null : (
                  <div
                    style={{
                      width: 250,
                      backgroundColor: "white",
                      height: 250,
                    }}
                    className="text-center"
                  >
                    {/* <CircularProgress style={{ marginTop: "100px" }} /> */}
                  </div>
                )}
                <img
                  src={url}
                  id="profile"
                  style={{
                    width: "250px",
                    height: "250px",
                    objectFit: "contain",
                    display: load ? "block" : "none",
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-center ">
          <Col>
            <Row className="justify-content-center mb-2">
              <Col lg={6}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    imgs.current.click();
                  }}
                >
                  Select new profile images
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-center mt-4">
          <Col md="6">
            <Row className="justify-content-end">
              <Col md="2">
                <Button
                  fullWidth
                  onClick={handleSave}
                  variant="contained"
                  color="secondary"
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        {showCropper ? (
          <Dialog fullWidth open={true}>
            <DialogContent style={{ height: "300px" }}>
              <ImageCropper getBlob={getBlob} inputImg={inputImg} />
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleCrop}
              >
                Crop
              </Button>
            </DialogActions>
          </Dialog>
        ) : null}
        <input
          multiple
          type="file"
          accept="image/png, image/jpeg"
          onChange={onInputChange}
          style={{ display: "none" }}
          ref={imgs}
        />
      </Container>
    </>
  );
};

export default HAdd;
