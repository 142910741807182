import { put, del, post, get } from "../../http";
import { root } from "../../config.json";

export const getAllHospitals = (page) => {
  return get(`${root}/api/v1/hospital?limit=6&page=${page}`);
};

export const createHospital = (body) => {
  return post(`${root}/api/v1/hospital`, body);
};

export const delHospital = (id) => {
  return del(`${root}/api/v1/hospital/${id}`);
};

export const updateBaseInfo = (id, body) => {
  return put();
};

export const getCities = () => {
  return get(`${root}/api/v1/city?limit=100`);
};

export const getCategories = () => {
  return get(`${root}/api/v1/category?limit=100`);
};

export const searchDoctor = (email) => {
  return post(`${root}/api/v1/hospital/search?email=${email}`);
};

export const uploadProfile = (id, data) => {
  return post(`${root}/api/v1/hospital/img/${id}`, data, {
    headers: { "content-type": "multipart/form-data" },
  });
};

export const getHospitalInfo = (id) => {
  return get(`${root}/api/v1/hospital/info/${id}`);
};

export const editHospital = (id, body) => {
  return put(`${root}/api/v1/hospital/${id}`, body);
};

// export const searchHospital = (name, page) => {
//   return get(`${root}/api/v1/hospital/search/${name}`);
// };
export const searchHospital = (ID) => {
  return get(`${root}/api/v1/hospital/searchByID/${ID}`);
};
export const updateHospital = (body) => {
  return put(`${root}/api/v1/hospital`, body);
};

export const getCheckUps = (id) => {
  return get(`${root}/api/v1/hospital/Hcheckup/${id}`);
};
export const upload_image_no_id = (body) => {
  return post(`${root}/api/v1/hospital/img`, body);
};

export const switchToHospital = (hid) => {
  return get(`${root}/api/v1/admin/hospital/${hid}`);
};
