import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { makeStyles } from "@material-ui/core/styles";
import ATabel from "./ATabel";
import { getListOfAdmin, deleteAdmin } from "../../Services/admin/admin";
import { Pagination } from "@material-ui/lab";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { useDispatch } from "react-redux";
import { Button } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import swal from "sweetalert";

const useStyles = makeStyles((theme) => ({
  Btn: {
    borderRadius: 0,
    color: "#fff",
    backgroundColor: "#6DBCDB",
    "&:hover": {
      backgroundColor: "#0097e6",
    },
  },
}));

const Admin = ({ history }) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(1);
  const classes = useStyles();
  const handleAddAdmin = () => {
    const { push } = history;
    push("/adminAdd");
  };
  const initRows = async (page) => {
    let { data } = await getListOfAdmin(page);
    setCount(data.count);
    data = data.data;
    const tmp = [];
    data.map((val, idx) => {
      tmp.push({
        id: val._id,
        email: val.email,
      });
    });
    setRows(tmp);
  };
  const handleRemove = async (id, name) => {
    let isSure = await swal({
      title: "Are you sure?",
      text: `Are you sure to delete ${name}!`,
      icon: "warning",
      buttons: ["No", "Yes"],
      dangerMode: true,
    });
    if (isSure) {
      try {
        dispatch(showLoading());
        await deleteAdmin(id);
        swal("success!", "successfully admin deleted!", "success");
        if (page == 1) await initRows(1);
        else setPage(1);
      } catch (error) {
        let msg = "some error occured";
        let { status, data } = error.response;
        if (status == 400 || status == 401) {
          msg = data.msg;
        }
        swal("wrong!", msg, "error");
      } finally {
        dispatch(hideLoading());
      }
    }
  };

  const handlePage = async (e, value) => {
    setPage(value);
  };
  useEffect(async () => {
    try {
      dispatch(showLoading());
      await initRows(page);
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401) {
        msg = data.msg;
      }
      swal("wrong!", msg, "error");
    } finally {
      dispatch(hideLoading());
    }
  }, [page]);

  return (
    <>
      <Container>
        <Row>
          <Col md="3">
            <Button
              onClick={handleAddAdmin}
              fullWidth
              variant="contained"
              className={classes.Btn}
            >
              + Add Admin
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="mt-2">
            <ATabel rows={rows} handleRemove={handleRemove} />
          </Col>
        </Row>
        {count > 6 ? (
          <div className="mt-3">
            <Pagination
              count={parseInt((count + 5) / 6)}
              onChange={handlePage}
              page={page}
              style={{ display: "flex", justifyContent: "center" }}
              variant="outlined"
              color="secondary"
            />
          </div>
        ) : null}
      </Container>
    </>
  );
};

export default withRouter(Admin);
