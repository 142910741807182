import { React, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import swal from "sweetalert";
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  CircularProgress,
  Typography,
  TextField,
  Button
} from "@material-ui/core";
import { root } from "../../../Services/config.json";
import {AddDoctorComment}  from '../../../Services/Hospital/Feedback';
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { Row, Col } from "react-bootstrap";
import StarRatings from 'react-star-ratings';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});


const FTable = ({ rows,history }) => {
  const [load, setLoad] = useState(false);
  const [comment,setComment] = useState("");
  const dispatch = useDispatch();
  setTimeout(() => { setLoad(true) }, 3000);
  const handleImgLoad = () => {
    if (!load) setLoad(true);
  };

  const handleComment = async (e) => {
    setComment(e.target.value)
  }

  const handleUpdate = async (e) => {
    console.log(comment);
    try{
      dispatch(showLoading());
      await AddDoctorComment(e, {
        comment
      })
      dispatch(hideLoading());
      await swal("success!", "successfully Comment Add", "success");
      window.location.reload()
    }
    catch(error){
      let msg = "some error occured";
        let { status, data } = error.response;
        if (status == 400 || status == 401) {
          msg = data.msg;
        }

        swal("wrong!", msg, "error");
    }
  }
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left">Doctors Feedbacks</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, idx) => (
            <StyledTableRow key={idx}>
              <StyledTableCell component="th" scope="row" size="small">
                <Row className="justify-content-left">
                  <Col lg={4}>
                    {load ? null : (
                      <div
                        style={{
                          width: 150,
                          backgroundColor: "white",
                          height: 150,
                        }}
                        className="text-center"
                      >
                        <CircularProgress style={{ marginTop: "100px" }} />
                      </div>
                    )}
                    <img
                      src={`${root}${row.photo}`}
                      id="profile"
                      onLoad={handleImgLoad}
                      style={{
                        width: "150px",
                        height: "150px",
                        objectFit: "contain",
                        display: load ? "block" : "none",
                      }}
                    />
                    <Typography variant="h6">{row.speciality}</Typography>
                    <StarRatings
                      rating={row.DocTotalRating}
                      starRatedColor="blue"
                      changeRating={null}
                      numberOfStars={5}
                      name='rating'
                    />
                  </Col>
                  <Col lg={6} className="justify-content-left pl-2">
                    <Row className="mb-2 mt-1 ">
                      <Typography variant="h6">Name : {row.Name} </Typography></Row>
                    <Row className="mb-2 mt-1" >
                      <Typography variant="h6">Email  : {row.Email} </Typography></Row>
                    <Row className="mb-2 mt-1" >
                      <Typography variant="h6">Visiter  : {row.visitor} </Typography></Row>
                    <Row className="justify-content-left">
                      <Col md="6" className="mt-2">
                        <TextField
                          size="small"
                          fullWidth
                          label="Comments"
                          onChange={handleComment}
                          value={comment}
                          variant="outlined"
                        />
                      </Col>
                    </Row>
                    <Row className="justify-content-left">
                      <Col md="4" lg={4} sm={4} className="mt-3" >
                        <Button
                          fullWidth
                          onClick={()=> handleUpdate(row.id)}
                          variant="contained"
                          color="secondary"
                        >
                          Add Comment   
                        </Button>
                      </Col>
                    </Row>

                  </Col>
                </Row>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FTable;
