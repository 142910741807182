import { put, del, post, get, cancel } from "../../http";
import { root } from "../../config.json";

export const getListOfCategories = (page) => {
  return get(`${root}/api/v1/category?limit=6&page=${page}`);
};

export const addCategory = (body) => {
  return post(`${root}/api/v1/category`, body);
};

export const deleteCategory = (id) => {
  return del(`${root}/api/v1/category/${id}`);
};

export const uploadImage = (id, fd) => {
  return post(`${root}/api/v1/category/${id}`, fd, {
    headers: { "content-type": "multipart/form-data" },
  });
};

export const editCategory = (id, body) => {
  return put(`${root}/api/v1/category/${id}`, body);
};
