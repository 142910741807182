import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { makeStyles } from "@material-ui/core/styles";
import Tabel from "./Table";
import {
    getCitiesForAdmin,
    delCity,
} from "../../Services/Cities";
import { Pagination } from "@material-ui/lab";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { useDispatch } from "react-redux";
import {
  Button,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import swal from "sweetalert";
import * as icons from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  Btn: {
    borderRadius: 0,
    color: "#fff",
    backgroundColor: "#6DBCDB",
    "&:hover": {
      backgroundColor: "#0097e6",
    },
  },
}));

const Cities = ({ history }) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(1);
  const classes = useStyles();
  const handleAddCity = () => {
    const { push } = history;
    push("/Cities/AddCity");
  };
  const initRows = async (page) => {
    let { data } = await getCitiesForAdmin(page)
    setCount(data.count);
    let Cities = data.data;
    setRows(Cities);
  };

  const handleRemove = async (cityId) => {
    try {
      dispatch(showLoading());
      await delCity(cityId);
      swal("success!", "successfully modified!", "success");
      if (page == 1) await initRows(1);
      else setPage(1);
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401) {
        msg = data.msg;
      }
      swal("wrong!", msg, "error");
    } finally {
      dispatch(hideLoading());
    }
  };

  const handlePage = async (e, value) => {
    setPage(value);
  };
  useEffect(async () => {
    try {
      dispatch(showLoading());
      await initRows(page);
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401) {
        msg = data.msg;
      }
      swal("wrong!", msg, "error");
    } finally {
      dispatch(hideLoading());
    }
  }, [page]);

  return (
    <>
      <Container>
        <Row className="justify-content-between">
          <Col
            xs={{ order: "last" }}
            md={{ order: "first", span: 3 }}
            className="mt-2"
          >
            <Button
              onClick={handleAddCity}
              fullWidth
              variant="contained"
              className={classes.Btn}
            >
              + Add City
            </Button>
          </Col>
          {/* <Col
            xs={{ order: "first", span: 12 }}
            md={{ order: "last", span: 3 }}
            className="mt-2"
          >
            <TextField
              fullWidth
              size="small"
              id="search"
              label="Search by ID"
              variant="outlined"
              value={search}
              onChange={handleSearch}
              onKeyDown={(e) => {
                if (e.key === "Enter") handleSearchEnter();
              }}
              className={classes.textField}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton onClick={handleSearchEnter}>
                      <icons.Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Col> */}
        </Row>
        <Row>
          <Col xs={12} className="mt-2">
            <Tabel
              rows={rows} handleRemove={handleRemove}
              
            />
          </Col>
        </Row>
        {count > 6 ? (
          <div className="mt-3">
            <Pagination
              count={parseInt((count + 5) / 6   )}
              onChange={handlePage}
              page={page}
              style={{ display: "flex", justifyContent: "center" }}
              variant="outlined"
              color="secondary"
            />
          </div>
        ) : null}
      </Container>
    </>
  );
};

export default withRouter(Cities);
