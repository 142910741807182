import { useEffect, useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { Button, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { getCities } from "../../Services/doctor/profile";
import ImgComp from "../utils/ImageComp";
import { updateAds, uploadImg } from "../../Services/ads";
import swal from "sweetalert";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { useDispatch } from "react-redux";
import { root } from "../../Services/config.json";

const AddAds = ({ history, location }) => {
  let info = location.state;
  const dispatch = useDispatch();
  const [cities, setCities] = useState(info.city);
  const [citiesDef, setCitiesDef] = useState(info.city);
  const [name, setName] = useState(info.name);
  const [link, setLink] = useState(info.link);
  const [cityList, setCityList] = useState([]);
  const [blob, setBlob] = useState(null);
  const [url, setUrl] = useState(`${root}${info.img}`);
  const isValidUrl = (str) => {
    return str.match(
      /http(s)?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
  };
  useEffect(() => {
    const call = async () => {
      let { data } = await getCities();
      data = [...data.data];
      data.unshift({ e_name: "All", _id: "all" });
      setCityList(data);
    };
    call();
  }, []);
  const handleName = (e) => {
    setName(e.target.value);
  };
  const handleLink = (e) => {
    setLink(e.target.value);
  };
  const handleSave = async () => {
    if (link.length == 0 || isValidUrl(link) != null) {
      if (name.length > 0) {
        try {
          dispatch(showLoading());
          let accessCity = [];
          for (let x of cities) {
            accessCity.push(x._id);
          }
          let body = { name, link, id: info._id };
          if (accessCity.length == 0 || accessCity[0] == "all") {
            body["is_public"] = true;
          } else {
            body["city"] = accessCity;
          }
          let { data } = await updateAds(body);
          let id = info._id;

          if (blob) {
            const fd = new FormData();
            fd.append("img", blob, "jack");
            await uploadImg(id, fd);
          }
          swal("success!", "successfully ads updated!", "success");
          history.goBack();
        } catch (error) {
          swal("error", error.message, "error");
        } finally {
          dispatch(hideLoading());
        }
      } else {
        swal("error", "Name and Photo is required.", "error");
      }
    } else {
      swal("error", "link is invalid.", "error");
    }
  };
  return (
    <Container>
      <Row className="justify-content-center">
        <Col md="3" className="mt-2">
          <TextField
            value={name}
            onChange={handleName}
            size="small"
            fullWidth
            variant="outlined"
            label="Name"
          />
        </Col>
        <Col md="3" className="mt-2">
          <TextField
            value={link}
            onChange={handleLink}
            size="small"
            fullWidth
            variant="outlined"
            label="Link"
          />
        </Col>
      </Row>
      <Row className="justify-content-center mt-2">
        <Col md="6">
          <Autocomplete
            multiple
            options={cityList}
            defaultValue={citiesDef}
            onChange={(e, value) => {
              setCities(value);
            }}
            getOptionLabel={(option) => option.e_name}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                variant="outlined"
                label="Cities"
              />
            )}
          />
        </Col>
      </Row>
      <ImgComp
        loading={true}
        blob={blob}
        setBlob={setBlob}
        ads={true}
        link={url}
      />
      <Row className="justify-content-center">
        <Col md="5"></Col>
        <Col md="1" className="mt-4">
          <Button
            onClick={handleSave}
            fullWidth
            variant="contained"
            color="secondary"
          >
            Save
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default AddAds;
