import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import VerifiedIcon from '@mui/icons-material/Verified';
import Tooltip from '@mui/material/Tooltip';

import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Button,
} from "@material-ui/core";

import * as icons from "@material-ui/icons";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const DTabel = ({ rows, handleRemove, handleEdit, handleSwitch }) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
          <StyledTableCell align="left">ID</StyledTableCell>
            <StyledTableCell align="left">FullName</StyledTableCell>
            <StyledTableCell align="left">Email</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, idx) => (
            <StyledTableRow key={idx}>
               <StyledTableCell align="left">{row.ID ? row.ID : "N/A"}</StyledTableCell>
              <StyledTableCell align="left">{row.fullname} {row.Verification ? <Tooltip title="Verified" arrow><VerifiedIcon color="primary" /></Tooltip> : ""}</StyledTableCell>
              <StyledTableCell align="left">
                <div className="d-flex justify-content-between">
                  <div>{row.email}</div>
                  <div>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        handleSwitch(row.id);
                      }}
                    >
                      Switch
                    </Button>
                    <Button
                      className="p-0"
                      onClick={() => {
                        handleRemove(row.id);
                      }}
                    >
                      {row.is_active ? (
                        <icons.LockOpen className="text-danger" />
                      ) : (
                        <icons.Lock className="text-danger" />
                      )}
                    </Button>
                    <Button
                      className="p-0"
                      onClick={() => {
                        handleEdit(idx);
                      }}
                    >
                      <icons.EditOutlined style={{ color: "#0abde3" }} />
                    </Button>
                  </div>
                </div>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DTabel;
