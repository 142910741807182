import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, TextField } from "@material-ui/core";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { useDispatch } from "react-redux";
import swal from "sweetalert";
import * as icons from "@material-ui/icons";
import {
  postDisease,
  getDisease,
  delDisease,
} from "../../Services/doctor/profile";
const Tags = ({ classes }) => {
  const [name, setName] = useState("");
  const dispatch = useDispatch();
  const [diseases, setDiseases] = useState([]);
  const handleAdd = async () => {
    dispatch(showLoading());
    try {
      let { data } = await postDisease({ name });
      data = data.data;
      setDiseases(data);
      swal("success!", "successfully added!", "success");
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401) {
        msg = data.msg;
      }
      swal("wrong!", msg, "error");
    } finally {
      dispatch(hideLoading());
    }
  };
  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleDelete = async (tag) => {
    let isSure = await swal({
      title: "Are you sure?",
      text: `Are you sure to delete ${tag}!`,
      icon: "warning",
      buttons: ["No", "Yes"],
      dangerMode: true,
    });
    if (isSure) {
      try {
        dispatch(showLoading());
        let { data } = await delDisease(tag);
        data = data.data;
        setDiseases(data);
        swal("success!", "successfully deleted!", "success");
      } catch (error) {
        let msg = "some error occured";
        let { status, data } = error.response;
        if (status == 400 || status == 401) {
          msg = data.msg;
        }
        swal("wrong!", msg, "error");
      } finally {
        dispatch(hideLoading());
      }
    }
  };

  useEffect(async () => {
    dispatch(showLoading());
    try {
      let { data } = await getDisease();
      data = data.data;
      setDiseases(data);
    } catch (error) {
      let msg = "some error occured";
      let { status, data } = error.response;
      if (status == 400 || status == 401) {
        msg = data.msg;
      }
      swal("wrong!", msg, "error");
    } finally {
      dispatch(hideLoading());
    }
  }, []);

  return (
    <Container>
      <Row className="justify-content-center">
        <Col xs={12} lg={8}>
          <div className="d-flex justify-content-between">
            <span>Tags</span>
            <div>
              <Button
                onClick={handleAdd}
                variant="contained"
                className={classes.addBtn}
              >
                Add +
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={12} lg={8}>
          <Row className="mb-3">
            <Col xs={12}>
              <TextField
                size="small"
                className="mt-2"
                id="tags"
                value={name}
                onChange={handleName}
                fullWidth
                label="Tags"
                variant="outlined"
              />
            </Col>
          </Row>
          {diseases.map((item, idx) => (
            <Row key={idx}>
              <Col lg={11} className="mt-3">
                <TextField
                  size="small"
                  className="mt-2"
                  value={item}
                  fullWidth
                  disabled
                  label="Tags"
                  variant="outlined"
                />
              </Col>
              <Col lg={1} className="mt-4">
                <Button
                  onClick={() => {
                    handleDelete(item);
                  }}
                  fullWidth
                >
                  <icons.DeleteOutline style={{ color: "red" }} />
                </Button>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default Tags;
