import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import * as icons from "@material-ui/icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { updateDoctor } from "../../Services/admin/doctor";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { useDispatch } from "react-redux";
import {
  TextField,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import Checkbox from '@mui/material/Checkbox';

const useStyles = makeStyles((theme) => ({
  menuPaper: {
    maxHeight: 200,
  },
}));
const DEdit = ({ location, history }) => {
  const dispatch = useDispatch();
  const { info } = location.state;
  const [email, setEmail] = useState(info.email);
  const [fullname, setFullname] = useState(info.fullname);
  const [ID, setID] = useState(info.ID);
  const [checked, setChecked] = React.useState(info.Verification ? info.Verification : false);


  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const VerifiedHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleFullname = (e) => {
    setFullname(e.target.value);
  };
  const handleID = (e) => {
    setID(e.target.value);
  };

  // const handleGenerateID = async () => {
  //   var val = Math.floor(1000 + Math.random() * 9000);
  //   setID(val)
  // }

  const handleSave = async () => {
    if (email.length > 0 && fullname.length > 0) {
      try {
        dispatch(showLoading());
        let body = {
          email,
          fullname,
          Verification: checked,
          ID: ID
        };
        if (values.password.length > 0) {
          body["password"] = values.password;
        }
        await updateDoctor(info.id, body);
        swal("success!", "successfully updated!", "success");
        history.goBack();
      } catch (error) {
        let msg = "some error occured";
        let { status, data } = error.response;
        if (status == 400 || status == 401) {
          msg = data.msg;
        }

        swal("wrong!", msg, "error");
      } finally {
        dispatch(hideLoading());
      }
    } else {
      swal("wrong!", "please fill all field!", "error");
    }
  };

  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col md="6" className="mt-2">
            <TextField
              size="small"
              fullWidth
              id="fullname"
              label="FullName"
              value={fullname}
              onChange={handleFullname}
              variant="outlined"
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="3" className="mt-2">
            <TextField
              size="small"
              fullWidth
              id="email"
              label="Email Address"
              value={email}
              onChange={handleEmail}
              variant="outlined"
            />
          </Col>
          <Col md="3" className="mt-2">
            <FormControl fullWidth size="small" variant="outlined">
              <OutlinedInput
                placeholder="Password"
                id="outlined-adornment-password"
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChange("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? (
                        <icons.Visibility />
                      ) : (
                        <icons.VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="4" className="mt-3 ">
            <h5>Verified</h5>
          </Col>
          <Col md="1" className="mt-2">

            <Checkbox checked={checked}
              onChange={VerifiedHandleChange}
              inputProps={{ 'aria-label': 'controlled' }} color="success" />

          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="4" className="mt-2">
            <TextField
              size="small"
              fullWidth
              id="ID"
              label="ID"
              value={ID}
              onChange={handleID}
              variant="outlined"
            />

          </Col>
          {/* <Col md="2" className="mt-2">
            <Button
              fullWidth
              onClick={handleGenerateID}
              variant="contained"
              color="Primary"
            >
              Generate ID
            </Button>
          </Col> */}
        </Row>
        <Row className="justify-content-center mt-4">
          <Col md="6">
            <Row className="justify-content-end">
              <Col md="2">
                <Button
                  fullWidth
                  onClick={handleSave}
                  variant="contained"
                  color="secondary"
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container >
    </>
  );
};

export default DEdit;
