import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import SummarizeTwoToneIcon from '@mui/icons-material/SummarizeTwoTone';
import MedicationTwoToneIcon from '@mui/icons-material/MedicationTwoTone';
import { Typography } from '@mui/material';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Popover from '@material-ui/core/Popover';
import Box from '@mui/material/Box';
import { root } from "../../Services/config.json";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {
    Paper,
    TableRow,
    TableHead,
    TableContainer,
    TableCell,
    TableBody,
    Table,
    Button,
} from "@material-ui/core";

import * as icons from "@material-ui/icons";
import { Row, Col } from "react-bootstrap";

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});

const PatientTable = ({ rows, handleView ,handleClick}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);


    

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    // const id = open ? 'simple-popover' : undefined;
    const classes = useStyles();
    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell align="left">ID</StyledTableCell>
                        <StyledTableCell align="left">Doctor</StyledTableCell>
                        <StyledTableCell align="left">Speciality</StyledTableCell>
                        <StyledTableCell align="left">Complaints</StyledTableCell>
                        <StyledTableCell align="left">Description</StyledTableCell>
                        <StyledTableCell align="left">Prescriptions</StyledTableCell>
                        <StyledTableCell align="left">Reports</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.ReportsAndDescription.map((row, idx) => (
                        <StyledTableRow key={idx}>
                            <StyledTableCell component="th" scope="row">
                                <div>{rows.user.patientID}</div>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                <div>{row.doctor[0].fullname}</div>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                <div>{row.doctor[0].speciality}</div>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                <div className=" justify-content-between">
                                    <div>{row.title}</div>
                                </div>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                <div className=" justify-content-center">
                                    <div>{row.description}</div>
                                </div>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                <div className=" justify-content-end">
                                    <Button
                                        size="small"
                                        onClick={() => {

                                        }}
                                        style={{ color: '#6DBCDB' }}
                                    >
                                        <MedicationTwoToneIcon fontSize="medium" />
                                    </Button>
                                </div>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                <div className=" justify-content-end">
                                    <Button
                                        size="small"
                                        onClick={()=> {handleClick(row.documents)}}
                                        id={'basic-menu'}
                                        style={{ color: '#6DBCDB' }}
                                    >
                                        <SummarizeTwoToneIcon fontSize="medium" />
                                    </Button>


                                    {/* <Popover
                                        id={id}
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                    >


{console.log(rows.ReportsAndDescription[idx].documents)}
                                        
                                        <ImageList sx={{ width: 300, height: 300 }} cols={1} rowHeight={164}>
                                            {rows.ReportsAndDescription[idx].documents.map((val, index) => {
                                                <ImageListItem key={idx}>
                                                    <img
                                                        src={`${root}${val}?w=164&h=164&fit=crop&auto=format`}
                                                        srcSet={`${root}${val}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                                        alt={"Report"}
                                                        loading="lazy"
                                                    />
                                                </ImageListItem>
                                            })}
                                        </ImageList>

                                    </Popover> */}
                                </div>
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default PatientTable;
