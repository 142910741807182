import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import * as icons from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import FTable from "./fTable";
import { getListOfUsers } from "../../Services/admin/feedback";
import { Pagination } from "@material-ui/lab";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import swal from "sweetalert";

const useStyles = makeStyles((theme) => ({
    Btn: {
        borderRadius: 0,
        color: "#fff",
        backgroundColor: "#6DBCDB",
        "&:hover": {
            backgroundColor: "#0097e6",
        },
    },
}));

const Feedback = ({ history }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [pid, setPid] = useState(null);
    const [viewPatient, setViewPatient] = useState([]);
    const [patientID, setPatientID] = useState(null)
    const [page, setPage] = useState(1);
    const [rows, setRows] = useState([]);
    const [count, setCount] = useState(1);
    const [ReportsAndDescription, setReportsAndDescription] = useState(null)
    const [user, setUser] = useState(null);






    const initRows = async (page) => {
        dispatch(showLoading());
        let { data } = await getListOfUsers(page);
        dispatch(hideLoading());
        setCount(data.count);
        setRows(data.data);
    };
    const handleEdit = async (id) => {
        let { push } = history;
        setPid(id)
        push({
            pathname: "/EditFeedback",
            state: { id: id },
        });
    };
    const handlePage = async (e, value) => {
        setPage(value);
    };
    useEffect(async () => {
        try {
            await initRows(page);
        } catch (error) {
            let msg = "some error occured";
            let { status, data } = error.response;
            if (status == 400 || status == 401) {
                msg = data.msg;
            }
            swal("wrong!", msg, "error");
        } finally {

        }
    }, [page]);

    return (
        <>
            <Container>
                <Row>
                    <Col xs={12} className="mt-2">
                        <FTable rows={rows} handleEdit={handleEdit} />
                    </Col>
                </Row>
                {count > 6 ? (
                    <div className="mt-3">
                        <Pagination
                            count={parseInt((count + 5) / 6)}
                            onChange={handlePage}
                            page={page}
                            variant="outlined"
                            color="secondary"
                            style={{ display: "flex", justifyContent: "center" }}
                            variant="outlined"
                            color="secondary"
                        />
                    </div>
                ) : null}
            </Container>
        </>
    );
};

export default withRouter(Feedback);
