import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import VerifiedIcon from '@mui/icons-material/Verified';
import Tooltip from '@mui/material/Tooltip';

import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Button,
} from "@material-ui/core";

import * as icons from "@material-ui/icons";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const Tabel = ({ rows, handleRemove, handleEdit, handleSwitch }) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
          <StyledTableCell align="left">English Name</StyledTableCell>
            {/* <StyledTableCell align="left">Pashto Name</StyledTableCell>
            <StyledTableCell align="left">Dari Name</StyledTableCell> */}
            <StyledTableCell align="left">Russian Name</StyledTableCell>
            <StyledTableCell align="left">Uzbek Name</StyledTableCell>
            <StyledTableCell align="left"></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, idx) => (
            <StyledTableRow key={idx}>
               <StyledTableCell align="left">{row.e_name ? row.e_name : "N/A"}</StyledTableCell>
              {/* <StyledTableCell align="left">{row.p_name ? row.p_name : "N/A"}</StyledTableCell>
              <StyledTableCell align="left">{row.f_name ? row.f_name : "N/A"}</StyledTableCell> */}
              <StyledTableCell align="left">{row.r_name ? row.r_name : "N/A"}</StyledTableCell>
              <StyledTableCell align="left">{row.u_name ? row.u_name : "N/A"}</StyledTableCell>
              <StyledTableCell align="left">
                <div className="d-flex justify-content-between">
                  <div>
                  <Button
                        className="p-0"
                        onClick={() => {
                          handleRemove(row._id);
                        }}
                      >
                        <icons.DeleteOutline className="text-danger" />
                      </Button>
                   
                  </div>
                </div>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Tabel;
